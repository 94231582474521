/*
 * @Author: weiyanna
 * @Date: 2021-12-08 10:34:08
 * @LastEditTime: 2022-01-05 20:00:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/AddressList/index.tsx
 */
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Pagination, message, Modal, Checkbox } from 'antd';

import UserCenterMenu from '@/components/UserCenterMenu';
import CpAddressList from '@/components/Address/List/index';
import { PAGE_SIZE_OPTIONS } from '@/enum/config';
import { Input } from 'antd';
import SearchEmpty from '@/assets/images/accountSettings/icon_search_empty.png';

import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import AddArea from '@/components/Address/Drawer';
import CommonBasicHeader from '@/components/CommonBasicHeader';
import {
  createAddress,
  getAddressList,
  GetAddressListType,
  modifyAddress,
  removeAddress,
} from '@/api/newApi/personalCenter/address';
import { filterParams } from '@/util';
import { useTranslation } from 'react-i18next';
import PaginationJD from '@/components/PaginationJD';

const AddressList: React.FC<{}> = () => {
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  // 查询条件
  const [searchParams, setSearchParams] = useState<any>({});
  // 中英文切换
  const { t } = useTranslation();
  // 页码
  const [page, setPage] = useState<number>(1);
  // 页面数据展示条数
  const [pageSize, setPageSize] = useState<number>(30);
  // 地址列表
  const [consigneeArrayList, setConsigneeArrayList] = useState<Array<object>>([]);
  // 地址总条数
  const [total, setTotal] = useState<number>(0);
  // 选择地址集合
  const [selectedAddressIds, setSelectedAddressIds] = useState<Array<number>>([]);
  // 页面刷新flag
  const [refresh, setRefresh] = useState(true);
  // 编辑地址的详细信息
  const [consignee, setConsignee] = useState({});
  // 新增或编辑地址Drawer
  const [addressModelVisible, setAddressModelVisible] = useState(false);
  // 搜索按钮
  const [loading, setLoading] = useState(false);
  // 列表加载状态
  const [pageLoad, setPageLoad] = useState(false);
  /**
   * 重置已选地址集合
   */
  const clearSelect = () => setSelectedAddressIds([]);
  /**
   * 重置页面，重新加载地址列表
   */
  const reset = () => {
    setPage(1);
    setRefresh(true);
  };
  // 初始化调用
  // 监听用户信息变化，解决用户接口响应慢时获取地址列表优先于用户接口调用，导致报错问题
  useEffect(() => {
    if (currentUser.pin) {
      setSearchParams({});
      reset();
    }
  }, [currentUser.pin]);

  /**
   * 获取地址列表
   */
  const getData = async () => {
    const params = {
      pageNo: page,
      pageSize,
      pin: currentUser.pin,
      ...searchParams,
    };
    setPageLoad(true);
    const res = (await getAddressList(filterParams(params) as GetAddressListType)) as any;
    setLoading(false);
    setPageLoad(false);
    if (res.success) {
      //地址列表
      setConsigneeArrayList(res.value.items ?? []);
      setTotal(res.value.total ?? 0);
    } else {
      message.error(res.msg);
    }
  };

  // 当refresh状态为true并且用户信息已有
  // 重置所有条件
  useEffect(() => {
    if (refresh && currentUser.pin) {
      clearSelect();
      getData();
      setRefresh(false);
    }
  }, [refresh, currentUser.pin]);

  /**
   * 添加地址
   */
  const addAddressVisible = () => {
    setAddressModelVisible(true);
    setConsignee({});
    log('jdisp', 'Address_New');
  };
  /**
   * 编辑地址
   * @param address 地址详情
   */
  const onEditAlloc = (address: any) => {
    setConsignee(address);
  };
  /**
   * 批量删除地址
   * @param receiverId 地址ID
   */
  const batchDeleteAddress = async (receiverId: number | number[]) => {
    let res: any = '';
    res = await removeAddress(typeof receiverId === 'number' ? [receiverId] : receiverId);
    if (res.success) {
      message.success(TLT(`删除地址成功!`));
      reset();
    } else {
      message.error(res.msg || TLT(`删除失败，请重试`));
    }
  };
  /**
   * 地址全选/全不选
   */
  const checkAll = () => {
    const all = consigneeArrayList.filter((i: any) => i.pin === currentUser.pin);
    if (selectedAddressIds.length === all.length) {
      setSelectedAddressIds([]);
    } else {
      setSelectedAddressIds(all.map((i: any) => i.id));
    }
  };
  /**
   * 地址操作栏
   */
  const batchActions = () => {
    const selectAddressCount = selectedAddressIds.length;
    const allLength = consigneeArrayList.filter((i: any) => i.pin === currentUser.pin);
    const [p, l] = TLT(`已选 %{num}% 条地址`).split('%{num}%');
    return (
      <Row className={styles.batchActions} style={{ alignItems: 'center' }}>
        <Col span="18" style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ alignItems: 'center' }}
            indeterminate={
              selectAddressCount === allLength.length || allLength.length === 0 ? false : !!selectAddressCount
            }
            checked={allLength.length !== 0 && selectAddressCount === allLength.length}
            onClick={checkAll}
          >
            {TLT(`全选`)}
          </Checkbox>
          <span className={styles.checked}>
            {p} <span className={[styles.blue, styles.bold].join(' ')}>{selectAddressCount}</span> {l}
          </span>
          <Button
            style={{ marginRight: '20px' }}
            disabled={selectedAddressIds.length === 0}
            onClick={() => {
              Modal.confirm({
                title: TLT(`删除地址？`),
                icon: <ExclamationCircleOutlined />,
                content: TLT(`地址删除后将无法恢复!`),
                okText: TLT(`取消`),
                cancelText: TLT(`确定`),
                onCancel() {
                  batchDeleteAddress(selectedAddressIds);
                },
              });
              log('jdisp', 'Address_DeleteAll');
            }}
          >
            {TLT(`删除`)}
          </Button>
        </Col>
        <Col span="6" style={{ textAlign: 'right' }}>
          <Input.Search
            onSearch={value => {
              if (searchParams.keyword === value) return;
              setLoading(true);
              setSearchParams({ keyword: value });
              reset();
            }}
            placeholder={TLT(`搜索地址`)}
            loading={loading}
          />
        </Col>
      </Row>
    );
  };
  /**
   * 删除地址
   * @param address 地址详情
   */
  const deleteAddress = (address: any) => {
    Modal.confirm({
      title: TLT(`删除地址？`),
      icon: <ExclamationCircleOutlined />,
      content: TLT(`地址删除后将无法恢复!`),
      okText: TLT(`取消`),
      cancelText: TLT(`确定`),
      onCancel() {
        batchDeleteAddress(address.id);
      },
    });
    log('jdisp', 'Address_Delete');
  };
  /**
   * 保存/修改地址
   * @param address 地址详情
   */
  const onSaveAddress = async (address: any) => {
    const params = {
      pin: currentUser.pin,
      ...address,
      defaultStatus: address.defaultStatus ? 1 : 0,
    };
    let res: any = '';
    if (address.contractId) {
      res = await modifyAddress({
        ...params,
      });
    } else {
      res = await createAddress({
        ...params,
      });
    }
    if (res.success) {
      message.success(address.contractId ? TLT(`地址修改成功`) : TLT(`地址添加成功`));
      setAddressModelVisible(false);
      reset();
    } else {
      message.error(res.msg);
    }
  };
  /**
   * 触发编辑地址
   * @param address 地址详情
   */
  const onEditAddress = (address: any) => {
    setAddressModelVisible(true);
    setConsignee(address);
    log('jdisp', 'Address_Edit');
  };
  /**
   * 关闭地址弹窗
   */
  const closeAddressModel = () => {
    setAddressModelVisible(false);
    setConsignee({});
  };
  /**
   * 勾选地址
   * @param checked 地址勾选状态
   * @param address 地址详情
   */
  const checkAddress = (checked: any, address: any) => {
    const addressIds = [...selectedAddressIds];
    const index = addressIds.indexOf(address.id);
    if (checked) {
      addressIds.push(address.id);
    } else {
      addressIds.splice(index, 1);
    }
    setSelectedAddressIds(addressIds);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="ispContainer">
        <UserCenterMenu />
        <div className={styles.content} style={{ width: '1030px' }}>
          <CommonBasicHeader title={TLT(`地址管理`)}>
            <Button type="primary" onClick={addAddressVisible} disabled={total >= 200}>
              {TLT(`添加`)}（{total}/200）
            </Button>
          </CommonBasicHeader>
          <Card className={styles.orderList} style={{ padding: '15px 20px' }}>
            {batchActions()}
            {consigneeArrayList.length > 0 ? (
              <>
                <CpAddressList
                  addressList={consigneeArrayList}
                  onDeleteAddress={deleteAddress}
                  onCheckAddress={checkAddress}
                  onEditAddress={onEditAddress}
                  onEditAlloc={onEditAlloc}
                  checkedIds={selectedAddressIds}
                />
                {total > 30 ? (
                  <div style={{ textAlign: 'right' }}>
                    <PaginationJD
                      current={page}
                      total={total}
                      pageSize={pageSize}
                      pageSizeOptions={PAGE_SIZE_OPTIONS}
                      onChange={_page => {
                        setPage(_page);
                        setRefresh(true);
                      }}
                      onShowSizeChange={(current, size) => {
                        setPageSize(size);
                        reset();
                      }}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              !pageLoad && (
                <div className={styles.empty}>
                  <img src={SearchEmpty} alt="" />
                  <div className={styles.title}>
                    {searchParams.keyword ? TLT(`无相关结果。`) : TLT(`你还没有创建任何地址`)}
                  </div>
                  <div className={styles.tips}>
                    {searchParams.keyword
                      ? TLT(`请尝试其他结果。`)
                      : TLT(`点击下面按钮添加一个`)}
                  </div>
                  {!searchParams.keyword ? (
                    <div className={styles.button} onClick={addAddressVisible}>
                      {TLT(`添加地址`)}
                    </div>
                  ) : null}
                </div>
              )
            )}
            <AddArea
              address={consignee}
              visible={addressModelVisible}
              onOk={(address: any) => {
                onSaveAddress(address);
              }}
              onCancel={closeAddressModel}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
export default AddressList;
