import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Affix, message } from 'antd';

import FloorTitle from './floor/title/index';
import FloorInfoDetail from './floor/info/detail';
import FloorInfoAdd from './floor/info/add';
import FloorSetApproval from './floor/set/index';
import FloorAccountList from './floor/account';
import FloorSubmit from './floor/submit';

import ModifyNameModal from './components/modifyNameModal'; // 修改名称
import DeletePrompt from './components/deletePrompt'; //删除提示
import AddAccountDrawer from './components/addAccountDrawer/index'; // 增加人员提示
import ManageConditionDrawer from './components/manageConditionDrawer';

import {
  dispatchApprovalAccountModel,
  dispatchApprovalConditionDrawer,
  dispatchApprovalData,
  dispatchApprovalDeleteModel,
  dispatchApprovalExaminePins,
  dispatchApprovalNodes,
  dispatchApprovalModifyNameModel,
  dispatchApprovalErrorNodeId,
} from '@/redux/action';
import { queryDetail, saveProcess, initPageValue } from '@/api/newApi/approval';
import { getUrlsQuery } from '@/util';
import { CONDITION_DRAWER_TYPE, ACCOUNT_TYPE, ACCOUNT_DRAWER_TYPE, PAGE_TYPE, NODE_TYPE } from './constants/index';
import {
  deleteNode,
  deleteColNode,
  repickDeleteArr,
  transformResultData,
  getCurrentApprovalList,
  getNodeFactory,
  restructuringConditions,
  heightToTop,
} from './utils/oprateNode';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const CreateApproval: React.FC = () => {
  // const randomValue = HashCode(10);
  const [randomValue, setRandomValue] = useState(''); // 页面操作类型
  const history = useHistory();
  const dispatch = useDispatch();
  const stateApproval: any = useSelector((state: any) => {
    return state?.Approval;
  });
  console.log('stateApproval---->', stateApproval);

  const stateUserPin: any = useSelector((state: any) => {
    return state?.User?.data[0]?.pin;
  });

  const { t } = useTranslation();


  // 定义定位元素变量
  const myFloorInfoAddRef = useRef<HTMLDivElement>(null);

  const [inputApprovalError, setInputApprovalError] = useState(false); // 页面操作类型

  const [operateType, setOperateType] = useState(1); // 页面操作类型

  const [operateNode, setOperateNode] = useState({}); // 操作节点

  const [isShowModifyNameModel, setIsShowModifyNameModel] = useState(false); // 是否展示修改名字弹层
  const [isShowDeleteModel, setIsShowDeleteModel] = useState(false); // 是否展示删除弹层

  const [isShowAddAccountDrawer, setIsShowAddAccountDrawer] = useState(false); // 是否展示增加审批人员抽屉
  const [accountNode, setAccountNode] = useState({}); // 增加审批员节点
  const [accountDrawerTitle, setAccountDrawerTitle] = useState(''); // 增加审批员节点
  const [purchasePins, setPurchasePins] = useState([]);
  const [accountType, setAccountType] = useState(5);

  const [isShowManageConditionDrawer, setShowManageConditionDrawer] = useState(false); // 是否展示增加审批条件抽屉
  const [conditionNode, setConditionNode] = useState({}); // 增加审批员节点
  const [conditionDrawerTitle, setConditionDrawerTitle] = useState(''); // 增加审批员节点

  const [isCanSubmit, setIsCanSubmit] = useState(true); // 防止重复点击
  const [offsetBottom, setOffsetBottom] = useState(0); //图钉组件偏移量
  // 初始化详情信息
  const initDetail = async (processKey: string) => {
    const params = {
      processKey,
    };
    const res: any = await queryDetail(params);
    dispatch(dispatchApprovalData(res?.value));
  };
  // 初始化后端所需令牌
  const initPage = async () => {
    const res: any = await initPageValue();
    if (res.success) {
      setRandomValue(res?.value);
    }
  };

  useEffect(() => {
    const processKey = getUrlsQuery('processKey');
    // 操作类型 1新增 2修改 3详情
    const operateType = getUrlsQuery('type') || 1;
    setOperateType(Number(operateType));

    if (processKey) {
      // 如果不为空，则为详情
      initDetail(processKey);
    }

    if (operateType == PAGE_TYPE.addPage || operateType == PAGE_TYPE.modifyPage) {
      initPage();
    }
  }, [getUrlsQuery('type')]);

  // 监听删除动作
  useEffect(() => {
    setIsShowDeleteModel(stateApproval?.isShowDeleteModal);
    // todo
    setOperateNode(stateApproval?.operateNode);
  }, [stateApproval.isShowDeleteModal]);
  // 取消删除
  const handleCancelDelete = () => {
    const params = {
      isShowDeleteModal: false,
      operateNode: {},
    };
    dispatch(dispatchApprovalDeleteModel(params));
  };

  // 确认删除
  const handleConfirmDelete = (node: any) => {
    const tempNodeList = getCurrentApprovalList(stateApproval);
    const repickConfig = {};
    let selfConfig = JSON.parse(JSON.stringify(tempNodeList));
    if (node.isRow) {
      repickConfig.groupId = node.groupId;
      repickConfig.id = node.id;
      selfConfig = deleteNode(selfConfig, node);
      selfConfig = repickDeleteArr(selfConfig, repickConfig);
    } else {
      selfConfig = deleteColNode(selfConfig, node);
    }
    // 更新节点
    dispatch(dispatchApprovalNodes({ nodeList: selfConfig }));

    // 关闭删除
    handleCancelDelete();
  };

  // 监听是否修改名称弹框
  useEffect(() => {
    setIsShowModifyNameModel(stateApproval?.isShowModifyNameModal);
    setOperateNode(stateApproval?.operateNode);
  }, [stateApproval.isShowModifyNameModal]);

  // 取消修改名称
  const handleCancelModifyNameModal = () => {
    const params = {
      isShowModifyNameModal: false,
      operateNode: {},
    };
    dispatch(dispatchApprovalModifyNameModel(params));
  };
  // 确认修改名称
  const handleConfirmModifyNameModal = (node: any) => {
    const tempNodeList = getCurrentApprovalList(stateApproval);
    tempNodeList.filter((tempNode: any) => {
      if (tempNode.id === node.id) {
        tempNode.nodeName = node.nodeName;
      }
    });
    // 更新节点
    dispatch(dispatchApprovalNodes({ nodeList: tempNodeList }));
    // 关闭弹层
    handleCancelModifyNameModal();
  };

  // 监听增加审批条件抽屉
  useEffect(() => {
    setShowManageConditionDrawer(stateApproval.isShowManageConditionDrawer);
    if (stateApproval.isShowManageConditionDrawer) {
      if (stateApproval.conditionDrawerType === 'modifyCondition') {
        // console.log('管理审批条件');
        setConditionDrawerTitle(TLT(`管理审批条件`));
      } else {
        setConditionDrawerTitle(TLT(`请设置需要添加的条件内容`));
        setConditionNode(stateApproval.operateNode);
        // console.log('请设置需要添加的条件内容', stateApproval.operateNode);
      }
    }
  }, [stateApproval.isShowManageConditionDrawer]);

  // 取消条件抽屉
  const handleCancelConditionDrawer = () => {
    const params = {
      isShowManageConditionDrawer: false,
      conditionDrawerType: 'modifyCondition',
      operateNode: {},
    };
    dispatch(dispatchApprovalConditionDrawer(params));
  };
  const handleConfirmConditionDrawer = (node: any) => {
    switch (stateApproval.conditionDrawerType) {
      case CONDITION_DRAWER_TYPE.addConditionNode:
        // 如果操作类型为添加节点
        // eslint-disable-next-line no-case-declarations
        const selfAddConfig = getNodeFactory(node, stateApproval);
        // 更新节点
        dispatch(dispatchApprovalNodes({ nodeList: selfAddConfig }));
        // 关闭弹层
        handleCancelConditionDrawer();
        break;
      case CONDITION_DRAWER_TYPE.copyConditionNode:
        // console.log('添加条件（1/3）', node);
        // eslint-disable-next-line no-case-declarations
        const selfCopyConfig = [...stateApproval.haveConditionNodeList];
        // eslint-disable-next-line no-case-declarations
        const copyList = restructuringConditions(node, selfCopyConfig, CONDITION_DRAWER_TYPE.copyConditionNode);
        // 更新节点
        dispatch(dispatchApprovalNodes({ nodeList: copyList }));
        // 关闭弹层
        handleCancelConditionDrawer();
        break;
      case CONDITION_DRAWER_TYPE.modifyCondition:
        // console.log('添加条件（1/3）', node);
        // eslint-disable-next-line no-case-declarations
        const selfModifyConfig = [...stateApproval.haveConditionNodeList];
        // eslint-disable-next-line no-case-declarations
        const modifyList = restructuringConditions(node, selfModifyConfig, CONDITION_DRAWER_TYPE.modifyCondition);
        // console.log(modifyList);

        // 更新节点
        dispatch(dispatchApprovalNodes({ nodeList: modifyList }));
        // 关闭弹层
        handleCancelConditionDrawer();
        break;
    }
  };

  // 监听增加人员抽屉
  useEffect(() => {
    setIsShowAddAccountDrawer(stateApproval?.isShowAddAccountDrawer);
    if (stateApproval.isShowAddAccountDrawer) {
      // 修改节点信息
      if (stateApproval.accountDrawerType === ACCOUNT_DRAWER_TYPE.modifyAccountInfo) {
        const node = stateApproval?.operateNode;
        setAccountNode(node);
        // 后端所需数据 账号类型 (5:采购账号，6：审批账号，8：审查账号)
        switch (node.nodeType) {
          case 1:
            setAccountType(ACCOUNT_TYPE.purchaser);
            setAccountDrawerTitle(TLT(`添加采购员账号`));
            break;
          case 2:
            setAccountType(ACCOUNT_TYPE.approver);
            setAccountDrawerTitle(TLT(`添加审批员账号`));
            break;
          default:
            setAccountType(ACCOUNT_TYPE.examiner);
            setAccountDrawerTitle(TLT(`添加审查员账号`));

            // console.log('accountType', stateApproval?.examinePins);
            setPurchasePins(stateApproval?.examinePins);
            break;
        }
      } else {
        // 添加节点
        setAccountType(ACCOUNT_TYPE.approver);
        setAccountDrawerTitle(TLT(`请设置需要添加的审批节点内容`));
        const node = stateApproval?.operateNode;
        setAccountNode(node);
        // console.log('添加节点', node);
      }
    }
  }, [stateApproval.isShowAddAccountDrawer]);

  // 取消添加
  const handleCancelAddAccount = () => {
    const params = {
      isShowAddAccountDrawer: false,
      accountDrawerType: ACCOUNT_DRAWER_TYPE.addAccountNode,
      operateNode: {},
    };
    dispatch(dispatchApprovalAccountModel(params));
  };
  // 添加账号
  const handleConfirmAddAccount = (type: number, node: any, selectPins: any) => {
    // 如果操作类型为修改审批节点信息
    if (stateApproval.accountDrawerType === ACCOUNT_DRAWER_TYPE.modifyAccountInfo) {
      // 关闭节点
      handleCancelAddAccount();
      // 审查员账号
      if (type === ACCOUNT_TYPE.examiner) {
        // 修改审查账号
        dispatch(dispatchApprovalExaminePins({ examinePins: selectPins }));
      } else {
        // 修改节点
        const tempNodeList = getCurrentApprovalList(stateApproval);
        tempNodeList.filter((item: any) => {
          if (item.id === node.id) {
            item.content = selectPins.join(',');
            if(selectPins.length === 1){
              item.specifyApprover = 0; // 将定向设置去掉
            }
            return item;
          }
        });
      }
    } else {
      // 如果操作类型为添加节点
      const selfConfig = getNodeFactory(node, stateApproval);
      // 关闭弹层
      handleCancelAddAccount();
      // 更新节点
      dispatch(dispatchApprovalNodes({ nodeList: selfConfig }));
    }
  };
  // 提交
  const handleSubmit = () => {
    // 如果不能点击，则返回
    if (!isCanSubmit) {
      return;
    }
    setIsCanSubmit(false);

    if (stateApproval.processName === '') {
      message.warning(TLT(`审批流名称不能为空`));
      setInputApprovalError(true);
      // 滑动到输入审批流楼层
      if (myFloorInfoAddRef.current) {
        // console.log('currentErrorNode', myFloorInfoAddRef.current.offsetTop);
        window.scrollTo(0, myFloorInfoAddRef.current.offsetTop || 0);
      }
      setIsCanSubmit(true);
      return;
    }
    let selfConfig = getCurrentApprovalList(stateApproval);

    selfConfig = transformResultData(selfConfig);
    let hasError = false;
    for (let i = 0; i < selfConfig.length; i++) {
      const item = selfConfig[i];
      if (item.content === '') {
        // console.log('提交审批', item);
        if (item.nodeType === NODE_TYPE.nodePurchase) {
          message.warning(TLT(`采购账号不能为空`));
          hasError = true;
        } else if (item.nodeType === NODE_TYPE.nodeApprove) {
          message.warning(TLT(`审批人账号不能为空`));
          hasError = true;
        } else if (item.nodeType === NODE_TYPE.nodeCondition) {
          message.warning(TLT(`审批条件不能为空`));
          hasError = true;
        }
        dispatch(dispatchApprovalErrorNodeId({ errorNodeId: item.id }));
        const currentErrorNode = document.getElementById(item.id);
        const height = heightToTop(currentErrorNode);
        window.scrollTo(currentErrorNode?.scrollWidth || 0, height || 0);
        // console.log('currentErrorNode--scrollWidth', currentErrorNode?.scrollWidth);
        setIsCanSubmit(true);
        break;
      }
      if (item.nodeType === NODE_TYPE.nodeCondition && !item.hasChild) {
        message.warning(TLT(`审批条件下面必须有审批人`));
        hasError = true;
        const currentErrorNode = document.getElementById(item.id);
        const height = heightToTop(currentErrorNode);
        window.scrollTo(currentErrorNode?.scrollWidth || 0, height || 0);

        dispatch(dispatchApprovalErrorNodeId({ errorNodeId: item.id }));

        setIsCanSubmit(true);
        break;
      }
    }
    if (!hasError) {
      const params = {
        processName: stateApproval.processName,
        processType: stateApproval.processType,
        applyPin: stateUserPin,
        approvalNodeList: selfConfig,
        examinePins: stateApproval.examinePins,
      };
      // 编辑需要将原始id 和 key 透穿给后端
      if (operateType === PAGE_TYPE.modifyPage) {
        params.processId = stateApproval.processId;
        params.processKey = stateApproval.processKey;
      }
      saveProcess(params, randomValue).then(res => {
        if (res.success) {
          if (operateType === PAGE_TYPE.addPage) {
            message.success(TLT(`审批流新建成功`));
          } else {
            message.success(TLT(`审批流修改成功`));
          }
          setTimeout(() => {
            history.push('/processList');
          }, 400);
        } else {
          setIsCanSubmit(true);
          message.error(res.msg || res.message);
        }
      });
    }
  };

  return (
    <div className={styles.createApproval}>
      <FloorTitle operateType={operateType}></FloorTitle>
      {operateType === 3 ? (
        <FloorInfoDetail stateApproval={stateApproval}></FloorInfoDetail>
      ) : (
        <div ref={myFloorInfoAddRef}>
          <FloorInfoAdd stateApproval={stateApproval} error={inputApprovalError}></FloorInfoAdd>
        </div>
      )}

      <FloorSetApproval operateType={operateType} stateApproval={stateApproval}></FloorSetApproval>
      {/* <FloorAccountList operateType={operateType} stateApproval={stateApproval}></FloorAccountList> */}
      <Affix offsetBottom={offsetBottom}>
        <FloorSubmit operateType={operateType} confirmCallBack={handleSubmit} isCanSubmit={isCanSubmit}></FloorSubmit>
      </Affix>
      {isShowModifyNameModel && (
        <ModifyNameModal
          open={isShowModifyNameModel}
          node={operateNode}
          cancelCallBack={handleCancelModifyNameModal}
          confirmCallBack={handleConfirmModifyNameModal}
        ></ModifyNameModal>
      )}
      {isShowDeleteModel && (
        <DeletePrompt
          open={isShowDeleteModel}
          node={operateNode}
          confirmCallBack={handleConfirmDelete}
          cancelCallBack={handleCancelDelete}
        ></DeletePrompt>
      )}
      {isShowAddAccountDrawer && (
        <AddAccountDrawer
          accountDrawerType={stateApproval.accountDrawerType}
          accountType={accountType}
          title={accountDrawerTitle}
          open={isShowAddAccountDrawer}
          node={accountNode}
          purchasePins={purchasePins}
          cancelCallBack={handleCancelAddAccount}
          confirmCallBack={handleConfirmAddAccount}
        ></AddAccountDrawer>
      )}
      {isShowManageConditionDrawer && (
        <ManageConditionDrawer
          conditionDrawerType={stateApproval.conditionDrawerType}
          title={conditionDrawerTitle}
          node={conditionNode}
          manageOpen={isShowManageConditionDrawer}
          cancelCallBack={handleCancelConditionDrawer}
          confirmCallBack={handleConfirmConditionDrawer}
        ></ManageConditionDrawer>
      )}
    </div>
  );
};

export default CreateApproval;
