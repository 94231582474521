import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchApprovalType, dispatchApprovalName } from '@/redux/action';

import styles from '../../index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  error?: boolean;
  stateApproval?: any;
}
const FloorInfoAdd: React.FC<IProps> = (props: any) => {
  const dispatch = useDispatch();
  const { stateApproval, error = true } = props;
  const [inputValue, setInputValue] = useState(''); // 页面操作类型
  const [inputError, setInputError] = useState(error);
  const { t } = useTranslation();

  useEffect(() => {
    setInputError(error);
  }, [error]);

  useEffect(() => {
    setInputValue(stateApproval?.processName);
  }, [stateApproval?.processName]);

  // 切换审批流程
  const changeApprovalType = (type: number) => {
    // console.log('切换审批流程', type);
    const params = {
      processType: type,
    };
    dispatch(dispatchApprovalType(params));
  };
  // 监听输入事件
  const handleInputChange = (event: any) => {
    const temp = event.target.value;
    setInputValue(temp);
    if (temp.length > 0) {
      setInputError(false);
    } else {
      setInputError(true);
    }
  };
  // 监听鼠标离开事件
  const handleInputBlur = (event: any) => {
    const temp = event.target.value;
    setInputValue(temp);
    if (temp.length > 0) {
      setInputError(false);
    } else {
      setInputError(true);
    }
    const params = {
      processName: event.target.value,
    };
    dispatch(dispatchApprovalName(params));
  };

  return (
    <div className={styles.info}>
      <div className={styles.infoTitle}>{TLT(`审批流基本信息`)}</div>
      <div className={styles.infoItems}>
        <div className={styles.infoItem}>
          <span className={styles.name}>{TLT(`审批流名称`)}<em>*</em></span>

          <span className={`${styles.inputBorder} ${inputError ? styles.error : ''}`}>
            <input
              placeholder={TLT(`请输入名称`)}
              maxLength={40}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />
            <span className={styles.inputCount}>{inputValue.length ?? 0}/40</span>
            {inputError && <span className={styles.inputError}>{TLT(`请输入审批流名称`)}</span>}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.name}>{TLT(`审批流类型`)}<em>*</em></span>
          <span
            className={`${styles.defaultBtn} ${stateApproval?.processType === 1 ? styles.active : ''} `}
            style={{ marginRight: '4px' }}
            onClick={() => {
              changeApprovalType(1);
            }}
          >
            {TLT(`无条件限制`)}
          </span>
          <span
            className={`${styles.defaultBtn} ${stateApproval?.processType !== 1 ? styles.active : ''} `}
            onClick={() => {
              changeApprovalType(3);
            }}
          >
            {TLT(`条件限制`)}
          </span>
        </div>
      </div>
    </div>
  );
};
export default FloorInfoAdd;
