/*
 * @Author: weiyanna
 * @Date: 2021-12-07 16:33:36
 * @LastEditTime: 2024-10-24 01:15:41
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/components/Address/List/Item/index.tsx
 */
import React from 'react';
import { Button, Row, Space, Col, Checkbox, Tooltip, Image, Card } from 'antd';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { orderTime } from '@/components/Order';
// import AddressVisibilityModal from '../AddressVisibilityModal';
export interface IAppProps {
    orderDetails?: any;
}
const AddressItem: React.FC<IAppProps> = (props: any) => {
  const {orderDetails} = props;
  const saleStatusEnum = {
    10: TLT(`待处理`),
    33: TLT(`处理中`),
    50: TLT(`已完成`),
    60: TLT(`取消`),
  };
  const saleTypeEnum = {
    10: TLT(`退货`),
    20: TLT(`换货`),
    30: TLT(`维修`),
  };
  const infoData = [
        {
            tit:TLT(`售后处理状态`),
            list:[
                {
                    label:TLT(`售后单号`),
                    value:orderDetails?.afterSalesOrderId
                },
                {
                    label:TLT(`订单编号`),
                    value:orderDetails?.orderId
                },
                // {
                //     label:TLT(`IOP单号`),
                //     value:orderDetails?.orderId
                // },
                {
                    label:TLT(`售后状态`),
                    value: saleStatusEnum[orderDetails?.status]
                },
                {
                    label:TLT(`客户名称`),
                    value:orderDetails?.clientName
                },
                {
                    label:TLT(`售后提交时间`),
                    value:orderDetails?.createTime && moment(orderDetails?.createTime).format('YYYY-MM-DD HH:mm:ss')
                },
                {
                    label:TLT(`售后处理方式`),
                    value: saleTypeEnum[orderDetails?.processingType]
                },
                {
                    label:TLT(`预计完成时间`),
                    value: orderDetails?.expectedCompleteTime && moment(orderDetails?.expectedCompleteTime).format('YYYY-MM-DD HH:mm:ss')
                },
                {
                    label:TLT(`实际完成时间`),
                    value:orderDetails?.actualCompleteTime && moment(orderDetails?.actualCompleteTime).format('YYYY-MM-DD HH:mm:ss')
                },
                {
                    label:TLT(`处理备注`),
                    value: orderDetails?.remark
                }
            ]
        },
        {
            tit:TLT(`售后联系信息`),
            list:[ 
                {
                    label:TLT(`售后人`),
                    value: orderDetails?.afsCustomer?.customerContactName
                },
                {
                    label:TLT(`联系电话`),
                    value: orderDetails?.afsCustomer?.customerTel
                },
                {
                    label:TLT(`手机号`),
                    value: orderDetails?.afsCustomer?.customerMobilePhone
                },
                {
                    label:TLT(`EMAIL`),
                    value: orderDetails?.afsCustomer?.customerEmail
                },
                {
                    label:TLT(`邮编`),
                    value: orderDetails?.afsCustomer?.customerPostcode
                }
            ]
        }
    ]
  return (
    <div className={styles.index}>
        {
            infoData.map((ele, idx)=>{
                return <div key={idx} className={styles.titArea}>
                    <div className={styles.title}>{ele.tit}</div>
                    <div className={styles.list}>
                        <Row gutter={24}>
                            {
                                ele.list.map((ele1, idx) => <Col key={idx} span={8} className={styles.item}>
                                    <span className={styles.label}>{ele1?.label}</span>
                                    <span className={styles.text} style={{
                                        marginLeft: '10px'
                                    }}>{ele1?.value}</span>
                                </Col>) 
                            }
                        </Row>
                    </div>
                </div>
            })
        }
      
    </div>
  );
};

export default AddressItem;
