import React, { useMemo, useState, useEffect } from 'react';
import { Empty, Steps, Spin, StepProps } from 'antd';
import { queryDeliveryApi } from '@/api/newApi/order';
import dayjs from 'dayjs';
import styles from './index.module.scss';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

type Params = {
  orderNo: number;
  timeRuleVersion?: string;
};

interface TrackItem extends Omit<StepProps, 'status'> {
  trackContent: string;
  trackOperator: string;
  trackMsgTime: number;
  status?: StepProps['status'];
}

type TrackList = TrackItem[];

type Response = {
  success: boolean;
  value: {
    trackInfoList: TrackList;
    estimatedDeliveryTime: number;
  };
};

const useDeliveryData = (params: Params) => {
  const [trackInfoList, setTrackInfoList] = useState<TrackList>([]);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState<number>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resData: Response = await queryDeliveryApi({
          orderId: params.orderNo,
          timeRuleVersion: params.timeRuleVersion,
        }) as Response;
        if (resData?.success) {
          const { trackInfoList, estimatedDeliveryTime } = resData.value;
          const processedList: TrackList = trackInfoList.map((item, index) => ({
            ...item,
            title: item.trackContent,
            description: dayjs(item.trackMsgTime).format(DATE_FORMAT),
            status: index === 0 ? 'finish' : undefined,
          }));
          setTrackInfoList(processedList);
          setExpectedDeliveryDate(estimatedDeliveryTime);
        }
      } catch (error) {
        console.error('Failed to fetch delivery data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params]);

  return { trackInfoList, expectedDeliveryDate, loading };
};

const LogisticsTrajectory: React.FC<{ params: Params }> = ({ params }) => {
  const { trackInfoList, expectedDeliveryDate, loading } = useDeliveryData(params);

  const formattedDeliveryDate = useMemo(() => 
    expectedDeliveryDate ? dayjs(expectedDeliveryDate).format('YYYY-MM-DD') : '',
    [expectedDeliveryDate]
  );

  return (
    <Spin className={styles.logisticsTrajectory} spinning={loading}>
      <div className={styles.main}>
        <div className={styles.box}>
          <span className={styles.title}>
            {TLT(`轨迹跟踪`)} <span className={styles.deliveryTime}>({TLT('预计{{tag}}送达', { tag: formattedDeliveryDate })})</span>
          </span>
          <div className={styles.content}>
            {trackInfoList.length ? (
              <Steps
                style={{ fontSize: '12px' }}
                progressDot
                current={0}
                direction="vertical"
                items={trackInfoList}
              />
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default LogisticsTrajectory;
