import http from '../axios';

export function getLangVersion(params)  {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/langLibraryVersion/getLangLibraryVersion', params, false, true).then(
        res=>{
          resolve(res)
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
    )
  })
}

export function getLangData(url: string)  {
  return new Promise((resolve, reject) => {
    http('get', url, {}, false, true).then(
        res=>{
          resolve(res)
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
    )
  })
}