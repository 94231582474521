import React, { useCallback, useEffect, useState } from 'react';
import { Anchor, message } from 'antd';
// import { useSelector } from 'react-redux';
// import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
// import { getPrice } from '@/util';
// import { addCarMku, addCarBefore } from '@/api/SkuDetail';
// import { getCookie } from '@/util';
// import Panel from '../Main/Summary/Basic/Panel';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const { Link } = Anchor;

// https://github.com/ant-design/ant-design/issues/30584
// getCurrentAnchor 的 bug 还有，所以只能自己 onChange 同步一下

const Index = () => {
  // const checkedService = useSelector((state: any) => {
  //   return state?.SkuDetail.checkedService;
  // });
  // const main = useRecoilValue('main');
  // const amount = useRecoilValue('amount');
  const { t } = useTranslation();
  const [active, setActive] = useState('#parameter');
  // const productSpecifications = TLT(`规格参数`);
  // const productDescription = TLT(`产品介绍`);
  // const [navs, setNavs] = useState([
  //   { id: '#parameter', title: productSpecifications },
  //   { id: '#graphic', title: productDescription },
  // ]);

  const onClick = (
    e: React.MouseEvent<HTMLElement>,
    link: {
      title: React.ReactNode;
      href: string;
    },
  ) => {
    e.preventDefault();
    if (link.href == '#parameter') {
      window.location.href = '#parameter';
      log('jdisp', 'Product_Specifications');
    } else {
      window.location.href = '#graphic';
      log('jdisp', 'Product_Description');
    }
  };

  const getCurrentAnchor = (activeLink: string) => {
    return activeLink;
  };

  const onChange = useCallback(active => {
    setActive(active);
    getCurrentAnchor(active);
  }, []);

  const onChange2 = useCallback(active => {
    setActive(active);
    getCurrentAnchor(active);
  }, []);
  // const price = main?.price;
  // const unit = main?.propertyList?.find(item => item.key === '销售单位')?.value;
  //
  // //加入采购清单
  // const addCart = useCallback((mkuId: number, num: number) => {
  //   addCarMku({
  //     mkuId,
  //     num,
  //     purchaseListId: 1,
  //   }).then((res: any) => {
  //     if (res.success == true) {
  //       // window.setTimeout(function() {
  //       // window.location.href = '/cart/carts';
  //       // }, 100);
  //       message.success('加入采购清单成功');
  //     } else {
  //       message.warning(res.msg);
  //     }
  //   });
  // }, []);
  //
  // //加入购物之前需要验证商品是否可售
  // const addCarBe = useCallback(async () => {
  //   const mkuIds = main?.mkuId;
  //   if (!mkuIds || !amount) {
  //     return;
  //   }
  //   const result: any = await addCarBefore({
  //     mkuIds,
  //   });
  //   if (result.success == true && result.value && result.value.saleState) {
  //     const skuId = main?.skuId;
  //     addCart(mkuIds, amount);
  //     // log('isp_info', 'add_cart', skuId, getCookie('pin'), 'skuDetail');
  //   } else if (result.success == true && result.value && !result.value.saleState) {
  //     message.warning(result.value.saleMessage);
  //   } else {
  //     message.warning(result.msg);
  //   }
  // }, [main?.mkuId, main?.skuId, amount, checkedService]);

  return (
    <div className={styles.m}>
      {/*<a*/}
      {/*  href="#parameter"*/}
      {/*  className={active === '#parameter' ? 'ant-anchor-link-active' : ''}*/}
      {/*  key="#parameter"*/}
      {/*  onClick={() => {*/}
      {/*    console.log(123);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {TLT(`规格参数`)}*/}
      {/*</a>*/}
      {/*<a*/}
      {/*  href="#graphic"*/}
      {/*  className={active === '#graphic' ? 'ant-anchor-link-active' : ''}*/}
      {/*  key="#graphic"*/}
      {/*  onClick={e => {*/}
      {/*    e.preventDefault();*/}
      {/*    console.log(e.target.href)*/}
      {/*    console.log(e.target)*/}
      {/*    console.log(123);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {TLT(`产品介绍`)}*/}
      {/*</a>*/}
      <Anchor targetOffset={50} onChange={onChange} onClick={onClick} getCurrentAnchor={() => getCurrentAnchor(active)}>
        {/*{navs.map(item => (*/}
        {/*  <Link*/}
        {/*    className={active === item.id ? 'ant-anchor-link-active' : ''}*/}
        {/*    href={item.id}*/}
        {/*    key={item.id}*/}
        {/*    title={item.title}*/}
        {/*  />*/}
        {/*))}*/}
        {
          <>
            <Link
              className={active === '#parameter' ? 'ant-anchor-link-active' : ''}
              href="#parameter"
              key="#parameter"
              title={TLT(`规格参数`)}
            />
            <Link
              className={active === '#graphic' ? 'ant-anchor-link-active' : ''}
              href="#graphic"
              key="#graphic"
              title={TLT(`产品介绍`)}
            />
          </>
        }
      </Anchor>
    </div>
  );
};

export default Index;
