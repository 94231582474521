/*
 * @Author: duchongchong1
 * @Date: 2022-11-18 10:44:17
 * @LastEditors: duchongchong1
 * @LastEditTime: 2023-08-28 11:43:51
 * @FilePath: /isp_International/src/pages/SelectList/index.tsx
 * @Description:
 */
import React, { useState, useEffect } from 'react';
import { Affix, message } from 'antd';
import Main from './components/Main';
import styled from './index.module.scss';
import './indexConent.scss';
import { dispatchShopCarNum } from '@/redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { addProducts, getMarketDetail, getSearchList } from '@/api/newApi/search';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getQueryVariable, GetRequest } from '@/util';
import { getHotCate } from '@/api/newApi/home';

export interface IAppProps {
  location?: any;
}

let checkGoodFlag = true; //默认是true
const SelectList: React.FC<IAppProps> = (props: IAppProps) => {
  const { location } = props;
  const [loading, setLoading] = useState(true);
  const [changeGoodData, setChangeGoodData] = useState([]); //异步改变的商品数据
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  //加入购物
  const addCarBe = async (item: any) => {
    const result: any = await addProducts([
      {
        sku: item.sku,
        num: 1,
        price: item.price,
      },
    ]);
    if (result.success == true) {
      message.success(`${TLT(`加入采购清单成功`)}`);
      dispatch(dispatchShopCarNum(result.value.totalKindCount));
    } else {
      message.error(result.msg);
    }
  };
  // 展示当前搜索词，需要转码展示中文
  const searchWord = (): string => {
    const sw = getQueryVariable('key');
    if (sw) {
      return `${decodeURIComponent(sw)}`;
    }
    return '';
  };
  const getData = async () => {
    setLoading(true);
    if (!checkGoodFlag) {
      return;
    }
    checkGoodFlag = false;


    // 采用搜索接口
    const params = {
      key: '手套' || '',
      pageSize: 48,
      pageNo: 1,
      sort: 0,
      cat: '',
    };
    const result: any = await getSearchList({
      ...params,
    });
    if(result?.success){
      setChangeGoodData(result?.value?.wares || []);
      setLoading(false);
      checkGoodFlag = true;
    }

  };

  useEffect(() => {
    getData();
    window.addEventListener('popstate', getData);
    return () => {
      window.removeEventListener('popstate', getData);
    };
  }, [pathname]);
  return (
    <div className={`${styled.main}`}>
      <div className={`${styled.cont}`}>
        <div className={`${styled.conent}`}>
          <div className={`${styled.goods_list}`}>
            <Main
              changeGoodData={changeGoodData}
              addCarBe={addCarBe}
              loading={loading}
              isEmpty={!loading && changeGoodData.length == 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectList;
