/*
 * @Author: duchongchong1
 * @Date: 2022-11-02 16:43:23
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-07-30 21:34:58
 * @FilePath: /isp_International/src/api/newApi/header.ts
 * @Description:
 */
import http from '../axios';

export  function getLogo()  {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/public/logo', {}, false, true).then(
        res=>{
          resolve(res)
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
    )
  })
}
// 查询 header类目树
export function getTree(params: any) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/category/gettree', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 本土跨境站点切换接口
export function getChangeStation(params: any) {
  return http('get', '/jdig/public/change/station', params, false, true)
}
// 语言切换接口
export function getChangeEnv(params: { env: string, reqStartTime?:number }) {
  console.log('params', params)
  return new Promise((resolve, reject) => {
    http('get', '/jdig/public/changeEnv', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
//emil
export function sendVerifyMail(params: any) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/account/sendVerifyMail', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 获取首页广告配置
export function getAd(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/ad/getAd', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//获取权限功能配置
export function getAuthority(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/auth/authority', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
