/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2023-12-04 15:09:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-10-23 10:42:32
 * @FilePath: /isp_International/src/api/newApi/personalCenter/address.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from '../../axios';

/**
 * 获取地址列表
 */
export type GetAddressListType = {
  pageSize: number;
  pageNo: number;
  keyword: string;
};
export const getAddressList = (data: GetAddressListType) => {
  return http('jsonpost', '/jdig/address/query', data, true, true);
};

/**
 * 新增地址
 */
export type CreateAddressRequestType = {
  pin: string;
  receivePhone: string;
  country: string;
  receiveName: string;
  detailAddress: string;
  defaultStatus: 0 | 1; // 0表示非默认 ，1表示默认
};
export const createAddress = (data: CreateAddressRequestType) => {
  return http('jsonpost', '/jdig/address/add', data, true, true);
};

/**
 * 修改地址
 */
export type ModifyAddressRequestType = {
  id: string;
  receivePhone: string;
  country: string;
  receiveName: string;
  detailAddress: string;
  defaultStatus: 0 | 1; // 0表示非默认 ，1表示默认
};
export const modifyAddress = (data: ModifyAddressRequestType) => {
  return http('jsonpost', '/jdig/address/update', data, true, true);
};

/**
 * 根据ID查询地址详情
 */
// export const getAddressDetail = (id: string) => http('jsonpost', '/jdig/address/add', { id }, true, true);

// 127.0.0.1/address/assign?id=1&pin=hanhongwei测试
// export const
/**
 * 删除地址
 */
export const removeAddress = (ids: number[]) => http('jsonpost', '/jdig/address/del', { ids }, true, true);


//获取省市区地址列表
export const getAddressItemList = (params: any) => {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/address/children', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
};

// * 根据ID查询地址详情

export const getAddressDetail = (params: any) => {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/address/querydetail', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
};

