import React, { useState, useEffect, memo } from 'react';
import { Affix, message, Pagination, Space, Spin } from 'antd';
import Crumbs from '@/components/SearchList/Crumbs';
import Main from './components/Main';
import styled from './index.module.scss';
import './indexConent.scss';
import { dispatchAnnounceData, dispatchCheckCount, dispatchShopCar, dispatchShopCarNum } from '@/redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { addProducts } from '@/api/newApi/search';
// import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

export interface IAppProps {
  location?: any;
}

let checkGoodFlag = true; //默认是true
const SearchList: React.FC<IAppProps> = (props: IAppProps) => {
  const { location } = props;
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [chooseGoodItem, setChooseGoodItem] = useState([]); //选中商品组
  const [chooseGoodIndex, setChooseGoodIndex] = useState(0); //选中商品index
  const [initGoodData, setInitGoodData] = useState([]); // 初始化商品数据
  const [changeGoodData, setChangeGoodData] = useState([]); //异步改变的商品数据
  const [isRefresh, setIsRefresh] = useState(true); //判断是否是重新请求接口
  const { t, i18n } = useTranslation();
  const [loadings, setloadings] = useState(true);
  const [isFirst, setIsFirst] = useState(true); //是否是第一次进入该页面
  const red_goods_data: any = useSelector((state: any) => {
    return state.SearchList;
  });
  const {
    data,
    selectParams,
    extAttrList,
    pageInfo,
    allMkuIds,
    loading,
  } = red_goods_data;

  const dispatch = useDispatch();

  //加入购物
  const addCarBe = async (num: any, item: any) => {
    if (num === 1) {
      const result: any = await addProducts([
        {
          sku: item.sku,
          num: 1,
          price: item?.showCurrency?.salePrice,
        },
      ]);
      if (result.success == true) {
        message.success(TLT(`加入采购清单成功`));
        dispatch(dispatchShopCarNum(result.value.totalKindCount));
      } else {
        message.error(result.msg);
      }
    } else {
      message.info(TLT(`该商品不可售`));
    }
  };
  const replaceParamVal = () => {
    if (!checkGoodFlag) {
      return;
    }
    checkGoodFlag = false;
    const params = {
      key: location.cat ? '' : location?.query || '',
      pageSize: 48,
      pageNo: 1,
      sort: 0,
      cat: location.cat ? location?.cat : '',
    };
    const Params = {
      ...selectParams,
      cataIdList: location?.cataIdList,
      cat: location?.cat,
    };
    dispatch(dispatchAnnounceData(params, isFirst, Params));
    setIsRefresh(true);
    checkGoodFlag = true;
  };

  const sum = (arr: any) => {
    let num = 0;
    arr.forEach((item: any) => {
      num += item.mkuNum;
    });
    return num;
  };

  useEffect(() => {
    setloadings(true);
    replaceParamVal();
  }, [location?.query]);
  const copyInitGoodData = () => {
    if (isRefresh) {
      //初始化状态
      const initGoodData = red_goods_data.data ? red_goods_data.data.concat() : [];
      initGoodData.map((item: any) => {
        item.showAttrs = {
          img: item.img,
          sku: item.sku,
          price: item?.showCurrency?.salePrice,
          skuId: item.sku,
          wareName: item.wareName,
        };
      });
      const changeGoodData = [...initGoodData];
      //初始化数据
      setInitGoodData(initGoodData);
      setChangeGoodData(changeGoodData);
      setloadings(false);
    } else {
      //数据属性发生变化
      const initGoodData = red_goods_data.data ? red_goods_data.data.concat() : [];
      initGoodData.map((item: any, index: any) => {
        changeGoodData[index].checked = item.checked;
        changeGoodData[index].mkuNum = item.mkuNum ? item.mkuNum : changeGoodData[index].mkuNum;
      });
      const res = changeGoodData.filter((item: any) => {
        return item.checked;
      });
      if (res.length > 0 && res.length >= changeGoodData.length) {
        setIsAllChecked(true);
      } else {
        setIsAllChecked(false);
      }
      //选中统计
      dispatch(dispatchCheckCount(sum(res)));
      setloadings(false);
    }
  };
  useEffect(() => {
    copyInitGoodData();
  }, [allMkuIds, red_goods_data.data]);

  const chooseGood = (chooseGoodItem: any, chooseIndex: any) => {
    console.log(chooseGoodItem, 'chooseGoodItem');
    setChooseGoodItem(chooseGoodItem);
    setChooseGoodIndex(chooseIndex);
    //将选中的数据找出，赋值给初始化数据
    changeGoodData.forEach((initItem: any, index: any) => {
      if (initItem.sku === chooseGoodItem[0].sku) {
        changeGoodData[index].showAttrs = {
          img: chooseGoodItem[chooseIndex].img,
          sku: chooseGoodItem[chooseIndex].sku,
          price: chooseGoodItem[chooseIndex]?.price,
          skuId: chooseGoodItem[chooseIndex].skuId,
          wareName: initItem.wareName,
        };
      }
    });
  };
  const goTop = () => {
    const anchorElement = document.getElementById('__position');
    if (anchorElement) {
      anchorElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    setIsFirst(false);
  }, []);
  return (
    <div className={`${styled.main}`}>
      <Affix offsetTop={0}>
        <div className={`${styled.conditions_conent}`}>
          <Crumbs updataData={replaceParamVal}></Crumbs>
        </div>
      </Affix>
      <div className={`${styled.cont}`}>
        <div className={`${styled.conent}`}>
          <div className={`${styled.goods_list}`}>
            {(loading || loadings) && (
              <div className={`${styled.loadings}`}>
                <Space size="middle">
                  <Spin size="large" />
                </Space>
              </div>
            )}
            
            {(!loading && !loadings) && (
              <Main
                changeGoodData={changeGoodData}
                addCarBe={addCarBe}
                chooseGoodItem={chooseGoodItem}
                chooseGoodIndex={chooseGoodIndex}
                chooseGood={chooseGood}
                loading={loading}
                extAttrList={extAttrList}
                updataData={replaceParamVal}
                isEmpty={(!loading && !loadings) && data.length == 0}
                pageInfo={pageInfo}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchList;
