/*
 * @Author: weiyanna
 * @Date: 2022-03-25 13:55:32
 * @LastEditTime: 2023-08-28 11:50:10
 * @LastEditors: duchongchong1
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_International/src/api/newApi/carts.ts
 */
import http, { messageHttp } from '../axios';

// 国际站接口

/**
 * 获取采购清单数量
 * @param params
 */
export function getCartNum(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/cart/getCartNum', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 购物车列表
 * @param params
 */
export function getCartList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/cart/getCartList', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 修改购物车商品数量
 * @param params
 */
export function updateProductNum(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/cart/updateProductNum', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 获取单个mku库存数量
 * @param params
 */
export function getRemainStock(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/stock/remain', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 新增购物车
 * @param params
 */
export function getAddProducts(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/addProducts', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 批量立即购买
 * @param params
 */
export function batchToBuy(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/trade/batch/ispBuyNow', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 购物车正选反选接口
 * @param params
 */
export function checkProduct(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/checkProduct', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 删除商品
 * @param params
 */
export function delProducts(params: any) {
  console.log('删除商品', params);
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/delProducts', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getLogin(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/login', params, false, false).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getPersonInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/account/getPersonInfo', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getPunchoutOrder(data: { orderId: string }) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/punchoutOrder', data, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

