/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-04-18 16:04:09
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-10-23 10:40:09
 * @FilePath: /isp_International/src/api/newApi/addressAuthority.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from '../axios';

/**
 * 权限地址列表
 * @param params
 */
export function getAuthoritList(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('post', '/jdig/address/authority/page', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
  }

  /**
 * 设置权限开关
 * @param params
 */
export function setAuthoritStatus(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/address/authority/update ', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
  }
