/*
 * @Author: your name
 * @Date: 2021-10-27 17:08:30
 * @LastEditTime: 2021-11-30 18:10:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isp_shop_detail/src/widget/imall_isp_cart/src/atoms/index.ts
 */
import current from './current';
import loading from './loading';
import skuList from './skuList';
import totalInfos from './totalInfos';
import selectedSkuList from './selectedSkuList';
import selected from './selected';
import isCanPlaceOrder from './isCanPlaceOrder';
import showSlide from './showSlide';
import disableSkuList from './disableSkuList';
import errorSkuProduct from './errorSkuProduct';
import successProduct from './successProduct';
import errorProduct from './errorProduct';

const atoms = {
  current,
  loading,
  skuList,
  totalInfos,
  selectedSkuList,
  selected,
  isCanPlaceOrder,
  showSlide,
  disableSkuList,
  errorSkuProduct,
  successProduct,
  errorProduct
};

export default atoms;
