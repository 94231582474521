/*
 * @Author: duchongchong1
 * @Date: 2022-11-01 21:42:34
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-11-18 18:09:42
 * @FilePath: /isp_International/src/layout/BaseLayout.tsx
 * @Description:
 */
import React, { useEffect } from 'react';
import { ConfigProvider, message } from 'antd';
import zh from 'antd/lib/locale/zh_CN';
import vi from 'antd/lib/locale/vi_VN';
import en from 'antd/lib/locale/en_US';
import hu from 'antd/lib/locale/hu_HU';
import th from 'antd/lib/locale/th_TH';
import ms from 'antd/lib/locale/ms_MY';
import pt_BR from 'antd/lib/locale/pt_BR';
import NewFooter from '@/components/NewFooter';
import Menu from '@/components/NavBar';
import { getLocal, LocalNameEnum } from '@/util/storage';

const langs = {
  zh,
  vi,
  en,
  hu,
  th,
  ms,
  'pt-BR': pt_BR
}

const isLangKey = (key: any): key is keyof typeof langs => {
  return key in langs;
}

const _top = (document.body.clientHeight - 38) / 2;
message.config({
  duration: 2,
  maxCount: 1, // 最大显示数, 超过限制时，最早的消息会被自动关闭
  top: _top, // 到页面顶部距离
});
const BaseLayout = (props: any) => {
  const { children } = props;

  const mulLangLocalTagRemark = getLocal(LocalNameEnum.mulLangLocalTagRemark)
  const local = isLangKey(mulLangLocalTagRemark) ? langs[mulLangLocalTagRemark] : langs['en'];

  return (
    <ConfigProvider locale={local}>
      <div className="ispWrapper">
        <Menu {...props}></Menu>
        <div style={{ width: '100%', position: 'relative', overflowX: 'hidden', flex:'auto' }}>{children}</div>
        <NewFooter />
      </div>
    </ConfigProvider>
  );
};

export default BaseLayout;
