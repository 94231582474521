import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cookies from 'react-cookies';
import Descriptions from './Descriptions';
import LocalSimilarPro from './LocalSimilarPro';
import DSpecifications from './DetailedSpecifications';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import Carousel from './Carousel';
import Panel from './Panel';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';

const Index = () => {
  const [stationType, setStationtype] = useState(cookies.load('stationType')); // 跨境还是本土站点
  // const main = useRecoilValue('main');
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const basicInfo = useRecoilValue('basicInfo');
  const materialInfo = useRecoilValue('materialInfo');


  // 获取用户操作权限
  const userLimit: any = useSelector((state: any) => {
    return state?.UserLimit;
  });
  // const { t } = useTranslation();
  // const item =
  //   main?.propertyList &&
  //   main?.propertyList.length > 0 &&
  //   main?.propertyList[0].key === '订货号' &&
  //   main?.propertyList[0];

  // 是否显示型号 （具体逻辑看readme）
  // const Model = () => {
  //   if (!item) {
  //     const _m = main?.basicInfo && main?.basicInfo?.model;
  //
  //     if (_m && main?.pageType !== 1) {
  //       return (
  //         <div className={styles.tag_brand}>
  //           <div className={styles.tag_brand_title}>型号</div>
  //           <div className={styles.tag_brand_name}>{_m}</div>
  //         </div>
  //       );
  //     } else {
  //       return <div></div>;
  //     }
  //   }
  // };

  const searchBrand = () => {
    // if (main?.basicInfo?.brandName) {
    //   window._openPage(
    //     `/rede/page/${main?.pageType === 1 ? 'list' : 'listCable'}?key=` +
    //       encodeURIComponent(main?.basicInfo?.brandName),
    //     '_self',
    //   );
    // }

    if (basicInfo?.brandInfoVo?.name) {
      // window._openPage(
      //   `/rede/page/${basicInfo?.isMainBrand === 1 ? 'list' : 'listCable'}?key=` +
      //     encodeURIComponent(basicInfo?.brandInfoVo?.name),
      //   '_self',
      // );
      const path = {
        pathname: '/page/list',
        query: basicInfo?.brandInfoVo?.name,
      };
      history.push(path);
    }
    //
  };

  return (
    <div className={styles.m}>
      {/*<div className={styles.pic}>{main?.images && <Carousel images={main?.images} />}</div>*/}

      {/**/}
      <div className={styles.pic}>{basicInfo?.imageDTOList && <Carousel images={basicInfo?.imageDTOList} />}</div>
      {/**/}

      <div className={styles.info}>
        <div className={styles.info_box}>
          <div className={styles.tag}>
            <div className={styles.tag_brand} onClick={searchBrand}>
              {/*{main?.basicInfo?.logoUrl && (*/}
              {/*  <div className={styles.tag_brand_img}>*/}
              {/*    <img*/}
              {/*      src={`https://img30.360buyimg.com/popshop/${main?.basicInfo?.logoUrl}`}*/}
              {/*      style={{ height: '24px' }}*/}
              {/*      alt="logo"*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}

              {/**/}
              {basicInfo?.imagePath && (
                <div className={styles.tag_brand_img}>
                  {basicInfo?.brandInfoVo?.logoUrl && (
                    <img
                    src={basicInfo?.brandInfoVo?.logoUrl?.match('http')
                    ? basicInfo?.brandInfoVo?.logoUrl
                    : `https://img30.360buyimg.com/popshop/${basicInfo?.brandInfoVo?.logoUrl}`
                    }
                      style={{ height: '24px' }}
                      alt="logo"
                    />
                  )}
                </div>
              )}
              {/**/}

              {/*<div className={styles.tag_brand_name}>{main?.basicInfo?.brandName}</div>*/}

              {/**/}
              <div className={styles.tag_brand_name}>
                {basicInfo?.brandInfoVo?.name}
              </div>
              {/**/}
            </div>
            {/*{item && (*/}
            {/*  <div className={styles.tag_brand}>*/}
            {/*    <div className={styles.tag_brand_title}>{item.key}</div>*/}
            {/*    <div className={styles.tag_brand_name}>{item.value}</div>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>

          {/*<div className={styles.name}>{main?.basicInfo?.skuName}</div>*/}

          {/**/}
          <div className={styles.name}>
            <span>{`${basicInfo?.sourceCountryCode == 'CN' ? TLT(`【跨境】`) : ''}${ basicInfo?.productName}`}</span>
            {userLimit?.limitData?.enableMaterial == 1 && <span className={styles.material_name}>{TLT(`物料编码`)}:{materialInfo?.materialId || '-'}</span>}
          </div>
          {/**/}
        </div>
        <div className={styles.descriptions}>
          <Descriptions></Descriptions>
          {basicInfo?.sourceCountryCode == 'CN' && <div style={{
            color:"#000000D9",
            fontSize:"12px"
          }}>{TLT(`跨境商品，货期和报关方式与境内贸易商品不同，敬请留意。如有疑问，请随时联系我们的运营经理`)}</div>}
          {/*{main?.pageType === 1 && <DSpecifications></DSpecifications>}*/}
          {/* {stationType == '0' ? <LocalSimilarPro/> : <DSpecifications/>} */}
          <LocalSimilarPro/>
          {/* {stationType != '0' && <Panel></Panel>} */}
          <Panel></Panel>
        </div>
      </div>
    </div>
  );
};

export default Index;
