// @ts-nocheck
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from 'react';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import styled from './index.module.scss';
import InputNumber from 'rc-input-number';
import { useSelector, useDispatch } from 'react-redux';
import { GetRequest, insertParam, getClstag } from '@/util/index';
import { dispatchViewType, dispatchAnnounceData } from '@/redux/action';
import cookies from 'react-cookies';
import './indexConent.scss';
import { useTranslation } from 'react-i18next';
import GlobalLocation from '@/components/GlobalLocation';
import iconDownWhite from '@/assets/images/searchList/icon_down_white.png';
import iconDownGrey from '@/assets/images/searchList/icon_down_grey.png';
import Icon from '@/components/common/Icon';

export interface IAppProps {
  updataData: Function;
  searchWhite: boolean;
  from: string;
}
declare module 'react' {
  interface HTMLAttributes<T> {
    clstag?: string;
  }
}

const Page: React.FC<IAppProps> = props => {
  const dispatch = useDispatch();
  const red_goods_data: any = useSelector((state: any) => {
    return state.SearchList;
  });
  const { view, checkedCount, canMcSearch, params, selectParams } = red_goods_data;
  // 综合、销量、价格排序字段
  const sortIdxMap: [string] = ['0', '3', '4', '1', '2'];
  const { searchWhite, updataData, from } = props;
  const [viewTypeIdx, setViewTypeIdx] = useState(1);
  const [viewSortIdx, setViewSortIdx] = useState(() => {
    // const url: any = GetRequest();
    // 检验当前url中sort是否是设置的值
    return params.sort || '0';
  });
  const { t, i18n } = useTranslation();
  const [mcSearch, setMcSearch] = useState(() => {
    const url: any = GetRequest();
    return url.mcSearch == 1 ? true : false;
  });
  const [pageToolNum, setPageToolNum] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [priceMinFilter, setPriceMinFilter] = useState(() => {
    const url: any = GetRequest();
    if (url.priceFilter) {
      const priceFilter = url.priceFilter.split('-');
      return priceFilter[0];
    }
    return '';
  });
  const [priceMaxFilter, setPriceMaxFilter] = useState(() => {
   
    return null;
  });
  const [isLocation, setIsLocation] = useState(cookies.load('stationType')) // 0 | 1 是否是本地商品 0本地 1跨境
  const hasThingRef = useRef(null); // 仅显示有货ref


  //列表视图或者大图模式
  const hankViewTypeFn = (idx: number) => {
    setViewTypeIdx(idx);
    dispatch(dispatchViewType(idx));
  };
  //综合、销量、价格排序
  const hankViewSortFn = (idx: string) => {
    // 限制了综合销量价格的点击，如果已经选中，则不在重新请求
    const param = {
      ...params,
      sort: idx,
    };
    dispatch(dispatchAnnounceData(param, false, selectParams));
    setViewSortIdx(idx);
  
  };
  const replaceParamVal = (pageType: string) => {
    let param: any = {};
    if (pageType == 'prev') {
      //如果到第一页
      if (pageNum == 1) return;
    
   
      param = {
        ...params,
        pageSize: red_goods_data.params.pageSize,
        pageNo: Number(pageNum) - 1,
      };
    } else {
      //如果到最后一页
      if (pageNum >= pageToolNum) return;
      param = {
        ...params,
        pageSize: red_goods_data.params.pageSize,
        pageNo: Number(pageNum) + 1,
      };
    }
    dispatch(dispatchAnnounceData(param, false, selectParams));
    // insertParam('pageNo', params.pageNo);
    // updataData({ changePage: true });
  };

  const clearMinMaxPrice = () => {
    setPriceMinFilter('');
    setPriceMaxFilter('');
    // insertParam('priceRangeRole', '');
    const param = {
      ...params,
      priceMinFilter: null,
      priceMaxFilter: null
    };
    dispatch(dispatchAnnounceData(param, false));
  };
  //设置价格区间
  const confirmPrice = () => {
    let minPrice = 0;
    let maxPrice = 0;
    let param = {}
    if ((priceMinFilter == 0 || priceMinFilter == '') && (priceMaxFilter == 0 || priceMaxFilter == '')) {
      return;
    } else if ((priceMaxFilter == 0 && priceMinFilter >= 0) || (priceMaxFilter == '' && priceMinFilter >= 0)) {
      // insertParam('priceRangeRole', priceMinFilter);
       param = {
        ...params,
        priceMinFilter: priceMinFilter,
      };
    } else if ((priceMinFilter <= 0 || priceMinFilter == '') && priceMaxFilter > 0) {
      // insertParam('priceRangeRole', 0 + '-' + priceMaxFilter);
      param = {
        ...params,
        priceMinFilter: 0,
        priceMaxFilter: priceMaxFilter
      };
    } else {
      if (priceMinFilter > priceMaxFilter) {
        minPrice = priceMaxFilter;
        maxPrice = priceMinFilter;
      } else {
        minPrice = priceMinFilter;
        maxPrice = priceMaxFilter;
      }
      param = {
        ...params,
        priceMinFilter: minPrice,
        priceMaxFilter: maxPrice
      };
      // insertParam('priceRangeRole', minPrice + '-' + maxPrice);
    }
    // updataData();
    dispatch(dispatchAnnounceData(param, false));
  };

  useEffect(() => {
    const { pageSize, page, toolNum } = red_goods_data;
    if (toolNum / pageSize < 1 && toolNum / pageSize > 0) {
      setPageToolNum(1);
    } else if (Math.round(toolNum) == 0) {
      setPageToolNum(0);
    } else {
      setPageToolNum(Math.ceil(toolNum / pageSize));
    }
    //设置当前第几页
    setPageNum(Math.round(toolNum) == 0 ? 0 : page);
    //设置价格排序
    setViewSortIdx(() => {
      // 检验当前url中sort是否是设置的值
      return sortIdxMap.indexOf(params.sort) > -1 ? params.sort : '0';
    });
  }, [red_goods_data.data]);



  return (
    <div className={`${styled.conent}`}>
      <div className={`${styled.page_left}`} style={{display:'none'}}>
        {/* {
          viewTypeIdx === 0 ? '' : <div className={`${styled.ps_title}`}>
            <span>配送至</span>
          </div>
        }
        <div className={styled.view_address}>
          <GlobalLocation />
        </div> */}
        <div className={`${styled.view_sortgroup}`}>
          <div className={`${styled.view_sort} ${styled.comprehensive}`}>
            <a
              className={`${
                viewSortIdx === '0' ? styled.curr1 : viewSortIdx == '0' ? styled.curr2 : styled.curr3
              } `}
              onClick={() => {hankViewSortFn('0');log('jdisp', 'Search_Comprehensive');}}
            >
              <span>{TLT(`综合`)}</span>
              <em className={`${styled.fs_down}`}>
              <CaretDownOutlined
                  style={{
                    fontSize: '10px',
                    color: viewSortIdx == '0' ? '#ffffff' : '#999999',
                    position: 'absolute',
                    top: '15px',
                  }}
                />
              </em>
            </a>
          </div>
          <div className={`${styled.view_sort}`}>
            <a
              className={`${
                viewSortIdx == '4'
                  ? styled.curr1
                  
                  : styled.curr3
              } `}
              onClick={() => {
                hankViewSortFn('4');
                log('jdisp', 'Search_ranking');
              }}
            >
              <span>{TLT(`销量`)}</span>
              <em className={`${styled.fs_down}`} clstag="h|keycount|Search|16">
                {/* <CaretUpOutlined
                  style={{
                    fontSize: '10px',
                    color: viewSortIdx == '3' ? '#ffffff' : '#999999',
                    position: 'absolute',
                    top: '12px',
                  }}
                /> */}
                <CaretDownOutlined
                  style={{
                    fontSize: '10px',
                    color: viewSortIdx == '4' ? '#ffffff' : '#999999',
                    position: 'absolute',
                    top: '15px',
                  }}
                />
              </em>
              {/* <em className={`${styled.fs_down}`}>
                {viewSortIdx == '4' ? (
                  <Icon icon={iconDownWhite} width="10px" height="26px" style={{ paddingTop: '10px' }} />
                ) : (
                  <Icon icon={iconDownGrey} width="10px" height="26px" style={{ paddingTop: '10px' }} />
                )}
              </em> */}
            </a>
          </div>
          <div className={`${styled.view_sort} ${styled.sort_price}`}>
            <a
              className={`${
                viewSortIdx == '1'
                  ? styled.curr1
                  : viewSortIdx == '2'
                  ? styled.curr2
                  : styled.curr3
              } `}
              onClick={() => {
                viewSortIdx == '1'
                  ? hankViewSortFn('2')
                  : viewSortIdx == '2'
                  ? hankViewSortFn('1')
                  : hankViewSortFn('1');
                log('jdisp', 'Search_Price');
              }}
            >
              <span>{TLT(`价格`)}</span>
              <em className={`${styled.fs_down}`} clstag="h|keycount|Search|16">
                <CaretUpOutlined
                  style={{
                    fontSize: '10px',
                    color: viewSortIdx == '1' ? '#ffffff' : '#999999',
                    position: 'absolute',
                    top: '12px',
                  }}
                />
                <CaretDownOutlined
                  style={{
                    fontSize: '10px',
                    color: viewSortIdx == '2' ? '#ffffff' : '#999999',
                    position: 'absolute',
                    top: '18px',
                  }}
                />
              </em>
            </a>
          </div>
          <div className={`${styled.view_price}`}>
            <div className={`${styled.price_set}`}>
              <div className={`${styled.fl}`}>
                <span>$</span>
                <InputNumber
                  prefixCls={'screening-number'}
                  min={0}
                  max={999999999999}
                  value={priceMinFilter}
                  onChange={value => {
                    if (/^\d+$/.test(value)) {
                      setPriceMinFilter(value);
                    }
                  }}
                />
              </div>
              {/*<em>-</em>*/}
              <p>{TLT(`-`)}</p>
              <div className={`${styled.fl}`}>
                <span>$</span>
                <InputNumber
                  prefixCls={'screening-number'}
                  min={0}
                  max={999999999999}
                  value={priceMaxFilter}
                  onChange={value => {
                    if (/^\d+$/.test(value)) {
                      setPriceMaxFilter(value);
                    }
                  }}
                />
              </div>
            </div>
            <div className={`${styled.price_edit}`}>
              <a
                className={`${styled.btn1}`}
                onClick={() => {
                  clearMinMaxPrice();
                }}
              >
                {TLT(`清除`)}
              </a>
              <a
                className={`${styled.btn2}`}
                onClick={() => {
                  confirmPrice();
                }}
              >
                {TLT(`确认`)}
              </a>
            </div>
          </div>
        </div>
        {/* <div className={`${styled.view_inventory} ${viewTypeIdx === 1 ? styled.border_left : styled.border_left_small}  ${!searchWhite && styled.hide}`}>
          <label htmlFor="jdLogistics" className={`${styled.in_label}`}>
            <input
              hidden
              id="jdLogistics"
              type="checkbox"
              checked={isJdLogistics}
              onChange={event => {
                jdLogisticsFn(event);
              }}
            ></input>
            <i className={`${styled.icon}`}></i>
            <span>京东物流</span>
          </label>
        </div> */}

        {/* <div className={`${styled.view_inventory} ${!searchWhite && styled.hide}`}>
          <label htmlFor="inventory" className={`${styled.in_label}`}>
            <input
              hidden
              ref={hasThingRef}
              id="inventory"
              type="checkbox"
              checked={isInventory}
              onChange={event => {
                inventoryFn(event);
              }}
            ></input>
            <i className={`${styled.icon}`}></i>
            <span>仅显示有货</span>
          </label>
        </div> */}
        {/* <div className={`${styled.view_inventory} ${Number(canMcSearch) === 0 && styled.hide}`}>
            <label htmlFor="mcSearch" className={`${styled.in_label}`}>
              <input
                hidden
                id="mcSearch"
                type="checkbox"
                checked={mcSearch}
                clstag={from === 'SearchListCable' ? getClstag('search_materialcode', 'isp') : ''}
                onChange={event => {
                  mcSearchFn(event);
                }}
              ></input>
              <i className={`${styled.icon}`}></i>
              <span>物料编码</span>
            </label>
          </div> */}
      </div>
      <div className={`${styled.page_right}`}>
        {/* <div className={`${styled.view_type} ${viewTypeIdx === 1 ? '' : styled.view_type_small}`}>
          <span
            className={`${viewTypeIdx == 0 ? styled.act : ''}`}
            onClick={() => {
              viewTypeIdx != 0 ? hankViewTypeFn(0) : '';
            }}
          >
            <i></i>列表
          </span>
          <span
            className={`${viewTypeIdx == 1 ? styled.act : ''}`}
            onClick={() => {
              viewTypeIdx != 1 ? hankViewTypeFn(1) : '';
            }}
          >
            <i></i>大图
          </span>
        </div> */}
        <div className={`${styled.cumulative}`}>
        {TLT(`共`)} <span style={{
          marginRight: '3px',
        }}>{red_goods_data.toolNum}</span>{TLT(`件商品`)}
        </div>
        <div className={`${styled.goods_page}  ${viewTypeIdx === 1 ? '' : styled.goods_page_small}`}>
          <div className={` ${pageNum == 1 || pageNum == 0 ? styled.disablePage : ''}`}>
            <div
              className={`${styled.page_l} `}
              onClick={() => {
                replaceParamVal('prev');
              }}
            >
              <i></i>
            </div>
          </div>
          <div className={`${styled.page_con}`}>
            <span>{pageNum}</span>/{pageToolNum}
          </div>
          <div className={` ${pageNum >= pageToolNum ? styled.disablePage : ''}`}>
            <div
              className={`${styled.page_r} `}
              onClick={() => {
                replaceParamVal('next');
              }}
            >
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Page;
