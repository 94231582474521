/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-03-01 10:06:04
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-04 17:18:49
 * @FilePath: /isp_International/src/api/newApi/settleConfig.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from '../axios';

export function deleteEnum(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/settleConfig/deleteItem', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

export function insertEnum(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/settleConfig/createItem', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

export function getSettleConfigAll(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('get', '/jdig/settleConfig/queryAllConfig', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

export function insertConfigBatch(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/settleConfig/saveOrUpdateConfig', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

export function deleteFunction(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/settleConfig/deleteConfig', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

export function downloadSettleConfig(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('get', '/jdig/settleConfig/enumTemplate', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

