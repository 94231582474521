import React, {useCallback, useEffect, useState} from 'react';
import {Drawer, Checkbox, message, Button} from 'antd';
// eslint-disable-next-line prettier/prettier
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { queryProcessAccount } from '@api/newApi/approval';
import {ACCOUNT_DRAWER_TYPE, ACCOUNT_TYPE} from "@/pages/Approval/Create/constants";
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
/**
 * 添加采购员账号
 * @constructor
 */
interface IProps {
  accountDrawerType?:number;
  accountType?: number;
  title?: string;
  open?: boolean;
  node?: any;
  purchasePins?: any;
  cancelCallBack?: () => void;
  confirmCallBack?: (type: number, node: any, selectPins: any) => void;
}
const AddAccountDrawer: React.FC<IProps> = (props: any) => {
  const { accountDrawerType, accountType, title, open = false, node, purchasePins, cancelCallBack, confirmCallBack } = props;
  const [accountList, setAccountList] = useState([]);

  const [pageInfo, setPageInfo] = useState({});

  const [selectPins, setSelectPins] = useState<Array<string>>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [nodeName, setNodeName] = useState(node?.nodeName);
  const [errorMessage, setErrorMessage] = useState(false); // 没有输入就点击提交按钮
  const { t } = useTranslation();

  const initData = (cutPage:number, accountName:string) =>{
    const params = {
      accountType: accountType,
      cutPage: cutPage,
      pageSize: 20,
      pinLike: accountName
    };
    queryProcessAccount(params).then(res => {
      if (res?.success) {
        setAccountList(res?.value?.items);
        setPageInfo(res?.value);
        if(accountType === ACCOUNT_TYPE.examiner) {
          if(purchasePins?.length > 0){
            setSelectPins(purchasePins);
          }
        } else {
          if(node?.content?.length > 0) {
            setSelectPins(node?.content.split(','));
          }
        }
      }
    });
  }
  useEffect(() => {
    if (open){
      initData(1, '');
    } else{
      setSelectPins([]);
    }
  }, [open, accountType]);

  //往前
  const handleClickPagePre = (pre:any) =>{
    initData(pre, '');
  }
  // 往后
  const handleClickPageNext = (next:any) =>{
    initData(next, '');
  }
  const handleInputSearch = (event:any) =>{
    initData(1, event.target.value);
  }
  const handleCheckbox = (item:any, isCheck:any)=> {
    // console.log('checked', item?.pin, isCheck);
    const _ids = selectPins.filter(id => id != item.pin);
    if (isCheck) {
      _ids.push(item?.pin);
    }
    setSelectPins(_ids);
  }
  const handleCheckAllBox = (isCheck:any)=> {
    const allPins = accountList.map((u: any) => u.pin);

    setSelectPins(isCheck ? allPins : []);
  }

  const handleInputChange = (event: any) => {
    setNodeName(event.target.value);
    if (nodeName.length !== 0) {
      setErrorMessage(false);
    }
  };
  const handleInputBlur = (event: any) => {
    setNodeName(event.target.value);
    if (nodeName.length !== 0) {
      setErrorMessage(false);
    }
  };
  const handleSubmit = () => {
    // 如果不是审查人,
    if(accountType !== ACCOUNT_TYPE.examiner) {
      if(nodeName.length === 0){
        setErrorMessage(true);
        return;
      } else {
        node.nodeName = nodeName;
      }
      node.content = selectPins.join(',');
    }

    if(selectPins.length === 0 && accountType !== ACCOUNT_TYPE.examiner){
      message.warning(TLT(`请选择要添加的账号`));
      return;
    }

    if(accountType === ACCOUNT_TYPE.purchaser){
      message.success(`${TLT(`已添加`)}${selectPins.length}${TLT(`个采购员账号`)}`)
    } else if(accountType === ACCOUNT_TYPE.approver) {
      message.success(`${TLT(`已添加`)}${selectPins.length}${TLT(`个审批员账号`)}`)
    } else {
      message.success(`${TLT(`已添加`)}${selectPins.length}${TLT(`个审查员账号`)}`)
    }
    confirmCallBack && confirmCallBack(accountType,node,selectPins);
  }

  return (
    <div id="addAccountDrawer">
      <Drawer
        className={styles.addAccountModel}
        width={800}
        title={title}
        visible={open}
        destroyOnClose={true}
        footer={
          <div className={styles.addFooter}>
            <div className={styles.footerLeft}>
              {
                  accountList.length > 0 && (
                      <>
                        <div className={styles.leftCheck}>
                          <Checkbox checked={selectPins.length === accountList.length} onChange={(e: CheckboxChangeEvent)=>{
                            handleCheckAllBox(e.target.checked)
                          }}></Checkbox>
                          <span className={styles.allTitle}>{TLT(`全选`)}</span>
                        </div>
                        {
                            selectPins.length >0 &&(<span className={styles.leftTips}>{TLT(`已选`)}{selectPins.length}{TLT(`个账号`)}</span>)
                        }
                  </>)
              }
            </div>
            <Button style={{marginRight: '16px'}} 
              onClick={() => {
                cancelCallBack && cancelCallBack();
              }}>{TLT(`取消`)}</Button>
            <Button type='primary' onClick={handleSubmit}>{TLT(`提交`)}</Button>
          </div>
        }
        closeIcon={<div className={styles.iconClose}></div>}
        getContainer={() => document.getElementById('addAccountDrawer') as HTMLDivElement}
        onClose={() => {
          cancelCallBack && cancelCallBack();
        }}
      >
        <div className={styles.addContent}>
          {
            accountDrawerType === ACCOUNT_DRAWER_TYPE.addAccountNode && (
                <div className={styles.nodeName}>
                  <span className={styles.title}>{TLT(`审批节点名称:`)}</span>
                  <input placeholder={TLT(`请输入名称`)} maxLength={15} value={nodeName}
                         onChange={handleInputChange}
                         onBlur={handleInputBlur} className={`${errorMessage? styles.error: ''}`} />
                  <span className={styles.inputTips}>{nodeName?.length}/15</span>
                  { errorMessage && <span className={styles.errorTips}>{TLT(`请输入审批节点名称`)}</span>}
                </div>)
          }
          <input className={styles.searchAccount} placeholder={TLT(`请输入账号`)} onInput={handleInputSearch} ></input>
          {
            accountList.length > 0 ? (
                <>
                  <div className={styles.addHeader}>
                    <span className={styles.name}>{TLT(`账号名称`)}</span>
                    <span className={styles.email}>{TLT(`邮箱`)}</span>
                    <span className={styles.profession}>{TLT(`职位`)}</span>
                  </div>
                  <div className={styles.addMain}>
                    {accountList.map((item, index) => {
                      return (
                          <div className={styles.mainItem} key={index}>
                            <span className={styles.checkbox}>
                              <Checkbox
                                  checked={selectPins?.indexOf(item?.pin) > -1? true : false}
                                  onChange={(e: CheckboxChangeEvent)=>{
                                    handleCheckbox(item, e.target.checked)
                                  }}></Checkbox>
                            </span>
                            <span className={styles.name}>{item?.pin}</span>
                            <span className={styles.email}>{item?.bindingEmail}</span>
                            <span className={styles.profession}>{item?.position}</span>
                          </div>
                      );
                    })}
                  </div>
                  <div className={styles.pageMain}>
                    <span className={styles.pageTips}>{TLT(`共`)}{pageInfo?.totalItem}{TLT(`个账号`)}</span>
                    <i className={`${styles.pagePre} ${pageInfo?.index === 1 ? styles.disable : ''}`}
                       onClick={()=>{
                            if(pageInfo?.index !== 1) {
                              handleClickPagePre(pageInfo?.previousPage)
                            }
                    }}></i>
                    <span className={styles.pageIndex}><em>{pageInfo?.index}</em>/{pageInfo?.totalPage}</span>
                    <i className={`${styles.pageNext} ${pageInfo?.index === pageInfo?.totalPage ? styles.disable : ''}`}
                       onClick={()=>{
                         if(pageInfo?.index !== pageInfo?.totalPage){
                           handleClickPageNext(pageInfo?.nextPage)
                         }
                        }}></i>
                  </div>
                </>
            ) :(
                <div className={styles.empty}>
                  <div className={styles.content}>
                    <i className={styles.emptyIcon}></i>
                    <span className={styles.emptyTitle}>{TLT(`暂无数据`)}</span>
                  </div>
                </div>
               )
          }
        </div>
      </Drawer>
    </div>
  );
};

export default AddAccountDrawer;
