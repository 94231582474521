import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, message, Modal, Pagination, Row, Affix, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cookies from 'react-cookies';

import BreadCrumb from '@/components/BreadCrumb';
import UserCenterMenu from '@/components/UserCenterMenu';
import SimplePagination from '@/components/common/SimplePagination';

import Empty from '@/components/common/Empty';
import SearchForm, { UserType } from './components/SearchForm';
import OrderTitle from './components/OrderTitle';
import OrderItem from './components/OrderItem';
import Tab from './components/Tab';
import OrderDownloadModal from './components/SettingList';

import styles from './index.module.scss';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { find, flatten, pick } from 'lodash';
import { findPublucPromise, goTop, useNewPromise } from '@/util';
import PaginationJD from '@/components/PaginationJD';

import {
  ApproveModal,
  AuditStatus,
  auditStatus,
  confirm,
  handleConfirmOrder,
  OrderStatus,
  print,
  RejectModal,
  removeOrder,
  restore,
} from '@/components/Order';
import {
  exportAuditOrderRequest,
  // exportOrderRequest,
  getAuditOrderCount,
  // getAuditOrderList,
  getAuditOrderListConditions,
  getOrderCount,
  // getOrderList,
  getOrderListCancelTime,
  getOrderListReceiptTime,
  canApprovalOrder,
} from '@/api/orderList/api';

import { exportOrderRequest, getAuditOrderList, getOrderList } from '@/api/newApi/order';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CommonBasicHeader from '@/components/CommonBasicHeader';
import { useTranslation } from 'react-i18next';
import { formatApprovalList } from '@/util/formatServiceStuff';
import {dispatchAnnounceData} from "@/redux/action";

const ApprovalList: React.FC<{ isAudit?: boolean; isDelete?: boolean; isVsp?: boolean; isBatch?: boolean }> = ({
  isAudit = false, //订单审批
  isDelete = false, //订单回收站
  isVsp = false, //慧采列表
}) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const batchId = queryParams.get('batchId') || '';
  const statusParam = parseInt(queryParams.get('status') || '0');
  const urlStatus: OrderStatus = (OrderStatus as any)[Object.keys(OrderStatus)[statusParam]];

  const [status, setStatus] = useState<OrderStatus>(urlStatus);

  const auditType = queryParams.get('auditType') || '0';
  const [auditQueryType, setAuditQueryType] = useState<string>(auditType);
  const [searchParams, setSearchParams] = useState<any>({});
  const [paymentTypes, setPaymentTypes] = useState<Array<object>>([]);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(30);
  const [orders, setOrders] = useState<Array<object>>([]);
  const [total, setTotal] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [orderCount, setOrderCount] = useState<any>({});
  const [productLoading, setProductLoading] = useState(true)

  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(true);
  const [selectedOrderIds, setSelectedOrderIds] = useState<Array<number>>([]);
  const [selectedOrders, setSelectedOrders] = useState<Array<{ jdOrderId: string; processId: string; pin: string }>>(
    [],
  );
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState([]); //显示确定订单按钮的订单数组
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土
  const history = useHistory();
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });

  const userType: UserType = currentUser?.userType ?? UserType.unknonw;
  const userPin: string = currentUser.pin;

  const getData = () => {
    setProductLoading(true)

    const param = {
      page,
      pageSize,
      queryType: auditQueryType,
      ...searchParams,
    };
    // getFlow
    getAuditOrderList(param).then((res: any) => {
      const auditStatusMap: any = {
        0: AuditStatus.none,
        1: AuditStatus.auditing,
        2: AuditStatus.approved,
        3: AuditStatus.deny,
        4: AuditStatus.cancel,
      };
      const _orders = [];
      let data = res?.value?.orderList ?? {};
      if (auditQueryType === '0') {
        setOrderCount({ ...orderCount, waitingAudit: data?.total ?? 0 });
      }

      const markingList = res?.value?.marking;
      data = formatApprovalList(data);
      for (const order of data?.items ?? []) {
        if (order.parentJdOrderId) {
          let parent: any = find(_orders, ['parentJdOrderId', order.parentJdOrderId]);
          if (parent) {
            parent.childOrders.push(order);
          } else {
            parent = order;
            parent.childOrders = [order];
            _orders.push(parent);
          }
        } else {
          _orders.push(order);
        }
        order.auditStatus = auditStatusMap[order.approvalStatus.toString()] || AuditStatus.cancel;

        order.isService = order.hasServiceFee || false;
        if (order.snapshots && order.snapshots.length > 0) {
          let snapshot = find(order.snapshots, ['type', 101]);
          if (snapshot) {
            snapshot = JSON.parse(snapshot.snapshot);
            if (snapshot.extVXPService) {
              const extVXPService = JSON.parse(snapshot.extVXPService);

              const orderSkus = order.orderSkus || [];
              for (const sku of extVXPService?.fKUSnap ?? []) {
                const parentSku = find(orderSkus, ['linkSkuId', sku.skuId]);
                if (parentSku) {
                  parentSku.serviceSkus = sku.serviceInfos.map((s: any) => ({
                    name: s.serviceName,
                    salesPrice: s.skuPrice,
                    num: s.skuNum,
                    thirdSkuId: s.skuId,
                  }));
                }
              }
            }
          }
        }
        //判断订单商品是否有工业标品的标
        // order.orderSkus &&
        //   order.orderSkus.map((sku: any) => {
        //     sku.marking = markingList[order?.jdOrderId][sku?.skuId];
        //   });
      }
      setOrders(_orders);
      setTotal(data?.total ?? 0);
      setTotalPage(data?.pageTotal ?? 0);
    }).finally(() => {
      setProductLoading(false)
    });
  };

  const exportOrder = () => {
    const params = {
      taskType: auditQueryType,
    };
    exportOrderRequest(params).then(() => {
      message.success('稍后可在下载中心查看下载详情！');
    });
  };

  const clearSelect = () => {
    setSelectedOrderIds([]);
    setSelectAll(false);
    setIndeterminate(false);
  };

  useEffect(() => {
    if (auditQueryType === '1') {
      getAuditOrderList({
        queryType: '0',
      }).then((res: any) => {
        // alert(1)
        const data = res?.value?.orderList ?? {};
        setOrderCount({ ...orderCount, waitingAudit: data?.total ?? 0 });
      });
    }
  }, [userType]);

  useEffect(() => {
    // changeLogin();
    if (refresh) {
      clearSelect();
      getData();
      setRefresh(false);
    }
  }, [refresh]);

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setRefresh(true);
    }
  };
  const nextPage = () => {
    if (page < totalPage) {
      setPage(page + 1);
      setRefresh(true);
    }
  };
  const reset = () => {
    setPage(1);
    setRefresh(true);
  };

  const onCheckOrder = (e: CheckboxChangeEvent, order: any) => {
    const _ids = selectedOrderIds.filter(id => id != order.jdOrderId);
    if (e.target.checked) {
      _ids.push(order.jdOrderId);
    }
    setSelectedOrderIds(_ids);
    const allIds = flatten(
      orders.map((o: any) => (o.childOrders ? o.childOrders.map((x: any) => x.jdOrderId) : o.jdOrderId)),
    );
    setIndeterminate(!!_ids.length && _ids.length < allIds.length);
    setSelectAll(_ids.length === allIds.length);
  };

  const onRemoveOrder = (order: any) => {
    removeOrder(order.jdOrderId, () =>
      setTimeout(() => {
        reset();
      }, 1000),
    );
  };

  const auditBatchActions = () =>
    auditQueryType === '0' ? (
      <>
        <Button clstag="h|keycount|myorder|10" style={{ marginRight: '8px' }}>
          <span onClick={() => exportOrder()}>{TLT(`批量导出`)}</span>
          {/*{currentUser?.orderExportWhite && (*/}
          {/*  // <>*/}
          {/*  //   <OrderDownloadModal*/}
          {/*  //     closeSetting={() => setShowOrderDownloadSetting(false)}*/}
          {/*  //     showSetting={showOrderDownloadSetting}*/}
          {/*  //   ></OrderDownloadModal>*/}
          {/*  // </>*/}
          {/*)}*/}
        </Button>
        <Button
          clstag="h|keycount|myorder|11"
          style={{ marginRight: '8px' }}
          onClick={() => {
            log('jdisp', 'ApprovalList_batchprint');
            if (selectedOrderIds.length >= 2 && selectedOrderIds.length <= 50) {
              Modal.confirm({
                title: TLT(`打印订单`),
                content: `${TLT(`您已选择`)}${selectedOrderIds.length}${TLT(`条订单，确定要合并打印吗？`)}`,
                okText: TLT(`合并打印`),
                cancelText: TLT(`单独打印`),
                closable: false,
                onOk: () => {
                  print(selectedOrderIds.join(','), '2', t);
                },
                onCancel: () => {
                  print(selectedOrderIds.join(','), '1', t);
                },
                // onCancel: e => {
                //   if (!e.toString().indexOf('close')) {
                //     print(selectedOrderIds.join(','), '1', t);
                //     e();
                //   }
                // },
              });
            } else if (selectedOrderIds.length > 50) {
              Modal.info({
                title: TLT(`已超出打印订单上限`),
                content: TLT(`勾选订单已超出50条，请减少勾选订单数量再打印。`),
              });
            } else {
              print(selectedOrderIds.join(','), '1', t);
            }
          }}
        >
          {TLT(`批量打印`)}
        </Button>
        <Button
          clstag="h|keycount|h|keycount|ordercenter|1"
          style={{ marginRight: '8px' }}
          disabled={selectedOrderIds.length === 0}
          onClick={() => {
            log('jdisp', 'ApprovalList_batchapprove');
            setSelectedOrders(
              orders
                .filter((o: any) => selectedOrderIds.includes(o.jdOrderId))
                .map((o: any) => pick(o, ['jdOrderId', 'processId', 'pin'])),
            );
            setApproveModalVisible(true);
            // const validateCount = orders.filter(
            //   (o: any) => selectedOrderIds.includes(o.jdOrderId) && (o?.operateMap?.showPassBtn ?? false),
            // ).length;
            // Modal.confirm({
            //   title: TLT(`批量通过`),
            //   content: (
            //     <span>
            //       {TLT(`已选`)}{' '}
            //       <span className={[styles.blue, styles.bold].join(' ')}>{selectedOrderIds.length}</span>{' '}
            //       {TLT(`条订单，其中`)}{' '}
            //       <span className={[styles.blue, styles.bold].join(' ')}>{validateCount}</span>{' '}
            //       {TLT(`条支持审批通过`)}
            //     </span>
            //   ),
            //   icon: <ExclamationCircleOutlined style={{ color: '#0d47a0' }} />,
            //   okText: TLT(`取消`),
            //   cancelText: TLT(`确认`),
            //   onCancel: () => {
            //     if (validateCount > 0) {
            //       setSelectedOrders(
            //         orders
            //           .filter((o: any) => selectedOrderIds.includes(o.jdOrderId))
            //           .map((o: any) => pick(o, ['jdOrderId', 'processId', 'pin'])),
            //       );
            //       setApproveModalVisible(true);
            //     }
            //   },
            // });
          }}
        >
          {TLT(`批量通过`)}
        </Button>
        <Button
          clstag="h|keycount|ordercenter|2"
          style={{ marginRight: '8px' }}
          disabled={selectedOrderIds.length === 0}
          onClick={() => {
            log('jdisp', 'ApprovalList_batchreject');
            setSelectedOrders(
              orders
                .filter((o: any) => selectedOrderIds.includes(o.jdOrderId))
                .map((o: any) => pick(o, ['jdOrderId', 'processId', 'pin'])),
            );
            setRejectModalVisible(true);
            // const validateCount = orders.filter(
            //   (o: any) => selectedOrderIds.includes(o.jdOrderId) && (o?.operateMap?.showRejectBtn ?? false),
            // ).length;
            // Modal.confirm({
            //   title: TLT(`批量驳回`),
            //   content: (
            //     <span>
            //       {TLT(`已选`)}{' '}
            //       <span className={[styles.blue, styles.bold].join(' ')}>{selectedOrderIds.length}</span>{' '}
            //       {TLT(`条订单，其中`)}{' '}
            //       <span className={[styles.blue, styles.bold].join(' ')}>{validateCount}</span>{' '}
            //       {TLT(`条支持审批驳回`)}
            //     </span>
            //   ),
            //   icon: <ExclamationCircleOutlined style={{ color: '#0d47a0' }} />,
            //   okText: TLT(`取消`),
            //   cancelText: TLT(`确认`),
            //   onCancel: () => {
            //     if (validateCount > 0) {
            //       setSelectedOrders(
            //         orders
            //           .filter((o: any) => selectedOrderIds.includes(o.jdOrderId))
            //           .map((o: any) => pick(o, ['jdOrderId', 'processId', 'pin'])),
            //       );
            //       setRejectModalVisible(true);
            //     }
            //   },
            // });
          }}
        >
          {TLT(`批量驳回`)}
        </Button>
      </>
    ) : (
      <>
        <Button
          clstag="h|keycount|myorder|11"
          style={{ marginRight: '8px' }}
          onClick={() => {
            log('jdisp', 'ApprovalList_batchprint');
            if (selectedOrderIds.length >= 2 && selectedOrderIds.length <= 50) {
              Modal.confirm({
                title: TLT(`打印订单`),
                content: `${TLT(`您已选择`)}${selectedOrderIds.length}${TLT(`条订单，确定要合并打印吗？`)}`,
                okText: TLT(`合并打印`),
                cancelText: TLT(`单独打印`),
                closable: true,
                onOk: () => {
                  print(selectedOrderIds.join(','), '2', t);
                },
                onCancel: e => {
                  if (!e.toString().indexOf('close')) {
                    print(selectedOrderIds.join(','), '1', t);
                    e();
                  }
                },
              });
            } else if (selectedOrderIds.length > 50) {
              Modal.info({
                title: TLT(`已超出打印订单上限`),
                content: TLT(`勾选订单已超出50条，请减少勾选订单数量再打印。`),
              });
            } else {
              print(selectedOrderIds.join(','), '1', t);
            }
          }}
        >
          {TLT(`批量打印`)}
        </Button>
        <Button clstag="h|keycount|myorder|10" style={{ marginRight: '8px' }} disabled={orders.length === 0}>
          <span
            onClick={() => {
              exportOrder();
              log('jdisp', 'ApprovalList_Export');
            }}
          >
            {TLT(`批量导出`)}
          </span>
        </Button>
      </>
    );
  const batchActions = () => {
    const selectOrderCount = selectedOrderIds.length;
    let totalMoney = 0;
    try {
      orders.forEach((v1: any) => {
        if (v1?.childOrders?.length > 0) {
          v1?.childOrders?.forEach((item: any, index: any) => {
            if (selectedOrderIds.indexOf(item.jdOrderId) > -1) {
              totalMoney += (item?.orderNeedMoney ? item?.orderNeedMoney : item?.needPayMoney) * 100;
            }
          });
        } else {
          if (selectedOrderIds.indexOf(v1.jdOrderId) > -1) {
            totalMoney += (v1?.orderNeedMoney ? v1?.orderNeedMoney : v1?.needPayMoney) * 100;
          }
        }
      });
    } catch (e) {
      console.log(e);
    }

    return (
      <Row className={styles.batchActions} style={{ alignItems: 'center' }}>
        <Col span="18">
          {
            <Checkbox
              className={styles.checkAll}
              clstag={isAudit ? 'h|keycount|ordercenter|5' : 'h|keycount|myorder|14'}
              style={{ color: '#333' }}
              indeterminate={indeterminate}
              checked={selectAll}
              onChange={e => {
                const allIds = flatten(
                  orders.map((o: any) => (o.childOrders ? o.childOrders.map((x: any) => x.jdOrderId) : o.jdOrderId)),
                );
                setSelectedOrderIds(e.target.checked ? allIds : []);
                setIndeterminate(false);
                setSelectAll(e.target.checked);
              }}
            >
              {TLT(`全选`)}
            </Checkbox>
          }

          {selectOrderCount > 0 && (
            <>
              <span className={styles.selectedTitle}>
                {TLT(`当前已选`)}{' '}
                <span className={[styles.blue, styles.bold].join(' ')}>{selectOrderCount}</span>{' '}
                {TLT(`订单`)}
              </span>
              {/*{totalMoney && (*/}
              {/*  <span>*/}
              {/*    总金额 <span className={[styles.blue, styles.bold].join(' ')}>{totalMoney / 100}</span> 元*/}
              {/*  </span>*/}
              {/*)}*/}
            </>
          )}
          {auditBatchActions()}
        </Col>
        <Col span="6">
          <SimplePagination page={page} total={total} totalPage={totalPage} onPrev={prevPage} onNext={nextPage} />
        </Col>
      </Row>
    );
  };
  const orderListTitle = (
    <Row className={styles.header}>
      <Col className={styles.title1} style={{ paddingLeft: '20px' }}></Col>
      <Col className={styles.title2} style={{ display: 'flex', alignItems: 'center', paddingLeft: '24px' }}>
        {TLT(`商品信息`)}
      </Col>
      <Col className={[styles.title3, styles.center].join(' ')}> {TLT(`价格/数量`)}</Col>
      {isLocalPro && <Col className={[styles.title7, styles.center].join(' ')}>{TLT(`是否报关`)}</Col>}
      <Col className={[styles.title4, styles.center].join(' ')}> {TLT(`收货人`)}</Col>
      <Col className={[styles.title5, styles.center].join(' ')}> {TLT(`总价`)}</Col>
      <Col className={[styles.title6, styles.center].join(' ')}> {TLT(`订单状态`)}</Col>
      <Col className={[styles.title7, styles.center].join(' ')}> {TLT(`操作`)}</Col>
    </Row>
  );

  const orderConfirm = (orderId: any) => {
    handleConfirmOrder(orderId, () => {
      reset();
    });
  };
  const orderList =
    orders.length > 0 ? (
      orders.map((o: any, index: number) => (
        <div
          key={index + o.jdOrderId}
          className={[styles.borderLeft, styles.borderBottom, styles.borderRight].join(' ')}
          style={{ marginBottom: '20px' }}
        >
          <OrderTitle
            order={o}
            isAudit={isAudit}
            isDelete={isDelete}
            isVsp={isVsp}
            auditQueryType={auditQueryType}
            checked={selectedOrderIds.includes(o.jdOrderId)}
            onCheckOrder={onCheckOrder}
            onRemoveOrder={() => onRemoveOrder(o)}
          />
          <OrderItem
            order={o}
            isAudit={isAudit}
            isDelete={isDelete}
            isVsp={isVsp}
            auditQueryType={auditQueryType}
            onCancel={() => {
              if (status === OrderStatus.all) {
                setRefresh(true);
              } else {
                reset();
              }
            }}
            onApprove={() => reset()}
            onReject={() => reset()}
            onRestore={() => {
              message.success('所选订单已还原，可前往订单中心进行查看！');
              setTimeout(() => {
                reset();
              }, 1000);
            }}
            isConfirm={confirmOrder.indexOf(o.jdOrderId) > -1 ? true : false}
            orderConfirm={() => {
              orderConfirm(o.jdOrderId);
            }}
          />
        </div>
      ))
    ) : (
      <Empty type="approvalList" />
    );
  const approveModal = (
    <ApproveModal
      orders={selectedOrders}
      visible={approveModalVisible}
      onCancel={() => setApproveModalVisible(false)}
      onOK={() => {
        setApproveModalVisible(false);
        reset();
      }}
    />
  );

  const rejectModal = (
    <RejectModal
      orders={selectedOrders}
      visible={rejectModalVisible}
      onCancel={() => setRejectModalVisible(false)}
      onOK={() => {
        setRejectModalVisible(false);
        reset();
      }}
    />
  );
  return (
    <div className={styles.orderlist}>
    <div className={'ispContainer'}>
      {/* <div className={styles.orderlist_menu}> */}
      <Affix offsetTop={0}>
        <UserCenterMenu />
      </Affix>
      {/* </div> */}
      <div className={styles.orderlist_content}>
        {/* <BreadCrumb data={crumbData} /> */}
        <CommonBasicHeader title={TLT(`订单审批`)} />
        <div className={styles.content}>
          <Card style={{ marginBottom: '10px' }}>
            <Tab
              isAudit={isAudit}
              isDelete={isDelete}
              status={status}
              auditQueryType={auditQueryType}
              showCompleteAudit={true}
              orderCount={orderCount}
              onAuditTabChange={activeKey => {
                history.replace(`/approvalList?auditType=${activeKey}`);
                setAuditQueryType(activeKey);
                delete searchParams.approvalStatus;
                reset();
              }}
            />
            <SearchForm
              isAudit={isAudit}
              isDelete={isDelete}
              paymentTypes={paymentTypes}
              auditQueryType={auditQueryType}
              onReset={() => {
                setSearchParams({});
                reset();
              }}
              onSearch={(params: any) => {
                setSearchParams(params);
                reset();
              }}
            />
          </Card>
          <Spin spinning={productLoading}>
            <Card className={styles.orderList} style={{ padding: '15px 20px' }}>
              {batchActions()}
              {orderListTitle}
              {orderList}
              <div style={{ textAlign: 'right' }}>
                <PaginationJD
                    hideOnSinglePage
                    current={page}
                    total={total}
                    pageSize={pageSize}
                    showSizeChanger={false}
                    onChange={(_page: any) => {
                      setPage(_page);
                      setRefresh(true);
                      goTop(false);
                    }}
                />
                {/*<Pagination*/}
                {/*  showSizeChanger*/}
                {/*  hideOnSinglePage*/}
                {/*  showQuickJumper*/}
                {/*  current={page}*/}
                {/*  pageSize={pageSize}*/}
                {/*  total={total}*/}
                {/*  pageSizeOptions={['30', '50', '100']}*/}
                {/*  showTotal={total => `共${total}条`}*/}
                {/*  onChange={_page => {*/}
                {/*    setPage(_page);*/}
                {/*    setRefresh(true);*/}
                {/*    goTop(false);*/}
                {/*  }}*/}
                {/*  onShowSizeChange={(current, size) => {*/}
                {/*    setPageSize(size);*/}
                {/*    reset();*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </Card>
          </Spin>
          {approveModal}
          {rejectModal}
        </div>
      </div>
    </div>
    </div>
  );
};
export default ApprovalList;
