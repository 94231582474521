import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

interface IAppProps {
  orderDetails: any;
}
const Information: React.FC<IAppProps> = (props: any) => {
  const { t } = useTranslation();
  const { orderDetails } = props;
  const orderRes = orderDetails?.orderRes;
  //收货人信息
  const deliveryCountry = orderRes?.deliveryCountry ?? '';
  const address = orderRes?.deliveryAddress ?? '';
  const _address = deliveryCountry + ' ' + address;

  return (
    <div className={styles.logisticsInformation}>
      <div className={styles.comDetail}>
        <div className={styles.box}>
          <span className={styles.title}>{TLT(`收货人信息`)}</span>
          <p className={styles.row}>
            <span className={styles.left}>{TLT(`收货人`)}</span>
            <span className={styles.right} title={orderRes?.consignee ?? ''}>
              {orderRes?.consignee ?? ''}
            </span>
          </p>
          <p className={styles.row}>
            <span className={styles.left}>{TLT(`联系电话`)}</span>
            <span className={styles.right}>{orderRes?.deliveryPhone ?? ''}</span>
          </p>
          <p className={styles.row}>
            <span className={styles.left}>{TLT(`收货地址`)}</span>
            <span className={styles.right} title={_address}>
              {_address}
            </span>
          </p>
        </div>
        {orderRes?.remark && <div className={styles.b}></div>}
        {orderRes?.remark && (
          <div className={`${styles.box} ${styles.rightBox}`}>
            <span className={styles.title}>{TLT(`订单备注`)}</span>
            <p className={styles.text}>{orderRes?.remark ?? ''}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default Information;
