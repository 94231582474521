import React from 'react';
import { Input } from 'antd';
import styles from './index.module.scss';
import { useSetRecoilState } from '../../hooks';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default () => {
  const setOrderRemarks = useSetRecoilState('orderRemarks');
  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOrderRemarks(e.target.value);
  };

  return (
    <div className={styles.main}>
      <div className={styles.name}>{TLT(`备注`)}</div>
      <TextArea
        showCount
        maxLength={200}
        style={{ width: '100%', height: '100%', resize: 'none' }}
        onChange={onChange}
        placeholder={TLT(`请填写备注`)}
      />
    </div>
  );
};
