/*
 * @Author: weiyanna
 * @Date: 2021-12-07 16:33:36
 * @LastEditTime: 2024-10-24 22:28:49
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/components/Address/List/Item/index.tsx
 */
import React from 'react';
import { Button, Row, Space, Col, Checkbox, Tooltip, Image } from 'antd';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { orderTime } from '@/components/Order';
// import AddressVisibilityModal from '../AddressVisibilityModal';
export interface IAppProps {
  mkuList: any;
  orderDetails:any;
}
const AddressItem: React.FC<IAppProps> = (props: any) => {
  const {mkuList, orderDetails} = props;
  return (
    <>
        <Row
            style={{
            width: '100%',
            padding: '10px 16px',
            background: 'rgba(0,3,20,0.03)',
            border: '1px solid rgba(225,225,225,1)',
            }}
            align="middle"
            gutter={[20, 0]}
        >
            {/* 名称 */}
            <Col span={8} style={{ fontSize: '12px', color: 'rgba(0,3,20,0.45)', paddingLeft: '34px' }}>
            {TLT(`商品`)}
            </Col>
            {/* 电话 */}
            <Col span={3} style={{ fontSize: '12px', color: 'rgba(0,3,20,0.45)' }}>
            {TLT(`价格`)}
            </Col>
            {/* 地区 */}
            {/* <Col span={8} style={{ fontSize: '12px', color: 'rgba(0,3,20,0.45)' }}>
            {TLT(`地址`)}
            </Col> */}
            {/* 地址 */}
            <Col span={3} style={{ fontSize: '12px', color: 'rgba(0,3,20,0.45)' }}>
            {TLT(`数量`)}
            </Col>
            {/* 操作 */}
            <Col span={4} style={{ fontSize: '12px', color: 'rgba(0,3,20,0.45)' }}>
            {TLT(`小计`)}
            </Col>
            <Col span={6} style={{ fontSize: '12px', color: 'rgba(0,3,20,0.45)' }}>
            {TLT(`下单时间`)}
            </Col>
        </Row>

        {
        mkuList.map((order, idx)=> <Row
          key={idx}
          className={styles.item}
          align="middle"
          gutter={[20, 0]}
      >
          {/* 商品 */}
          <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
            <Row gutter={24} style={{
              width:'100%'
            }}>
              <Col span={8}>
                <Image src={order?.skuImg} width={80} height={80}></Image>
              </Col>
              <Col span={16}>
                <div className={styles.mallinfo}>{order?.skuName}</div>
                <div className={styles.mallinfo}><span>{TLT(`商品编号`)}:</span>{order?.sku}</div>
              </Col>
            </Row>
          </Col>
          {/* 价格 */}
          <Col span={3}>
            <span>{order?.skuPrice}</span>
          </Col>
          {/* 数量 */}
          <Col span={3}>
            <span>{order?.skuNum}</span>
          </Col>
          {/* 小计 */}
          <Col span={4}>
            <span>{order?.totalPrice}</span>
          </Col>
          {/* 下单时间 */}
          <Col span={6}>
            <span>{moment(orderTime(orderDetails)).format('YYYY-MM-DD HH:mm:ss')}</span>
          </Col>
      </Row>
    )}
    </>
  );
};

export default AddressItem;
