/*
 * @Author: weiyanna
 * @Date: 2021-09-29 15:48:01
 * @LastEditTime: 2024-02-28 18:52:01
 * @LastEditTime: 2021-11-17 13:59:50
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: In User Settings Edit
 * @FilePath: /isp_International/src/components/NewSearch/logo/index.tsx
 */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import getImg from '@/util/getImg';
import { isIE } from '@/util/index';
import WorkBench from '@/router/WorkBench';
import styled from './index.module.scss';
import {getLogo} from "@api/newApi/header";
import { useTranslation } from 'react-i18next';
import { getJdiOSFile } from '@/util/index'

export interface IAppProps {
  clientOpenWindow: boolean;
  isScene?: any;
  src?: string;
}

const Logo: React.FC<IAppProps> = (props: any) => {
  const { pathname } = window.location;
  const userData: any = useSelector(state => {
    return state?.User?.data[0];
  });
  const { clientOpenWindow, isScene, src } = props;
  const [imgSrc,setImgSrc] = useState(src)
  const [isShowLogo, setIsShowLogo] = useState(true);
  const [title, setTitle] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const index = WorkBench.findIndex((item: any) => {
      return item.path === pathname;
    });
    index > -1 ? setIsShowLogo(false) : setIsShowLogo(true);
    const title = document.title.split('-') ? document.title.split('-')[1] : '';
    setTitle(title);
  }, [pathname, userData]);
  // useEffect(async ()=>{
  //   const res:any = await getLogo()
  //   console.log(res,'logo')
  //   if(res.value){
  //     setImgSrc(res.value)
  //   }
  // },[])

  // -- multiple langs TODO --
  useEffect(() => {
    if (i18n.language === 'zh') {
      setImgSrc(getJdiOSFile('d1/logo_zhongwen.png'))
    } else if(i18n.language === 'vi') {
      setImgSrc(getJdiOSFile('d1/logo_yueyu2.png'))
    }else{
      setImgSrc(getJdiOSFile('d1/logo_yingyu.png'))
    }
    return () => {
      return;
    };
  }, [i18n.language]);
  return (
    <div className={`${styled.conent}`}>
      <LazyLoad height={64} style={{ display: 'inline-block' }}>
        <img
          // onClick={() => {
          //   if (isIE() && clientOpenWindow) {
          //     window.location.href = '/';
          //   } else {
          //     window._openPage('/', '_self');
          //   }
          // }}
          className={`${styled.logo}`}
          alt="京东工采logo"
          src={imgSrc||'//img12.360buyimg.com/imagetools/jfs/t1/90365/35/27791/10813/6384ca60Efdc01864/63873e50cd54f16d.png'}
        />
      </LazyLoad>
    </div>
  );
};
export default Logo;
