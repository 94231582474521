import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

function TextareaWithLineNumbers({ setAiNumber }, ref) {
  const [text, setText] = useState('');
  const lineNumberRef = useRef(null);
  const textAreaRef = useRef(null);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleScroll = () => {
    if (lineNumberRef.current && textAreaRef.current) {
      lineNumberRef.current.resizableTextArea.textArea.scrollTop = textAreaRef.current.resizableTextArea.textArea.scrollTop;
    }
  };

  const getLineNumbers = () => {
    const lines = text ? text.split('\n').length : 0;
    const result = Array.from({ length: lines }, (_, i) => i + 1);
    setAiNumber(lines && result[result.length - 1]);
    return result.join('\n');
  };

  const getNumber = () => {
    return text.split('\n').length;
  };

  useImperativeHandle(ref, () => ({
    getText: () => text,
    getNumber,
    clearText: () => {
      setText('');
    }
  }));

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'flex-start', border: '1px solid #d9d9d9' }}>
      <TextArea
        readOnly
        value={getLineNumbers()}
        ref={lineNumberRef}
        style={{
          width: '30px',
          height: '100%',
          resize: 'none',
          border: 'none',
          backgroundColor: '#f0f0f0',
          color: 'rgb(146, 146, 146)',
          textAlign: 'center',
          overflow: 'hidden',
          boxShadow: 'none',
          fontSize: 14,
          padding: '6px 0'
        }}
      />
      <TextArea
        value={text}
        onChange={handleChange}
        onScroll={handleScroll}
        ref={textAreaRef}
        style={{
          flex: 1,
          height: '100%',
          resize: 'none',
          border: 'none',
          paddingLeft: '5px',
          boxShadow: 'none',
          fontSize: 14,
        }}
      />
    </div>
  );
}

export default forwardRef(TextareaWithLineNumbers);
