import http from '../axios';

/**
 * 待审批列表数据查询
 * @param params
 */
export function uploadFile(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/public/upload', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}


/**
 * 申请售后
 * @param params
 */
export function afterSaleSubmit(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/afterSales/submit', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
  }


/**
 * 获取售后详情
 * @param params
 */
export function getAfterSaleDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/afterSales/detail', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}



  
