import React, { useEffect, useState } from 'react';
import { Button, message, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { orderPrint, updateOrderStatus } from '@api/newApi/orderdetail';
import { ApproveModal, RejectModal, confirm, handleConfirmOrder } from '@/components/Order';
import styles from './../index.module.scss';
import { pick } from 'lodash';
import { GetRequest } from '@/util';
import { getOrderSettingMap, orderPrintApi } from '@/api/newApi/order';

interface IAppProps {
  orderDetails: any;
  changeProgressType?: () => void;
  changeOrderDetail?: () => void;
}
const TabInfo: React.FC<IAppProps> = (props: any) => {
  const { t, i18n } = useTranslation();
  const { orderDetails, changeProgressType, changeOrderDetail } = props;
  const orderRes = orderDetails?.orderRes;
  const approvalVo = orderDetails?.node;
  const [disableType, setDisableType] = useState<string>('');
  const [type, setType] = useState<string>('order');
  const [tabItems, setTabItems] = useState<Array<any>>([]);
  const [orderInfo, setOrderInfo] = useState<object>({ text: '', color: 'black' });
  const [approvalInfo, setApprovalInfo] = useState<object>({ text: '', color: 'black' });
  const [isShowOrderPin, setIsShowOrderPin] = useState<boolean>(false);
  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [orderConfigList, setOrderConfigList] = useState({key:''}); //初始化导出配置
  const [selectedOrders, setSelectedOrders] = useState<Array<{ jdOrderId: string; processId: string; pin: string }>>(
    [],
  );
  const orderStatus = orderRes?.orderStatus;
  const userType: any = useSelector((state: any) => {
    return state.User?.data[0] ? state.User?.data[0]?.userType : '';
  });
  // 订单状态 (0:已提交、1:待运营确认、2：待客户确认，3:审核中，4:已发货、5:已完成、99:已取消)
  // 审批状态（-1:提交申请、0：未发起、1：审批中、2：通过、3：否决 4、中断）
  const store: any = useSelector(state => {
    return state;
  });
  const c_pin = store?.User?.data[0]?.pin ?? '';
  const orderPin = orderRes?.pin;
  const params: any = GetRequest();
  const currentApprovalVo = approvalVo?.filter((item: any) => {
    if (item?.currentApprovePin == c_pin) {
      return item;
    }
  });
  const orderConfirm = (orderId: any) => {
    handleConfirmOrder(orderId, t, () => {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    });
  };

  useEffect(()=>{
    getSetMap();
  }, [])

  
  useEffect(() => {
    switch (orderStatus) {
      case 0:
        setOrderInfo({
          text: TLT(`已提交`),
          color: 'black',
          isShowCancel: true,
        });
        break;
      case 1:
        setOrderInfo({
          text: TLT(`京东确认中`),
          color: 'black',
          isShowCancel: orderPin == c_pin ? true : false,
        });
        break;
      case 2:
        setOrderInfo({
          text: TLT(`待确认`),
          color: 'orange',
          isShowCancel: orderPin == c_pin ? true : false,
          isShowConfirmOrder: orderPin == c_pin ? true : false,
        });
        break;
      case 3:
        setOrderInfo({
          text: TLT(`审批中`),
          color: 'orange',
          isShowCancel: false,
        });
        break;
      case 4:
        setOrderInfo({
          text: TLT(`下单成功`),
          color: 'orange',
          isShowCancel: false,
        });
        break;
      case 5:
        setOrderInfo({
          text: TLT(`已完成`),
          color: 'cyanBlue',
          isShowCancel: false,
        });
        break;
      case 80:
        setOrderInfo({
          text: TLT(`已发货`),
          color: 'cyanBlue',
          isShowCancel: false,
          isShowConfirmReceipt: orderDetails?.orderRes.deliveryStatus !== 1,
        });
        break;
      case 90:
          setOrderInfo({
            text: TLT(`已收货`),
            color: 'cyanBlue',
            isShowCancel: false,
            isShowConfirmReceipt: orderDetails?.orderRes.deliveryStatus !== 1,
          });
      break;
      case 99:
        setOrderInfo({
          text: TLT(`已取消`),
          color: 'gray',
          isShowCancel: false,
        });
        break;
      case 95:
        setOrderInfo({
          text: TLT(`已确认收货`),
          color: 'cyanBlue',
          isShowCancel: false,
        });
        break;
    }
    // if (currentApprovalVo) {
      switch (orderDetails?.approvalStatus) {
        case 1:
          setApprovalInfo({
            text: TLT(`审批中`),
            color: 'orange',
            isShowCancel: true,
          });
          break;
        case 2:
          setApprovalInfo({
            text: TLT(`审批完成`),
            color: 'cyanBlue',
            isShowCancel: true,
          });
          break;
        case 3:
          setApprovalInfo({
            text: TLT(`审批驳回`),
            color: 'claret',
            isShowCancel: true,
          });
          break;
        case 4:
          setApprovalInfo({
            text: TLT(`中断`),
            color: 'claret',
            isShowCancel: true,
          });
          break;
          default:
          setApprovalInfo({
            text: TLT(`审批中`),
            color: 'orange',
            isShowCancel: true,
          });
      }
    // } else {
    //   setApprovalInfo({
    //     text: i18n.language == 'zh' ? '审批中' : 'Pending Approval',
    //     color: 'orange',
    //     isShowCancel: true,
    //   });
    // }
    if (orderPin != c_pin) {
      setIsShowOrderPin(true);
    }
    const approvalStatusText = TLT(`审批状态`);
    const orderStatusText = TLT(`订单状态`);
  
    if (approvalVo) {
      const userType = store?.User?.data[0]?.userType ?? '';
      // 审批员
      if (userType === 6) {
        setTabItems([
          {
            label: approvalStatusText,
            key: '1',
          },
          {
            label: orderStatusText,
            key: '2',
          },
        ]);
        setType('approval');
        changeProgressType('approval');
      } else {
        console.log('orderStatusText', orderStatusText)
        setTabItems([
          {
            label: orderStatusText,
            key: '1',
          },
          {
            label: approvalStatusText,
            key: '2',
          },
        ]);
        setType('order');
      }
    } else {
      setTabItems([
        {
          label: orderStatusText,
          key: '1',
        },
      ]);
      setType('order');
    }
  }, [c_pin, orderPin, i18n.language]);
  const onChange = (key: string) => {
    const userType = store?.User?.data[0]?.userType ?? '';
    // 审批员
    if (userType === 6) {
      if (key === '1') {
        setType('approval');
        changeProgressType('approval');
      } else {
        setType('order');
        changeProgressType('order');
      }
    } else {
      if (key === '1') {
        setType('order');
        changeProgressType('order');
      } else {
        setType('approval');
        changeProgressType('approval');
      }
    }
  };

   //excel导出设置获取
   const getSetMap = async () => {
    try {
      const res:any = await getOrderSettingMap();

      if (res?.success) {
        const value = res?.value?.result;
        setOrderConfigList(value)
      } else {
        message.error('获取配置失败')
      }
    } catch (e) {
      console.log(e)
    }
  }

  /*
    采购员进来
        审批进行  显示进行中

        审批完成  显示通过 、驳回

    审批员进来
        如果订单没有到达该审批员的审批节点，不显示按钮，仅显示状态
        如果订单到达该审批员的审批节点，显示通过、驳回两个按钮，显示状态

        如果订单过了该审批员，但是没有结束，显示"Your approval task has been completed"

        审批结束 分为 通过 、驳回

        问题1：订单状态已取消 且到达到达该审批员的审批节点
   */
  const onHandlePrint = (orderId: string) => {
    const paramer = orderConfigList?.length && orderConfigList.map((ele)=>({
      orderInfo:ele.orderInfo,
      orderInfoName:ele.orderInfoName,
      orderInfoKey:ele.orderInfoKey
    }));
    orderPrintApi({
      orderId:[orderId],
      orderExportConfig:paramer
    }).then((res) => {
      if(res.success && res.value && res.value.fileUrl){
        location.href = res?.value?.fileUrl;
      }
    });
  };
  /**
   * 修改订单状态
   * @param orderId
   * @param status
   */
  const onHandleChangeOrderStatus = (orderNo: string, status: string, type?: string) => {
    updateOrderStatus({
      orderNo: orderNo,
      orderStatus: status,
    }).then(res => {
      if (res.success) {
        message.success(TLT(`订单取消成功`));

        setTimeout(() => {
          changeOrderDetail();
        }, 1000);
        setDisableType(type || '');
      } else {
        message.success(res.msg);
      }
    });
  };
  const approveModal = (
    <ApproveModal
      orders={selectedOrders}
      visible={approveModalVisible}
      onCancel={() => setApproveModalVisible(false)}
      onOK={() => {
        setApproveModalVisible(false);
        // onApprove();
      }}
    />
  );

  const rejectModal = (
    <RejectModal
      orders={selectedOrders}
      visible={rejectModalVisible}
      onCancel={() => setRejectModalVisible(false)}
      onOK={() => {
        setRejectModalVisible(false);
        // onReject();
      }}
    />
  );
  return (
    <div className={styles.tabInfo}>
      <div className={styles.top}>
        <div className={styles.topContent}>
          <Tabs defaultActiveKey="1" onChange={onChange} items={tabItems}></Tabs>
        </div>
      </div>

      {type === 'order' ? (
        <div className={styles.bottom}>
          <div className={`${styles.progressText} ${styles[orderInfo.color]}`}>{orderInfo.text}</div>
          <div className={styles.progressBtn}>
            {/*订单状态 在 To be confirmed 状态下 Confirm order*/}
            {orderInfo?.isShowConfirmOrder && userType === 6 && (
              <Button
                type="primary"
                ghost
                disabled={disableType == 'confirmOrder'}
                style={{ minWidth: '74px' }}
                onClick={() => {
                  orderConfirm(orderRes?.orderNo);
                }}
              >
                {TLT(`确认订单`)}
              </Button>
            )}

            {/*订单状态 在 Shipping in progress 状态下 Confirm receipt*/}
            {orderInfo?.isShowConfirmReceipt && (
              <Button
                type="primary"
                ghost
                disabled={disableType == 'confirmReceipt'}
                style={{ minWidth: '74px' }}
                onClick={() => {
                  confirm(
                    orderRes?.orderNo,
                    () => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 300);
                      // window.location.reload();
                    },
                    t,
                  );
                  // onHandleChangeOrderStatus(orderRes?.orderNo, '5', 'confirmReceipt');
                }}
              >
                {TLT(`确认完成`)}
              </Button>
            )}
          </div>
          <div className={styles.operateBtn}>
            <div
              className={styles.print}
              onClick={() => {
                onHandlePrint(orderRes?.orderNo);
                log('jdisp', 'OrderDetail_Print');
              }}
            >
              {TLT(`订单打印`)}
            </div>
            {orderInfo.isShowCancel && (
              <div
                className={styles.cancel}
                onClick={() => {
                  onHandleChangeOrderStatus(orderRes?.orderNo, '99');
                  log('jdisp', 'OrderDetail_Cancel');
                }}
              >
                {TLT(`取消`)}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.bottom}>
          {isShowOrderPin && (
            <div className={`${styles.normalText} ${styles.mrb16}`}>
              {TLT(`采购人:`)}
              {orderRes?.pin}
            </div>
          )}

          <div className={`${styles.progressText} ${styles[approvalInfo.color]}`}>{approvalInfo.text}</div>
          {((approvalInfo?.text == '审批中' || approvalInfo?.text == 'Pending Approval'))&&(userType===6)&&(
            <div className={styles.progressBtn}>
              {/*审批状态 在 Pending approval 状态下 Approval*/}
              <Button
                type="primary"
                ghost
                style={{ width: '88px' }}
                onClick={() => {
                  setSelectedOrders([{ jdOrderId: params?.id, processId: params?.processId, pin: params?.orderPin }]);
                  setApproveModalVisible(true);
                  log('jdisp', 'ApprovalList_Approve');
                  // window.location.reload();
                }}
              >
                {TLT(`审批通过`)}
              </Button>
              {/*审批状态 在 Pending approval 状态下 Reject*/}
              <Button
                type="primary"
                ghost
                style={{ width: '88px', marginLeft: '12px' }}
                onClick={() => {
                  setSelectedOrders([{ jdOrderId: params?.id, processId: params?.processId, pin: params?.orderPin }]);
                  setRejectModalVisible(true);
                  log('jdisp', 'ApprovalList_Reject');
                  // window.location.reload();
                }}
              >
                {TLT(`审批驳回`)}
              </Button>
            </div>
          )}

          <div className={styles.operateBtn}>
            <div
              className={styles.print}
              onClick={() => {
                onHandlePrint(orderRes?.orderNo);
              }}
            >
              {TLT(`订单打印`)}
            </div>
          </div>
          {approveModal}
          {rejectModal}
        </div>
      )}
    </div>
  );
};

export default TabInfo;
