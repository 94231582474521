/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2023-05-05 18:36:55
 * @LastEditors: weiyanna weiyanna@jd.com
 * @LastEditTime: 2023-05-06 16:09:26
 * @FilePath: /isp_pro/src/pages/Approval/Create/components/drawFlow/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import RowFactory from './utils/FactoryRow';
import FlowFactory from './utils/FlowFactory';

import { getRoot, transToTreeDat } from './utils';

import { dispatchSecondNoConditionId } from '@/redux/action';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  operateType?: number;
  flowConfig?: any;
  processType?: number;
}
const DrawFlow: React.FC<IProps> = (props: any) => {
  const { processType, flowConfig, operateType } = props;
  const dispatch = useDispatch();

  const selfConfig = JSON.parse(JSON.stringify(flowConfig));
  const temp = getRoot(selfConfig);
  const root = temp ? JSON.parse(JSON.stringify(getRoot(selfConfig))) : null;

  const treeData = transToTreeDat(selfConfig);

  const isRootHaveConditionNode = treeData[0]?.childNode?.conditionNodes ? true : false;
  // console.log('isRootHaveConditionNode--', root);
  if(isRootHaveConditionNode !== null && root !== null){
    root.isRootHaveConditionNode = isRootHaveConditionNode;
  }
  const { t } = useTranslation();

  const drawBody = (h: any, node: any, processTypeTemp: any, operateType: number) => {
    if (node.childNode) {
      return FlowFactory.getFactory(h, node.childNode, processTypeTemp, operateType);
    } else {
      return <div></div>;
    }
  };
  useEffect(() => {
    // 如果是无条件审批
    // if (processType === 1) {
      dispatch(dispatchSecondNoConditionId(treeData[0]?.childNode?.id));
    // } else {
      // dispatch(dispatchSecondNoConditionId(''));
    // }
  }, [processType, flowConfig]);

  return (
    <div className={styles.drawFlow}>
      <div className={styles.flowDesign}>
        <div className={styles.polyfillContainer}>
          <div className={styles.boxScale} id={'boxScale'}>
            {RowFactory.nodeWrapRender(this, root, null, processType)}
            {drawBody(this, treeData[0], processType, operateType)}
            <div className={styles.endNode}>{TLT(`结束`)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawFlow;
