import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RecoilRoot } from 'recoil';
import queryString from 'query-string';
import { initAddress, initOrderInfo } from '@/api/newApi/orderInfo';
import { useSetRecoilState, useRecoilState } from './hooks';
import cookies from 'react-cookies';
import Consignee from './types/consignee';
import Consignees from './components/Consignees';
import Panel from './components/Panel';
import Shipment from './components/Shipment';
import TradeType from './components/TradeType';
import Condition from './components/Condition';
import TransFee from './components/TransFee';
import styles from './index.module.scss';
import Remark from './components/OrderRemark';
import { useTranslation } from 'react-i18next';
import successIcon from '../../assets/images/orderInfo/success.png';
import { Affix } from 'antd';
import { LoginType } from '@/types/user.d';
import { useSelector } from 'react-redux';

const Index = () => {
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土
  const setConsigneeList = useSetRecoilState('consigneeList'); // Address list
  const setConsignee = useSetRecoilState('consignee'); // Address list
  const setConditionList = useSetRecoilState('conditionList'); // 条件列表
  const setCondition = useSetRecoilState('condition'); // 条件列表
  const setApprovalList = useSetRecoilState('approvalList'); // 审批流程
  const setApproval = useSetRecoilState('approval'); // 审批流
  const setShipment = useSetRecoilState('shipment'); // main message
  const [others, setOthers] = useRecoilState('others');
  const [transfee, setTransfee] = useRecoilState('transfee'); // main message
  const [ceiling, setCeiling] = useState('ceiling');
  const [tradeTypeOption, setTradeType] = useRecoilState('tradeType'); // 设置贸易条款
  const setTaxfee = useSetRecoilState('taxfee'); // 设置税金

  const { t } = useTranslation();

  const mainEl = useRef(null);
  const panelEl = useRef(null);

  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });

  const handleScroll = useCallback(() => {
    const main: unknown = mainEl.current;
    const panel: unknown = panelEl.current;
    if (!main || !panel) return;
    const mainRect = (main as HTMLDivElement).getBoundingClientRect();
    const panelRect = (panel as HTMLDivElement).getBoundingClientRect();
    if (mainRect.top < 0) {
      setCeiling('fixed');
      if (mainRect.bottom < panelRect.height) {
        setCeiling('abs');
      }
    } else {
      setCeiling('abs');
    }
  }, [ceiling]);

  useEffect(() => {
    if (!currentUser?.loginType) return
    // URL 带参数 tradeModel
    const parsed = queryString.parse(location.search);
    initAddress({}).then((res: any) => {
      if (res.success) {
        const data = res?.value;
        setConsigneeList(data);
        const newConsignee = data?.find((item: Consignee) => item.defaultStatus === 1) || data?.[0];
        setConsignee(newConsignee);
      }
    });
    initOrderInfo(parsed).then((res: any) => {
      const data = res.value;
      if (res.success) {
        //本土采购信息备模块儿注单独加一个固定的采购单号默认放在第一个
        if(isLocalPro && currentUser.loginType !== LoginType.JP){
          data?.settleFunctionList.unshift({
            "category": TLT(`内部采购单号`),
            "contractNum": "ISPG-20221115112609",
            "createDate": "2024-05-15T05:32:24.000+00:00",
            "createPin": "工采国际测试6",
            "id": -1,
            "isDeleted": 0,
        })
        }
       
        const curConditionList = data?.settleFunctionList
        setConditionList(curConditionList)
        setApprovalList(data?.approveProcessList)
        setShipment({
          productList: data?.cartResp?.cartWares,
          ...data,
        });
        setOthers({
          ...others,
          ...parsed,
        });
        isLocalPro && setTaxfee(data?.cartResp?.totalPriceCurrencies?.showCurrency?.valueAddedTax);
      }
   
      const tradeFilterOption = (data?.tradeTypeList || []).map((ele)=>({
        label:ele.tradeTypeText,
        value:ele.tradeType + "",
        shippingTypeList: ele.shippingTypeList
      }))
      //设置贸易模式
      setTradeType({
        ...tradeTypeOption,
        selected:tradeFilterOption[0]?.value,
        option: tradeFilterOption,
      });
    });
  }, [currentUser]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.m}>
      {others?.paySuccess ? (
        <div className={styles.m_paysuccess}>
          <div className={styles.m_paysuccess_box}>
            <img src={successIcon} alt="success" style={{ marginBottom: 24 }} />
            <p>{TLT(`提单成功`)}</p>
            <div className={styles.box_buttongoulp}>
              <div className={styles.m_paysuccess_button}>
                <a href="/orderList" onClick={()=>{log('jdisp', 'Settlement_ToOrder');}}>{TLT(`查看订单`)}</a>
              </div>
              <div className={styles.m_paysuccess_button}>
                <a href="/" onClick={()=>{log('jdisp', 'Settlement_Homepage');}}>{TLT(`继续购物`)}</a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.mc}>
            <div className={styles.main} ref={mainEl}>
              <div className={styles.main_title}>{TLT(`请检查订单信息`)}</div>
              <div className={styles.main_box}>
                <Consignees />
              </div>
              <Shipment />
             {!isLocalPro && !!tradeTypeOption.option && !!tradeTypeOption.option.length && <TradeType/>}
             {!isLocalPro && !!tradeTypeOption.option && !!tradeTypeOption.option.length && <TransFee/>}
              <Condition/>
              <div className={styles.main_remark}>
                <Remark></Remark>
              </div>
              <Affix offsetBottom={0}>
                <div
                  className={[ceiling === 'fixed' && styles.fixed, ceiling === 'abs' && styles.abs]
                    .filter(Boolean)
                    .join(' ')}
                  ref={panelEl}
                >
                  <Panel/>
                </div>
              </Affix>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Container: React.FC = () => {
  return (
    <RecoilRoot>
      <Index />
    </RecoilRoot>
  );
};

export default Container;
