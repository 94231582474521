/*
 * @Author: weiyanna
 * @Date: 2021-12-08 10:34:08
 * @LastEditTime: 2024-10-24 22:24:14
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/AddressList/index.tsx
 */
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Pagination, message, Modal, Checkbox, Table, Upload, UploadFile, UploadProps, Space, Form, Image } from 'antd';
import { useTranslation } from 'react-i18next';

import { Input } from 'antd';
import { useSelector } from 'react-redux';
import {
  createAddress,
  getAddressDetail,
  modifyAddress,
} from '@/api/newApi/personalCenter/address';
import AfterSaleDetailHeader from '../components/AfterSaleDetailHeader'
import Goods from '../components/Goods'


import styles from './index.module.scss';
import { GetRequest } from '@/util';
import { orderDetail } from '@/api/newApi/orderdetail';
import { afterSaleSubmit, getAfterSaleDetail, uploadFile } from '@/api/newApi/afterSale';
import { useHistory } from 'react-router-dom';


const AddressList: React.FC<{}> = () => {
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  // 查询条件
  const [orderDetails, setOrderDetails] = useState<any>({});
  const urlParams: any = GetRequest();
  const afterSalesOrderId = urlParams.afterSalesOrderId;
  // 中英文切换
  const { t } = useTranslation();
  const { TextArea } = Input;
  const history = useHistory();

  const store: any = useSelector(state => {
    return state;
  });
  const orderPin = urlParams.orderPin ? urlParams.orderPin : store?.User?.data[0]?.pin ?? '';
  const isVsp = urlParams.isVsp === '1' || false;


  useEffect(()=>{
    getDetail();
  }, [])

  const getDetail = ()=>{
    getAfterSaleDetail({
        afterSalesOrderId:afterSalesOrderId
    }).then((res)=>{
        setOrderDetails(res?.value)
    })
  }

  const toBack = ()=>{
    history.push('/orderList')
  }

  
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="ispContainer">
        <div className={styles.index} style={{ width: '1030px' }}>
          <Card className={styles.con} style={{ padding: '15px 20px', marginTop:"20px" }} bordered={false}>
            <AfterSaleDetailHeader orderDetails={orderDetails}/>
          </Card>
          <Card className={styles.con} style={{ padding: '15px 20px',margin:"20px 0" }}bordered={false}>
            <div className={styles.shopArea}>
                <div className={styles.title}>
                    {TLT(`商品清单`)}
                </div>
                <div className={styles.detailArea}>
                    <Goods mkuList={[{
                        skuImg:orderDetails?.afsWare && orderDetails?.afsWare[0]?.mainImage || '',
                        skuPrice:orderDetails?.afsWare && orderDetails?.afsWare[0]?.price,
                        skuNum:orderDetails?.afsWare && orderDetails?.afsWare[0]?.mkuNum,
                        totalPrice:orderDetails?.afsWare && orderDetails?.afsWare[0]?.mkuAllPrice,
                        sku:orderDetails?.afsWare && orderDetails?.afsWare[0]?.mkuId,
                        skuName:orderDetails?.afsWare && orderDetails?.afsWare[0]?.mkuName?.zh,
                    }]} orderDetails={{
                        createTime:orderDetails?.createTime
                    }}/>
                </div>
            </div>
            <div className={styles.shopArea}>
                <div className={styles.title}>
                    {TLT(`问题描述`)}
                </div>
                <div className={styles.detailArea}>
                   {orderDetails?.customerQuestionDesc}
                </div>
            </div>
            <div className={styles.shopArea}>
                <div className={styles.title}>
                    {TLT(`图片信息`)}
                </div>
                <div className={styles.detailArea}>
                    {
                        orderDetails?.questionPic && orderDetails?.questionPic.split(',').map((ele, idx)=> <Image width={80} height={80} src={ele} key={idx}/>)
                    }
                   
                </div>
            </div>
            <div className={styles.shopArea} style={{ padding: '15px 20px',margin:"20px 0" }}>
                <Row>
                    <Col span={3} offset={21}>
                        <Space>
                            <Button type="default" onClick={()=> toBack()}>{TLT(`返回`)}</Button>
                        </Space>
                    </Col>
                </Row>
              
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default AddressList;
