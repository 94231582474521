/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-03-26 15:45:22
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-09 16:47:05
 * @FilePath: /isp_International/src/pages/CartsNew/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useRef, useState } from 'react';
import { Affix } from 'antd';
import { useRecoilState, useSetRecoilState } from './hooks';
import ParentContext from '@/pages/BatchCarts/context'

import { getPersonInfo } from '@/api/newApi/carts';

import Goods from './components/Goods/index';
import PlaceOrder from './components/PlaceOrder/index';
import Empty from './components/Empty/index';
import Operate from './components/Operate/index';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';

const Index = ({ successProduct, errorProduct, updateAdded }) => {
  const cartNewRef = useRef();
  const [bottom, setBottom] = useState(0);
  const [skuList, setSkuList] = useRecoilState('skuList');
  const [errorSkuProduct, setErrorSkuProduct] = useRecoilState('errorSkuProduct');
  const setSelected = useSetRecoilState('selected');
  const setSelectedSkuList = useSetRecoilState('selectedSkuList');
  const setTotalInfos = useSetRecoilState('totalInfos');
  const setIsCanPlaceOrder = useSetRecoilState('isCanPlaceOrder');
  const [loading, setLoading] = useRecoilState('loading');
  // 列表加载状态
  const [pageLoad, setPageLoad] = useState(false);

  const store: any = useSelector(state => {
    return state;
  });

  useEffect(() => {
    setErrorSkuProduct(errorProduct || []);
    setSkuList(successProduct?.cartWares || [])

    if (store?.User?.data.length > 0) {
      // 只有角色5 采购员 可以下单
      const userType = store?.User?.data[0]?.userType ?? '';
      if (userType === 3 || userType === 5) {
        setIsCanPlaceOrder(true);
      } else {
        setIsCanPlaceOrder(false);
      }
    } else {
      getPersonInfo().then(res => {
        if (res?.value?.userType === 3 ||res?.value?.userType === 5) {
          setIsCanPlaceOrder(true);
        } else {
          setIsCanPlaceOrder(false);
        }
      });
    }
  }, [successProduct, errorProduct]);

  return (
    <>
      <div className={styles.cartNew} ref={cartNewRef}>
        {!pageLoad && (skuList?.length > 0 || errorSkuProduct?.length > 0) && (
          <React.Fragment>
            <Goods updateAdded={updateAdded}></Goods>
            <Affix offsetBottom={bottom}>
              <PlaceOrder></PlaceOrder>
            </Affix>
          </React.Fragment>
        )}
      </div>
      <Operate></Operate>
    </>
  );
};
const App: React.FC = ({ deleteHandler, smartMatch, successProduct, errorProduct, updateAfterDelete, updateAdded }) => {
  return (
    <ParentContext.Provider value={{ deleteHandler, updateAfterDelete, smartMatch }}>
      <Index successProduct={successProduct} updateAdded={updateAdded} errorProduct={errorProduct}></Index>
    </ParentContext.Provider>
  );
};

export default App;
