/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2023-03-09 11:02:45
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-22 16:02:24
 * @FilePath: /isp_pro/src/pages/Approval/Create/components/deletePrompt/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { Button, Modal,Row } from 'antd';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
interface IProps {
  open?: boolean;
  node?: any;
  confirmCallBack?: (node: any) => void;
  cancelCallBack?: () => void;
}
const DeletePrompt: React.FC<IProps> = (props: any) => {
  const { open = false, node, cancelCallBack, confirmCallBack } = props;

  const { t } = useTranslation();

  return (
    <Modal
      centered
      visible={open}
      width={464}
      className={styles.deletePrompt}
      footer={
        <Row justify={'end'} style={{width: '100%',textAlign: 'right'}}>
          <Button onClick={() => {
            cancelCallBack && cancelCallBack();
          }}>
          {TLT(`取消`)}
          </Button>
          <Button 
            type='primary' 
            onClick={() => {
              confirmCallBack && confirmCallBack(node);
            }}>
            {TLT(`删除`)}
          </Button>
        </Row>
      }
      // closeIcon={<div className={styles.iconClose}></div>}
      onCancel={() => {
        cancelCallBack && cancelCallBack();
      }}
    >
      <div className={styles.deleteContent}>
        <div className={styles.contentLeft}>
          <em></em>
        </div>
        <div className={styles.contentRight}>
          <div className={styles.deleteTitle}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {TLT(`删除`)}{node.nodeType === 2 ? TLT(`审批节点`) : TLT(`审批条件`)}"{node.nodeName}"
          </div>
          <div className={styles.deleteTips}>
          {TLT(`删除后该`)}{node.nodeType === 2 ? TLT(`审批节点`) : TLT(`审批条件`)}{TLT(`下配置的所有信息也将一并删除`)}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePrompt;
