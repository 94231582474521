import http, { messageHttp } from '../axios';

export function getBatchOrderTemplate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/cart/batch/template', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function batchSmartMatch(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/batch/smartMatch', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function uploadBatchTemplate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/batch/parse', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        reject(error);
      },
    );
  });
}

export function checkedItemsPrice(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/batch/checkedItemsPrice', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        reject(error);
      },
    );
  });
}