/*
 * @Author: duchongchong1
 * @Date: 2022-10-26 09:17:45
 * @LastEditors: duchongchong1
 * @LastEditTime: 2023-08-28 17:14:53
 * @FilePath: /isp_International/src/pages/AccountSettings/components/ChangePasswordModal/index.tsx
 * @Description:
 */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Button, Modal, Row, Form, Input, message } from 'antd';
import { modifyPwd } from '@/api/newApi/personalCenter/accountSettings';
import { useTranslation } from 'react-i18next';

const ChangePasswordModal: React.FC<{
  visible: boolean;
  onCancel?: () => void;
}> = ({ visible, onCancel }) => {
  // 表单
  const [form] = Form.useForm();
  // 按钮加载
  const [loading, setLoading] = useState(false);
  // 中英文切换
  const { t } = useTranslation();
  /**
   * 保存的回调函数
   * @param values 保存的form表单信息
   */
  const onFinish = (values: any) => {
    setLoading(true);
    modifyPwd(values)
      .then((res: any) => {
        setLoading(false);
        if (res?.success) {
          message.success(TLT(`修改成功!`));
          onCancel && onCancel();
        } else {
          message.error(res?.msg || TLT(`修改失败, 请稍后再试`));
        }
      })
      .then(() => {
        setLoading(false);
      });
  };
  // 监听visible，实现初始化弹窗清空保留信息
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);
  return (
    <Modal
      title={TLT(`修改登录密码`)}
      className="detailModel"
      open={visible}
      onCancel={onCancel}
      centered={true}
      width={500}
      footer={null}
    >
      <Form
        form={form}
        colon={false}
        labelCol={{ span: 6 }}
        name="control-hooks"
        onFinish={onFinish}
        className={styles.searchForm}
      >
        {/* 旧密码 */}
        <Form.Item
          key="formItem1"
          name="oldPwd"
          label={TLT(`原密码`)}
          rules={[{ required: true, message: TLT(`请输入原密码`) }]}
          getValueFromEvent={e => e.target.value}
        >
          <Input.Password placeholder={TLT(`请输入原密码`)} />
        </Form.Item>
        {/* 新密码 */}
        <Form.Item
          extra={<div className={styles.tips}>{TLT(`8~20位,至少含大小写字母/数字/符号2种组合`)}</div>}
          key="formItem2"
          name="newPwd"
          label={TLT(`新密码`)}
          rules={[{ required: true, message: TLT(`请输入新密码`) }]}
        >
          <Input.Password placeholder={TLT(`请输入新密码`)} />
        </Form.Item>
        {/* 确认新密码 */}
        <Form.Item
          key="formItem3"
          name="confirmNewPwd"
          label={TLT(`确认新密码`)}
          validateFirst={true}
          dependencies={['newPassword']}
          rules={[
            { required: true, message: TLT(`请再次输入新密码`) },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(TLT(`两次输入的新密码不一致，请重新输入！`)));
              },
            }),
          ]}
          getValueFromEvent={e => e.target.value}
        >
          <Input.Password placeholder={TLT(`请再次输入新密码`)} autoComplete="off" />
        </Form.Item>
        <Row justify="end">
          {/* 保存 */}
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ fontSize: '14px', width: '100px', height: '32px', marginBottom: '10px', marginRight: '16px' }}
          >
            {TLT(`确定`)}
          </Button>
          {/* 取消 */}
          <Button onClick={onCancel} style={{ fontSize: '14px', width: '100px', height: '32px', marginBottom: '10px' }}>
            {TLT(`取消`)}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
