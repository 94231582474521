export function numberToChinese(num: number) {
  const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const units = ['', '十', '百', '千', '万', '亿'];
  
  if (num === 0) {
      return chineseNumbers[0];
  }

  let result = '';
  let unitIndex = 0;
  let needZero = false;

  while (num > 0) {
      const digit = num % 10;
      if (digit === 0) {
          if (result.length > 0 && !needZero) {
              result = chineseNumbers[0] + result;
              needZero = true;
          }
      } else {
          result = chineseNumbers[digit] + units[unitIndex] + result;
          needZero = false;
      }
      num = Math.floor(num / 10);
      unitIndex++;
  }

  // 处理十位的特殊情况
  if (result.startsWith('一十')) {
      result = result.substring(1);
  }

  return result;
}

