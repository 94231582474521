import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from '../../index.module.scss';

const Empty = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.empty}>
      <div className={styles.content}>
        <div className={styles.logo}></div>
        <div className={styles.tips}>{TLT(`采购清单是空的！`)}</div>
        <div className={styles.but}>
          <Button
            type="primary"
            style={{ minWidth: '156px' }}
            onClick={() => {
              window.location.href = '/';
            }}
          >
            {TLT(`去采购`)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Empty;
