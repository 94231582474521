/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-05 15:48:45
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-20 15:55:13
 * @FilePath: /isp_International/src/pages/MaterialCode/components/TableHeader/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Button, Checkbox, Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface TableHeaderProps{
    checked:boolean; //是否选中
    checkAllHandler:(isChecked:boolean)=>void; //全部选中回调
    btnClickHander:(type:string)=>void;//按钮回调 type 1 单个新增  type 2 批量新增  
}
const  TableHeader: React.FC = (props:TableHeaderProps) => {
    const  {checked, checkAllHandler, btnClickHander} = props;
    const { t, i18n } = useTranslation();
    const launguage = i18n.language;
    
    return <Row className={styles.header}>
        <Col span={launguage == 'zh' ? 7 : 6}>
            <Checkbox
                className={styles.checkAll}
                style={{ color: '#333' }}
                // indeterminate={indeterminate}
                checked={checked}
                onChange={e => {
                    checkAllHandler(e.target.checked)
                }}
        >
           {TLT(`全选`)}
          </Checkbox>
          <Button onClick={()=>btnClickHander && btnClickHander('3')}>{TLT(`批量删除`)}</Button>
        </Col>
        <Col span={5} offset={launguage == 'zh' ? 12 : 11}>
            <Space>
                <Button onClick={()=>btnClickHander && btnClickHander('1')}>{TLT(`单个新增`)}</Button>
                <Button onClick={()=>btnClickHander && btnClickHander('2')}> {TLT(`批量新增`)}</Button>
            </Space>
        </Col>
    </Row>
}
export default TableHeader;