import React from 'react';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import PropertyList from '@/components/PropertyList';
import styles from './index.module.scss';
// import { useTranslation } from 'react-i18next';

const Index = () => {
  // const others = useRecoilValue('others');
  // const main = useRecoilValue('main');

  //
  const _productAttr = useRecoilValue('productAttr');
  // const { t } = useTranslation();
  const productAttr = Object.entries(_productAttr ? _productAttr : {}).map(([key, value]) => ({
    key,
    value,
  }));

  //

  // const searchBrand = () => {
  //   if (main?.basicInfo?.brandName) {
  //     window._openPage(
  //       `/rede/page/${main?.pageType === 1 ? 'list' : 'listCable'}?key=` +
  //         encodeURIComponent(main?.basicInfo?.brandName),
  //       '_self',
  //     );
  //   }
  // };

  // const oldBaseFlag = others?.oldBaseFlag;
  // const productProfPropertyList = others?.productProfPropertyList;
  // const wReadMe = others?.wReadMe;

  // //
  // const wReadMe2 = bigField?.wReadMe;
  // //
  // const productGroupInfos = others?.productGroupInfos;

  return (
    <div className={styles.m} id="parameter">
      {/*{others?.propertyListImage ? (*/}
      {/*  <div className={styles.mc}>*/}
      {/*    <img src={`https://m.360buyimg.com/da/${others.propertyListImage}`} width="840" />*/}
      {/*  </div>*/}
      {/*) : (*/}
      <>
        {/*{others?.packingInfo && (*/}
        {/*  <div className={styles.m_box} style={{ marginBottom: '32px' }}>*/}
        {/*    <div className={styles.m_box_title}>包装清单</div>*/}
        {/*    <div className={styles.packing}>{others?.packingInfo}</div>*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*{bigField && (*/}
        {/*  <div className={styles.m_box} style={{ marginBottom: '32px' }}>*/}
        {/*    <div className={styles.m_box_title}>包装清单</div>*/}
        {/*    <div className={styles.packing}>{bigField?.wareQD}</div>*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className={styles.m_box}>
          {/*<div className={styles.m_box_title}>{TLT(`规格参数`)}</div>*/}
          {/*{main?.basicInfo && (*/}
          {/*  <div className={styles.mc_cbox} onClick={searchBrand}>*/}
          {/*    <div className={styles.mc_cbox_title}>品牌：</div>*/}
          {/*    <div className={styles.mc_cbox_content}>{main?.basicInfo?.brandName}</div>*/}
          {/*  </div>*/}
          {/*)}*/}
          {/* {others?.propertyList && (
            <div className={styles.mc_plbox}>
              {others?.propertyList?.map((item, index) => (
                <div className={styles.mc_plbox_box} key={item?.key}>
                  <div className={styles.mc_plbox_box_title}>{item?.key}：</div>
                  {item?.value.length > 13 ? (
                    <Tooltip placement="top" title={item?.value}>
                      <div className={styles.mc_plbox_box_content}>{item?.value}</div>
                    </Tooltip>
                  ) : (
                    <div className={styles.mc_plbox_box_content}>{item?.value}</div>
                  )}
                </div>
              ))}
            </div>
          )} */}
          {/*{!oldBaseFlag && productProfPropertyList && productProfPropertyList?.length > 0 && (*/}
          {/*  <PropertyList List={productProfPropertyList} paddingLeft="0" />*/}
          {/*)}*/}
          {/*{oldBaseFlag && productGroupInfos && productGroupInfos?.length > 0 && (*/}
          {/*  <PropertyList List={productGroupInfos} paddingLeft="0" />*/}
          {/*)}*/}
          {/*{oldBaseFlag && wReadMe && (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      color: '#999',*/}
          {/*      lineHeight: '30px',*/}
          {/*      fontSize: '14px',*/}
          {/*    }}*/}
          {/*    dangerouslySetInnerHTML={{*/}
          {/*      __html: wReadMe,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*)}*/}

          {/**/}
          {/*{oldBaseFlag && wReadMe2 && (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      color: '#999',*/}
          {/*      lineHeight: '30px',*/}
          {/*      fontSize: '14px',*/}
          {/*    }}*/}
          {/*    dangerouslySetInnerHTML={{*/}
          {/*      __html: wReadMe2,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*)}*/}
          {/**/}

          {/**/}
          {productAttr && productAttr?.length > 0 && <PropertyList List={productAttr} paddingLeft="0" />}
          {/**/}
        </div>
      </>
      {/*)}*/}
    </div>
  );
};

export default Index;
