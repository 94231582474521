/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-02-05 10:06:08
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-11 11:08:55
 * @FilePath: /isp_International/src/pages/OrderInfo/components/TradeType/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import { message, Card, Radio } from 'antd';
import cookies from 'react-cookies';
import { freightCalculate } from '@/api/newApi/orderInfo';
import { useRecoilState, useRecoilValue, useSetRecoilState } from '../../hooks';
import styles from './index.module.scss';

const Index = () => {
  const tradeType = useRecoilValue('tradeType');
  const [tradeTypeOption] = useRecoilState('tradeType'); // 设置贸易条款
  const [ shippingType, setShippingType] = useRecoilState('shippingtype');
  const consignee = useRecoilValue('consignee'); 
  const shipment = useRecoilValue('shipment');
  const setTransfee = useSetRecoilState('transfee');
  const setTaxfee = useSetRecoilState('taxfee');
  const [feeInfo, setFeeInfo] = useState<any>([]);
  const [shippingOptions, setShippingOptions] = useState<any>([]);
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土

  useEffect(() => {
    if(tradeTypeOption.option.length){
      const options = tradeTypeOption.option.filter((ele)=>ele.value == tradeTypeOption.selected)[0].shippingTypeList.map((ele)=>({
        label:ele.shippingTypeText,
        value: ele.shippingType+''
      }))
     
      setShippingOptions(options)
      // //设置全局选中运输方式
      setShippingType(options[0].value)
      if(shipment && consignee){
        getFeeDetail(options[0].value);
      }
    }
  },[tradeTypeOption])
 
  useEffect(() => {
    //有收货地址信息获取运费明细
    if(shipment && consignee && shippingOptions && shippingOptions.length){
      getFeeDetail(shippingOptions[0].value);
    }
  }, [shipment, consignee])

  const getFeeDetail = async(value)=>{
    const mkuList = shipment?.productList ? shipment?.productList.map((ele)=>({
      mkuId:ele.sku,
      count:''+ele.skuNum,
    })) : []
    if(!mkuList.length){
      return
    };

    const resData = await freightCalculate({
      countyId:consignee?.countyId,
      tradeType:tradeTypeOption.selected+'',
      shippingType:value,
      mkuList,
    });
    if(resData?.success){
      setFeeInfo(resData?.value)
      setTransfee(resData?.value?.freight || 0)
      !isLocalPro && setTaxfee(resData?.value?.taxes || 0)
    }else{
      message.error(resData?.msg);
    }

  }

  return (
    <div className={styles.m}>
    <Card bordered={false} title={TLT(`运输方式`)}>
      <div className={styles.m_box}>
        <Radio.Group options={shippingOptions} onChange={(e)=>{
            setShippingType(e.target.value)
            getFeeDetail(e.target.value);
        }} value={shippingType}>
        </Radio.Group>
        {/* <div className={styles.feeTitle}>
            <div className={styles.feeTitleName}>{TLT(`费用项`)}</div>
            <div className={styles.feeTitleItem}>{TLT(`结算币种`)}</div>
            <div className={styles.feeTitleItem}>{TLT(`跨境运费`)}</div>
        </div>
        <div className={styles.itemArea}>
          {feeInfo && !!feeInfo?.itemList?.length && feeInfo.itemList.map((ele, idx)=><div key={idx} className={styles.item}>
            <div className={styles.feeName} >
          {ele.quoteName}
          </div>
          <div className={styles.feeCurrency} >
          {ele.currency}
          </div>
          <div className={styles.freight} >
          ¥  {ele.freight}
          </div>
          </div>)}
        </div>
        <div className={styles.feeTotal}>{TLT(`跨境运费总计`)}   ¥ {feeInfo?.freight}</div> */}
      </div>
    </Card>
    </div>
  );
};

export default Index;
