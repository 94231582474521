import style from '@/pages/Login/index.module.scss';
import React from 'react';
import PIC_ENMU from '../../enum/index';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import Map from "@/pages/Login/components/map";

interface BaseIF {
  title: string;
  img: any;
  subtitle: string;
  hoverContent?: string[];
}

const Ability = () => {
  const { t, i18n } = useTranslation();

  const baseAbilityMap: BaseIF[] = [
    {
      title: TLT(`快速准确选品`),
      img: PIC_ENMU.ability.ability_top_1,
      subtitle: TLT(`标准化合规推品，需求的智能洞察，快速寻源和海量的工业品助力快速精准触达。`),
    },
    {
      title: TLT(`快速低成本交付`),
      img: PIC_ENMU.ability.ability_top_2,
      subtitle: TLT(`高效的社会化供应链协同体系，创新的国际供应链网络，海外仓和智能履约决策实现高效低成本履约。`)
    },
    {
      title: TLT(`极致的采购体验`),
      img: PIC_ENMU.ability.ability_top_3,
      subtitle: TLT(`互联网采购体验，简单易用、即需即采；数字留痕，透明合规，流程优化，满足企业降本增效合规保供。`)
    },
  ];
  const moreAbilityMap: BaseIF[] = [
    {
      title: TLT(`伴随式出海`),
      img: PIC_ENMU.ability.abilitys_1,
      // subtitle: '提供企业客户专属定制化配置商城，满足企业个性化需求，助力企业实现工业品采购数字化转型。',
      subtitle: TLT(`跟随企业的全球化业务发展需要，快速在全球热点地区设立供应链服务，助力企业的出海需求。`),
      // hoverContent: [
      //   '电商化，极致的互联网采购体验，随时随地、即需即采；',
      //   '数字化，搜索选型、BOM清单高效下单，审批流程、物流信息实时追踪；',
      //   '个性化，客户定制商城，支持专属类目与商品的可视化行业场景方案。',
      // ],
      hoverContent: [
        'Customized full category shopping mall, online approval of purchase orders, achieving one-stop compliant procurement.',
        'Standardize the categories of chemical products, search and select precise matches, and efficiently select and place orders.',
        'Overseas empowerment of supply chain, tracking of cross-border performance trajectory, and precise control of performance timeliness.',
      ],
    },
    {
      title: TLT(`全托管服务`),
      img: PIC_ENMU.ability.abilitys_2,
      subtitle: TLT(`从营销、商品、定价，到下单、履约、结算、售后，为供应商完美解决全球业务拓展的难题。`),
      hoverContent: [
        '智能化，通过AI算法，输出京东一品多商检测，多源比价等采购实用工具；',
        '高效化，集成京东供应链能力，高效识别商品信息，提供商品物料智能匹配；',
        '标准化，基于工业知识图谱和智能商品挂载能力，构建商品标准化运营流程。',
      ],
    },
    // {
    //   title: '订单履约精准化',
    //   img: PIC_ENMU.ability.abilitys_3,
    //   subtitle:
    //     '搭建订单履约时效引擎，通过上游供应商实时库存，发货管控及三方物流时效预估，为客户提供履约时效准确展示和预约送达时效选择。',
    //   hoverContent: [
    //     '下单前，商详展示发货及送货时效，方便客户匹配时效选择；',
    //     '下单中，结算支持预约送达时效选择，实现准确履约交付；',
    //     '下单后，订单送达时效监控与预测，履约时效精准把控。',
    //   ],
    // },
    {
      title: TLT(`数智供应链`),
      img: PIC_ENMU.ability.abilitys_4,
      subtitle: TLT(`以商品数字化、采购数字化、履约数字化和运营数字化能力，降低供应链的显性和隐性成本，实现保供、合规、降本、增效的目的。`),
      hoverContent: [
        '协同社会库存供应，系统对接供应商库存数据，实现社会化库存信息共享；',
        '供需匹配诊断洞察，提供客户，行业画像与商品，供应商标签精准匹配能力；',
        '全链路数据可视化，商机按时响应，商机有效报出，商家转化追踪。',
      ],
    },
    // {
    //   title: '工业品履约决策',
    //   img: PIC_ENMU.ability.abilitys_5,
    //   subtitle:
    //     '围绕订单、商品、仓网、库存、履约和客诉等大数据，建立客户标签、供应商标签、决策因子与规则，智能决策最优履约商家。',
    //   hoverContent: [
    //     '积累采购数据，订单，商品，商家，仓网，库存，履约和客诉等大数据；',
    //     '搭建采购标签，客户偏好，供应商特性，履约标签，行业属性等标签能力；',
    //     '智能履约决策，通过智能算法，为客户决策最优的履约商家，商品和库存。',
    //   ],
    // },
    // {
    //   title: '移动端采购协同',
    //   img: PIC_ENMU.ability.abilitys_6,
    //   subtitle: '移动PC双端云协同，随时随地下单，不受办公地点限制，全流程数字化采购协同，助力企业高效采购。',
    //   hoverContent: [
    //     '双端协同化采购，工采PC下单，APP同步审批；工采PC审批，APP同步审批结果；',
    //     '移动数据看板，采购数据随时查看，采购趋势时刻把握。',
    //     '从下单到收货到面单打印及管理，一站解决，收货无忧。',
    //   ],
    // },
  ];

  return (
    <div className={`${style.login_ability}`}>
      <div className={`${style.login_ability_bability}`}>
        {baseAbilityMap.map((e, index) => {
          return (
            <div className={`${style.bability_box}`} key={index}>
              <img src={e.img} className={`${style.bability_box_img}`} />
              <div className={`${style.bability_box_title}`}>{e.title}</div>
              <div className={`${style.bability_box_subtitle}`}>{e.subtitle}</div>
            </div>
          );
        })}
      </div>
      <Map />
      <p className={`${style.login_ability_title}`}>
        <a id={`d1`} className={`${style.login_ability_title_a}`}></a>
        {TLT(`地球村计划，助力客户的全球化`)}
      </p>
      <div className={`${style.login_ability_mability}`}>
        {moreAbilityMap.map((e, index) => {
          return (
            //style={PIC_ENMU.ability.ability_background}
            <div className={`${style.mability_box}`} key={index} >
              <LazyLoad>
                <img src={e.img} className={`${style.mability_box_img}`} />
              </LazyLoad>
              <div className={`${style.mability_box_title}`}>{e.title}</div>
              <div className={`${style.mability_box_subtitle}`}>{e.subtitle}</div>
              {/*<div className={`${style.mability_hover_box}`}>*/}
              {/*  {e.hoverContent &&*/}
              {/*    e.hoverContent.map((he, index) => {*/}
              {/*      return (*/}
              {/*        <div key={index} className={`${style.hover_box_content}`}>*/}
              {/*          <i/>*/}
              {/*          <p>{he}</p>*/}
              {/*        </div>*/}
              {/*      );*/}
              {/*    })}*/}
              {/*</div>*/}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Ability;
