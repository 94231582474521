import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  delHistory,
  getSearchHistoryList,
  addSearchHistory,
  getAcquireAssociativeWords,
  // getSearchHotAndDark,
  isSkuId,
} from '@/api/newApi/search';
import cookies from 'react-cookies';
import { getClstag } from '@/util/index';
import style from './index.module.scss';
import History from '../history';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
interface IAppProps {
  input_color: string;
  hot_word_id: string;
  hot_color: string;
  search_text: string;
  search_btn_color: string;
  search_size: number;
  search_color: string;
  is_search_hot: boolean;
  bg_color: string;
  is_bg_color: boolean;
  isLenovo: boolean;
  clientOpenWindow: boolean;
  location?: any;
}

const Search: React.FC<IAppProps> = props => {
  const { search_btn_color, is_search_hot, isLenovo } = props;
  const [isShow, setIsShow] = useState(false); //是否展示联想词列表
  const [isShowHistory, setIsShowHistory] = useState(false); //是否展示搜索记录列表
  const [sIndex, setSIndex] = useState(-1); //下拉框键盘操作第几个
  const [isLocation, setIsLocation] = useState(cookies.load('stationType')); //是否本土 0 本土
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const userInfo: any = useSelector((state: any) => {
    return state?.User?.data[0];
  });
console.log('search',location)
  const [inputVal, setInputVal] = useState(() => {
    // const url: any = GetRequest();
    if ((location as any).query) {
      return (location as any).query;
    }
    return '';
  }); //文本框输入的值

  useEffect(() => {
    setInputVal((location as any).query || '');
  }, [location]);
  const [searchList, setSearchList] = useState([]); //搜索词联想数据
  const [historyList, setHistoryList] = useState([]); // 历史记录数据
  const [isSearList, setIsSearList] = useState(false); //是否是搜索列表页面
  const [searchBgIdx, setSearchBgIdx] = useState(0); //记录搜索暗文第几个
  const [isFocus, setIsFocus] = useState(false); //是否获取焦点
  const [isShowScene, setIsShowScene] = useState<boolean>(false);


  //  获取搜索历史记录
  const searchHistoryFn = () => {
    const getData = async () => {
      const result: any = (await getSearchHistoryList()) as any; // [""]
      try {
        if (result?.value?.length) {
          const value = result.value.filter((i: string) => i);
          if (!value.length) {
            // 无搜索记录不提示弹窗
            setHistoryList([]);
          } else {
            setHistoryList(value);
            !inputVal && setIsShowHistory(true); // 展示搜索历史弹框
          }
        }
      } catch (e) {
        setHistoryList(result?.value || []);
        setIsShowHistory(true); // 展示搜索历史弹框
      }
    };
    getData();
  };

  //  删除历史记录
  const delSearchHistoryFn = (num: number, data: object) => {
    const newHistory = [...historyList];
    const getData = async () => {
      const result: any = (await delHistory(data)) as any;
      if (result.success) {
        if (num != -1) {
          newHistory.splice(num, 1);
          setHistoryList(newHistory);
        } else {
          setHistoryList([]);
        }
      }
    };
    getData();
  };

  //搜索联想数据
  const searchOne = (val: string) => {
    val &&
      getAcquireAssociativeWords(val)
        .then((res: any) => {
          if (res.success) {
            setSearchList(res.value.filter((i: string) => i));
            setIsShow(true);
          } else {
            message.error(res.msg);
          }
        })
        .catch(error => {
          message.error(error.msg);
        });
  };
  //搜索框获取焦点的时候增加交互
  const focusWith = () => {
    // const url: any = GetRequest();
    searchOne(inputVal);
    setInputVal(inputVal);
    setSIndex(-1); //清除键盘选中

    if (inputVal !== '') {
      setIsShowHistory(false); // 关闭搜索历史弹框
    } else {
      setIsShow(false); // 关闭关键词联想弹框
      setIsShowHistory(true);
    }

  };
  // 点击账号其他位置收起退出弹框
  const handleHid = (e: any) => {
    //为了解决有时候className获取出一个对象 导致监听事件失效
    if (typeof e?.target?.className !== 'string') {
      return;
    }
    const str = e.target?.className?.split('_')[1];
    if (str !== 'historyList' && str !== 'searchInput' && str !== 'hotHistoryList') {
      setIsShowHistory(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleHid);
    return () => {
      document.removeEventListener('click', handleHid);
    };
    return () => {
      return;
    };
  }, []);
  //搜索框输入change事件
  const changeFn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // const url: any = GetRequest();

    //联想
    if (isLenovo) {
      searchOne(e.target.value);
    }
    setInputVal(e.target.value);
    // setstrongValue(e.target.value);
    setSIndex(-1); //清除键盘选中
    if(isLocation == '0') return;
    if (e.target.value != '') {
      setIsShowHistory(false); // 关闭搜索历史弹框
    } else {
      searchHistoryFn(); //获取下搜索历史的数据
      setIsShow(false); // 关闭关键词联想弹框
      setIsShowHistory(true); // 展示搜索历史弹框
    }

  };

  //获取光标事件
  const focus = () => {
    const dom: any = document.getElementById('G_searchbg');
    if (dom) {
      dom.style.color = 'rgba(0, 0, 0, 0.26)';
    }

    if (inputVal == '') {
      searchHistoryFn();
    }
    focusWith();
    setIsFocus(true);
  };
  //离开光标事件
  const blurs = () => {
    // const url: any = GetRequest();
    const dom: any = document.getElementById('G_searchbg');
    if (dom) {
      dom.style.color = 'rgba(0, 0, 0, 0.46)';
    }
  
    setTimeout(() => {
      setSIndex(-1); //清除键盘选中
      setIsFocus(false);
    }, 300);
    setTimeout(() => {
      setIsShow(false);
    }, 300);
  };

  useEffect(() => {
    //判断是不是搜索列表页页面
    const urlName = window.location.pathname;
    if (urlName == '/page/list') {
      setIsSearList(true);
    }
  }, []);

  const getIsShowScene = () => {
    const pathname = window.location.pathname;
    if (pathname === '/' || pathname === '/home' || pathname === '/newHome') {
      setIsShowScene(true);
    } else {
      setIsShowScene(false);
    }
  };

  useEffect(() => {
    getIsShowScene();
    return () => {
      return;
    };
  }, [props.location.pathname]);
  //搜索联想列表，键盘上下键操控
  const handleKeyUp = (e: any) => {
    if (!isShow) return;
    const keyCode = e.keyCode;
    if (keyCode == 38 || keyCode == 40) {
      const newList: any = searchList.length > 0 && isShow ? [...searchList] : [...historyList];
      //如果没有联想词，禁止键盘上下操作
      if (newList.length <= 0) return;

      let idx = sIndex;
      if (keyCode == 38) {
        //方向键向上
        idx--;
        if (idx < 0) {
          idx = newList.length - 1;
        }
      }
      if (keyCode == 40) {
        //方向键向下
        idx++;
        if (idx > newList.length - 1) {
          idx = 0;
        }
      }

      setSIndex(idx);
      if (searchList.length > 0 && isShow) {
        setInputVal(newList[idx]);
      } else {
        setInputVal(newList[idx]);
      }
    }
  };
  // 跳转
  const openWinPage = async (inputVal: any) => {
    // if (inputVal) {
    // await addSearchHistory(inputVal);
      
    // }
    const result = (await isSkuId(inputVal)) as any;
      if (result.success && result.value) {
        return history.push({
          pathname: '/sku/' + result.value,
        });
      }
   
    const path = {
      pathname: '/page/list',
      query: inputVal,
    };
    history.push(path);
  };
  // 本场景搜索
  const handleSceneSearch = (inputVal: any) => {
    // //如果是在搜索列表页面，直接搜索，不带暗文数据
    if (isSearList) {
      openWinPage(inputVal.trim());
      return;
    }
    openWinPage(inputVal.trim());
  };
  //回车事件
  const handleKeyDown = (e: any) => {
    const keyCode = e.keyCode;
    if (keyCode == 13) {
      //回车键
      if (inputVal != '') {
        openWinPage(inputVal);
      }
    }
  };
  //点击搜索按钮
  const handleClick = () => {
    let value = '';
    // 如果是在搜索列表页面，直接搜索，不带暗文数据
    if (isSearList && inputVal) {
      value = inputVal;
      openWinPage(value);
      return;
    };
    openWinPage(inputVal);
  };
  //点击联想列表
  const lenovoFn = (value: string) => {
    setInputVal(value);
    openWinPage(value);
  };

  return (
    <div className={`${style.seach_con}`}>
      <div
        className={`${style.searchTop} ${isFocus ? style.action : ''}`}
        onMouseLeave={() => {
          // !isFocus && setIsShowHistory(false);
        }}
        onMouseEnter={() => {
          // isFocus && setIsShowHistory(true);
        }}
      >
        {isFocus && inputVal != '' ? (
          <i
            className={`${style.searchIconClose}`}
            onClick={() => {
              setInputVal('');
            }}
          ></i>
        ) : (
          ''
        )}
     
        <input
          clstag={getClstag('HomePage_SearchInputBox', 'gc')}
          placeholder=""
          className={`${style.searchInput} `}
          type="text"
          value={inputVal}
          onChange={e => {
            changeFn(e);
          }}
          onFocus={() => {
            // if(isLocation != '0') 
            // focus();
          }}
          onBlur={() => {
            blurs();
          }}
          onKeyUp={e => {
            handleKeyUp(e);
          }}
          onKeyDown={e => {
            handleKeyDown(e);
          }}
        ></input>
        <div
          clstag={getClstag('HomePage_SearchButton', 'gc')}
          className={`${style.searchBtn}`}
          onClick={() => {
            handleClick();
            log('jdisp', 'HomePage_SearchButton');
          }}
        >
          <span style={{ color: search_btn_color }}> {TLT(`搜索`)}</span>
        </div>
        {isShowHistory ? (
          <History
            historyList={historyList}
            openWinPage={openWinPage}
            width={716}
            searchHistoryFnEvent={delSearchHistoryFn}
            downIndex={sIndex}
            handleSceneSearch={handleSceneSearch}
          ></History>
        ) : (
          ''
        )}

        {isShow && inputVal && searchList && searchList.length ? (
          <ul
            className={`${style.searchList}`}
            style={{
              width: '716px',
            }}
          >
            {searchList.map((item: any, index) => {
              return (
                <li
                  key={index}
                  className={`${sIndex == index ? style.active : ''}`}
                  onClick={() => {
                    lenovoFn(item);
                  }}
                >
                  <div
                    className={`${style.item}`}
                    dangerouslySetInnerHTML={{
                      __html: item.replace(new RegExp(inputVal, 'g'), `<span>${inputVal}</span>`),
                    }}
                  >
                    {/* {item.slice(0, strongValue.length)}
                    <span className={style.strong}>{item.slice(strongValue.length)}</span> */}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          ''
        )}
      </div>
      <div className={style.allCate} onClick={()=>{
       openWinPage('')
      }}>{TLT(`全部商品`)}</div>
    </div>
  );
};
export default Search;
