/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-10-21 21:43:37
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-11-18 21:12:07
 * @FilePath: /isp_International/src/pages/Login/components/map/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import style from '@/pages/Login/index.module.scss';
import React from 'react';
import PIC_ENMU from '../../enum/index';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import mapcn from '@/assets/images/login/map_cn.png';
import mapen from '@/assets/images/login/map_en.png';
import mapvi from '@/assets/images/login/map_vi.png';


interface BaseIF {
  title: string;
  img: any;
  subtitle: string;
  hoverContent?: string[];
}

const Map = () => {
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  return (
    <div className={`${style.login_map}`}>
      <p className={`${style.login_map_title}`}>
        <a id={`d1`} className={`${style.login_map_title_a}`}/>
        {TLT(`京东工业国际业务的全球化布局`)}
      </p>
      {/* -- multiple langs TODO -- */}
      <div className={`${style.login_map_bability}`}>
        {
          language === 'zh' ? (
            <LazyLoad>
              <img src={mapcn} style={{ width: '1208px' }} alt="mapcn"/>
            </LazyLoad>
          ) : language === 'vi' ? (
            <LazyLoad>
              <img src={mapen} style={{ width: '1208px' }} alt="mapen"/>
            </LazyLoad>
          ) : (
            <LazyLoad>
              <img src={mapen} style={{ width: '1208px' }} alt="mapen"/>
            </LazyLoad>
          )
        }
      </div>
    </div>
  );
};

export default Map;
