import { lazy } from 'react';
import OrderList from '../pages/OrderList';
import ApprovalList from '../pages/ApprovalList';
import ProcessList from '../pages/Approval/List'; //审批流程列表
import Approval from '../pages/Approval/Create';
import SettleConfig from '../pages/SettleConfig';
import OrderDetailNew from '../pages/OrderDetailNew/index';
import AccountSettings from '../pages/AccountSettings';
import BindingEmail from '../pages/AccountSettings/components/BindingEmail';
// import ProcessList from '../pages/ProcessList/index';
import EditProcess from '../pages/EditProcess/index';
import TaskCenter from '../pages/TaskCenter/index';
import ApplyAfterSale from '../pages/AfterSale/ApplyAfterSale';
import AfterSaleDetail from '../pages/AfterSale/Detail';
import AddressList from '../pages/AddressList';
import AddressAuthority from '../pages/AddressAuthority';
import MaterialCode from '../pages/MaterialCode';

export default [
  {
    path: `/orderList`,
    name: 'OrderList',
    meta: {
      title: 'orderList',
    },
    exact: true,
    component: OrderList,
  },
  //订单审批
  {
    path: `/Approval`,
    name: 'Approval',
    meta: {
      title: 'Approval', //审核订单
    },
    exact: true,
    component: Approval,
    params: {
      isAudit: true,
    },
  },
  //配置管理
  {
    path: `/settleConfig`,
    name: 'SettleConfig',
    meta: {
      title: 'settleConfig', //审核订单
    },
    exact: true,
    component: SettleConfig,
    params: {
      isAudit: true,
    },
  },
  // 审核订单列表
  {
    path: `/approvalList`,
    name: 'ApprovalList',
    meta: {
      title: 'approvalList', //审核订单
    },
    exact: true,
    component: ApprovalList,
    params: {
      isAudit: true,
    },
  },
  //地址管理
  {
    path: `/addressList`,
    name: 'AddressList',
    exact: true,
    meta: {
      title: 'addressList',
    },
    component: AddressList,
  },
  {
    path: `/authorityAddress`,
    name: 'authorityAddress',
    exact: true,
    meta: {
      title: 'authorityAddress',
    },
    component: AddressAuthority,
  },
  //物料编码管理
  {
    path: `/materialManage`,
    name: 'MaterialCode',
    exact: true,
    meta: {
      title: 'materialCode',
    },
    component: MaterialCode,
  },
  {
    path: `/authorityAddress`,
    name: 'authorityAddress',
    exact: true,
    meta: {
      title: 'authorityAddress',
    },
    component: AddressAuthority,
  },
  // 订单回收站
  {
    path: `/deleteOrderList`,
    name: 'DeleteOrderList',
    meta: {
      title: '订单回收站',
    },
    exact: true,
    component: OrderList,
    params: {
      isDelete: true,
    },
  },
  // 批量下单批次查询
  {
    path: `/batchList`,
    name: 'BatchList',
    meta: {
      title: 'orderDetail',
    },
    exact: true,
    component: OrderList,
    params: {
      isBatch: true,
    },
  },
  // 订单详情
  {
    path: `/order/detail`,
    name: 'OrderDetailNew',
    meta: {
      title: 'orderDetail',
    },
    exact: true,
    component: OrderDetailNew,
  },
  {
    path: `/accountSettings`,
    name: 'AccountSettings',
    exact: true,
    meta: {
      title: 'account',
    },
    component: AccountSettings,
  },
  {
    path: `/bindingEmail`,
    name: 'BindingEmail',
    exact: true,
    meta: {
      title: '绑定邮箱',
    },
    component: BindingEmail,
  },
  //流程管理
  {
    path: `/processList`,
    name: 'ProcessList',
    exact: true,
    meta: {
      title: 'approvalSettings',
    },
    component: ProcessList,
  },
  //编辑审批流
  {
    path: `/editProcess`,
    name: 'EditProcess',
    exact: true,
    meta: {
      title: 'editApprovalFlow',
    },
    component: EditProcess,
  },
  //创建审批流
  {
    path: `/createProcess`,
    name: 'CreateProcess',
    exact: true,
    meta: {
      title: 'createApprovalFlow',
    },
    component: EditProcess,
  },
  {
    path: `/taskCenter`,
    name: 'taskCenter',
    exact: true,
    meta: {
      title: 'taskCenter',
    },
    component: TaskCenter,
  },
  //售后
  {
    path: `/afterSale/apply`,
    name: 'AfterSale',
    exact: true,
    meta: {
      title: 'AfterSale',
    },
    component: ApplyAfterSale,
  },
  {
    path: `/afterSale/detail`,
    name: 'AfterSale',
    exact: true,
    meta: {
      title: 'AfterSale',
    },
    component: AfterSaleDetail,
  }
];
