import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Row, Col, Checkbox, message, Select, Modal } from 'antd';
const { Option } = Select;
import Empty from '@/components/common/Empty';
import styles from './index.module.scss';
import { ReactComponent as IconInfo } from '@/assets/images/paymentRecognition/icon_info.svg';
import SimplePagination from '@/components/common/SimplePagination';
import { getBuyUserList, getBuyUserCount } from '@/api/newApi/personalCenter/processList';
import { intersection, difference } from 'lodash';
import UploadModel from '@/components/Upload/Modal';
import { apiLocationUrl } from '@/enum/config';
import { useTranslation } from 'react-i18next';

const BuyAccount: React.FC<{
  processKey: string | null;
  selectedAccounts: string[];
  onAccountSelect: (pins: string[]) => void;
}> = ({ processKey, selectedAccounts, onAccountSelect }) => {
  const { t } = useTranslation();
  const editing = !!processKey;
  const defaultProcessQueryType = editing ? 1 : 2;
  const [searchParams, setSearchParams] = useState<any>({ processQueryType: defaultProcessQueryType });

  const [selectAccountAll, setSelectAccountAll] = useState<boolean>(false);
  const [accountIndeterminate, setAccountIndeterminate] = useState(false);

  const [buyAccountsPage, setBuyAccountsPage] = useState<number>(1);
  const [buyAccountsPageSize] = useState<number>(20);
  const [buyAccounts, setBuyAccounts] = useState<Array<string>>([]);
  const [buyAccountsTotal, setBuyAccountsTotal] = useState<number>(0);
  const [validBuyAccountsTotal, setValidBuyAccountsTotal] = useState<number>(0);
  const [buyAccountsTotalPage, setBuyAccountsTotalPage] = useState<number>(0);
  const [buyAccountsRefresh, setBuyAccountsRefresh] = useState(true);
  const [uploadModelVisible, setUploadModelVisible] = useState(false);

  const [form] = Form.useForm();

  const resetSelect = (allIds: string[], selectedIds: string[]) => {
    const differenceIds = difference(allIds, selectedIds);
    const intersectionIds = intersection(allIds, selectedIds);
    setSelectAccountAll(differenceIds.length === 0);
    setAccountIndeterminate(intersectionIds.length > 0 && intersectionIds.length < allIds.length);
  };

  const getBuyAccounts = () => {
    getBuyUserList({
      pageindex: buyAccountsPage,
      pageSize: buyAccountsPageSize,
      processKey,
      ...searchParams,
    }).then((res: any) => {
      const data = res?.value ?? {};
      const acounts = data?.items ?? [];
      acounts.forEach((a: any) => {
        a.enabled = a.inProcess === 1 || a.inProcess === 3;
      });
      setBuyAccounts(acounts);
      setBuyAccountsTotalPage(data?.pageTotal ?? 0);
      resetSelect(
        acounts.map((a: any) => a.pin),
        selectedAccounts,
      );
    });
  };

  const getBuyAccountCount = () => {
    getBuyUserCount().then((res: any) => {
      setBuyAccountsTotal(res?.value?.accountCount ?? 0);
      setValidBuyAccountsTotal(res?.value?.inProcess ?? 0);
    });
  };

  useEffect(() => {
    getBuyAccountCount();
  }, []);

  useEffect(() => {
    if (buyAccountsRefresh) {
      getBuyAccounts();
      setBuyAccountsRefresh(false);
    }
  }, [buyAccountsRefresh]);

  useEffect(() => {
    resetSelect(
      buyAccounts.map((a: any) => a.pin),
      selectedAccounts,
    );
  }, [selectedAccounts]);

  const prevPage = () => {
    if (buyAccountsPage > 1) {
      setBuyAccountsPage(buyAccountsPage - 1);
      setBuyAccountsRefresh(true);
    }
  };
  const nextPage = () => {
    if (buyAccountsPage < buyAccountsTotalPage) {
      setBuyAccountsPage(buyAccountsPage + 1);
      setBuyAccountsRefresh(true);
    }
  };

  const submit = (values: any) => {
    setSearchParams(values);
    setBuyAccountsPage(1);
    setBuyAccountsRefresh(true);
  };

  function selectAllAccounts(e: any) {
    const allIds = buyAccounts.map((o: any) => o.pin);
    const enabledIds = buyAccounts.filter((o: any) => o.enabled).map((o: any) => o.pin);
    let selectedIds;
    if (enabledIds.length < allIds.length) {
      selectedIds =
        e.target.checked && accountIndeterminate
          ? difference(selectedAccounts, enabledIds)
          : [...selectedAccounts, ...enabledIds];
    } else {
      selectedIds = e.target.checked ? [...selectedAccounts, ...enabledIds] : difference(selectedAccounts, enabledIds);
    }
    onAccountSelect(selectedIds);
    resetSelect(allIds, selectedIds);
  }

  const handleUploadResponse = (resp: any) => {
    if (resp.success) {
      // const allIds = buyAccounts.map((o: any) => o.pin);
      const value = resp.value || {};
      const pins = value?.pins ?? [];
      const errorIds = value?.errorPins ?? [];
      const inProcessPins = value?.inProcessPins ?? [];

      if (errorIds.length + inProcessPins.length > 0) {
        Modal.error({
          title: '上传账号信息有误',
          content: (
            <div>
              <div>{errorIds.length > 0 ? `错误账号：${errorIds.join(', ')}` : ''}</div>
              <div>{inProcessPins.length > 0 ? `已被绑定的账号：${inProcessPins.join(', ')}` : ''}</div>
            </div>
          ),
        });
      } else {
        message.success('批量挂载采购账号成功！');
      }

      setUploadModelVisible(false);
      if (pins.length > 0) {
        onAccountSelect(pins);
        // resetSelect(allIds, selectedIds);

        // 导入完列表清除筛选
        // form.resetFields();
        // setSearchParams({ processQueryType: defaultProcessQueryType });
        // setBuyAccountsPage(1);
        // setBuyAccountsRefresh(true);
      }
    } else {
      message.error(resp.msg);
    }
  };

  const buyAccountBatchActions = () => {
    return (
      <div>
        <Row
          style={{
            backgroundColor: '#fff',
            alignItems: 'center',
            padding: '15px 0',
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: '12px', color: 'rgba(51,51,51,0.85)' }}>
            {TLT(`挂载采购账号`)}
          </span>
          <IconInfo
            style={{ width: '12px', height: '12px', color: '#aaa', fill: 'currentcolor', marginLeft: '10px' }}
          />
          <span style={{ fontSize: '12px', color: '#aaa', marginLeft: '5px' }}>
            {TLT(`已挂载到其他审批流的采购账号无法重复挂载`)}
          </span>
        </Row>
        <Form
          initialValues={{ processQueryType: defaultProcessQueryType }}
          form={form}
          colon={false}
          name="control-hooks"
          onFinish={submit}
          className={styles.buyAccountSearchForm}
        >
          <Row justify="space-between">
            <Row>
              <Form.Item name="accountName" key="formItem1" label={TLT(`账号查询`)}>
                <Input
                  placeholder={TLT(`输入采购账号查询`)}
                  maxLength={40}
                  style={{ width: '230px' }}
                />
              </Form.Item>
              <Form.Item key="formItem2" name="processQueryType" label=" ">
                <Select
                  placeholder={TLT(`全部`)}
                  allowClear
                  style={{ width: '230px' }}
                >
                  <Option key="0" value={0}>
                    {TLT(`全部`)}
                  </Option>
                  {editing && (
                    <Option key="1" value={1}>
                      {TLT(`已挂载账号`)}
                    </Option>
                  )}
                  <Option key="2" value={2}>
                    {TLT(`可挂载账号`)}
                  </Option>
                  <Option key="3" value={3}>
                    {TLT(`不可挂载账号`)}
                  </Option>
                </Select>
              </Form.Item>
            </Row>
            <div style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" style={{ width: '74px', marginRight: '20px' }}>
                {TLT(`搜索`)}
              </Button>
              <Button
                type="primary"
                ghost
                style={{ width: '74px' }}
                onClick={() => {
                  form.resetFields();
                  setSearchParams({ processQueryType: defaultProcessQueryType });
                  setBuyAccountsPage(1);
                  setBuyAccountsRefresh(true);
                }}
              >
                {TLT(`重置`)}
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    );
  };

  const buyAccountListTitle = (
    <Row
      style={{
        backgroundColor: 'rgba(0,3,20,0.08)',
        padding: '10px 0',
        color: '#fff',
      }}
    >
      <Col className={[styles.title1, styles.center].join(' ')}>
        {buyAccounts.length > 0 && (
          <Checkbox
            className={styles.checkAll}
            style={{ color: '#fff' }}
            indeterminate={accountIndeterminate}
            checked={selectAccountAll}
            onChange={selectAllAccounts}
          ></Checkbox>
        )}
      </Col>
      <Col className={[styles.title2, styles.center].join(' ')}>{TLT(`账号名称`)}</Col>
      <Col className={[styles.title3, styles.center].join(' ')}>{TLT(`邮箱`)}</Col>
      <Col className={[styles.title4, styles.center].join(' ')}>{TLT(`职位`)}</Col>
      <Col className={[styles.title5, styles.center].join(' ')}>{TLT(`所属审批流`)}</Col>
      <Col className={[styles.title6, styles.center].join(' ')}></Col>
    </Row>
  );

  const buyAccountList =
    buyAccounts.length > 0 ? (
      <div>
        {buyAccounts.map((o: any, index: number) => {
          const name = o.processName || '';
          return (
            <Row key={index} style={{ backgroundColor: o.enabled ? '#fff' : '#f9f9f9' }}>
              <Col className={[styles.title1, styles.goodsCell, styles.borderLeft, styles.borderBottom].join(' ')}>
                <Checkbox
                  disabled={!o.enabled}
                  checked={selectedAccounts.includes(o.pin)}
                  onChange={e => {
                    const _ids = e.target.checked
                      ? [...selectedAccounts, o.pin]
                      : selectedAccounts.filter(id => id != o.pin);
                    onAccountSelect(_ids);
                    const allIds = buyAccounts.map((p: any) => p.pin);
                    resetSelect(allIds, _ids);
                  }}
                />
              </Col>
              <Col className={[styles.title2, styles.goodsCell, styles.borderBottom].join(' ')}>{o.pin}</Col>
              <Col className={[styles.title3, styles.goodsCell, styles.borderBottom].join(' ')}>{o.bindingEmail}</Col>
              <Col className={[styles.title4, styles.goodsCell, styles.borderBottom].join(' ')}>{o.position}</Col>
              <Col className={[styles.title5, styles.goodsCell, styles.borderBottom].join(' ')}>
                {name.length > 8 ? name.slice(0, 7) + '...' : name}
              </Col>
              <Col
                className={[styles.title6, styles.goodsCell, styles.borderBottom, styles.borderRight].join(' ')}
              ></Col>
            </Row>
          );
        })}
      </div>
    ) : (
      <Empty height="190xp" />
    );

  const accountListFooter = () => {
    const selectAcountCount = selectedAccounts.length;

    return (
      <Row justify="space-between" align="middle" style={{ padding: '20px 0', alignItems: 'center' }}>
        <Row align="middle">
          {/*<Button type="primary" onClick={() => setUploadModelVisible(true)}>*/}
          {/*  批量挂载*/}
          {/*</Button>*/}
          <div>
            {selectAcountCount > 0 && (
              <span style={{ color: '#999' }}>
                {TLT(`已选`)}{' '}
                <span className={[styles.blue, styles.bold].join(' ')}>{selectAcountCount}</span>{' '}
                {TLT(`个，共有`)}
                {buyAccountsTotal}
                {TLT(`个采购账号，`)}
                {validBuyAccountsTotal}
                {TLT(`个可选账号`)}
              </span>
            )}
          </div>
        </Row>
        <SimplePagination
          page={buyAccountsPage}
          total={buyAccountsTotal}
          totalPage={buyAccountsTotalPage}
          onPrev={prevPage}
          onNext={nextPage}
        />
      </Row>
    );
  };

  return (
    <Card id="buyAccount" className={styles.buyAccountList} style={{ marginTop: '10px' }}>
      <div style={{ padding: '0 20px' }}>
        {buyAccountBatchActions()}
        {buyAccountListTitle}
        {buyAccountList}
        {accountListFooter()}
        {/*{uploadModelVisible && (*/}
        {/*  <UploadModel*/}
        {/*    visible={uploadModelVisible}*/}
        {/*    title={'批量挂载'}*/}
        {/*    template={`https://${apiLocationUrl}/special/approval/process/man/downloadBatchPurchaseTemp`}*/}
        {/*    action={`https://${apiLocationUrl}/special/approval/process/man/batchImportPurchase`}*/}
        {/*    tip={['已挂载到其他审批流的采购账户无法重复挂载。']}*/}
        {/*    maxCount={1}*/}
        {/*    fileName={'uploadFile'}*/}
        {/*    onCancel={() => setUploadModelVisible(false)}*/}
        {/*    onResponse={handleUploadResponse}*/}
        {/*    onOK={() => setUploadModelVisible(false)}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </Card>
  );
};

export default BuyAccount;
