/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import cookies from 'react-cookies';
import { Spin } from 'antd';
import { jolUrl, apiLocationUrl, apiJdigUrl, domainCookieUrl } from '@/enum/config';
import { message } from 'antd';
import { Decrypt, Encrypt } from './AES';
import { any } from 'ramda';

let requestCount = 0; //累计loading次数
function showLoading() {
  if (requestCount == 0) {
    const dom = document.createElement('div');
    const dom1 = document.createElement('div');
    dom.setAttribute('id', 'loading');
    dom1.setAttribute('class', 'loadwrapper');
    document.body.appendChild(dom);
    dom.appendChild(dom1);
    ReactDOM.render(React.createElement(Spin, { size: 'large' }), dom1);
  }
  requestCount++;
}
function hideLoading() {
  requestCount--;
  if (requestCount == 0) {
    const doms: any = document.getElementById('loading');
    document.body.removeChild(doms);
  }
}
axios.defaults.timeout = 100000;
axios.defaults.withCredentials = true;


const saveCookie = (key, val) => {
  cookies.save(key, val, {
    domain: domainCookieUrl,
    path: '/'
    // sameSite:'none'
  });
};

// 检测当前链接是否包含https或者http，如果有就不做单独处理了
const checkUrl = (url: any) => {
  return /^(http|https):/.test(url);
}
/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  config => {
    const hostname = window.location.hostname;
    if (config.headers.isLoading !== false) {
      console.log('showLoading');
      showLoading();
    }
    saveCookie('domainUrl', hostname)
    // config.data = JSON.stringify(config.data);
    config.headers = {
      ...config.headers,
      // 'Content-Type': 'text/plain',
      'X-Requested-With': 'XMLHttpRequest',
      'X-jd-ts': new Date().getTime(),
    };

    if (!checkUrl(config.url)) {
      config.url = 'https://' + apiJdigUrl + config?.url;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  response => {
    if (response.config.headers.isLoading !== false) {
      hideLoading();
    }
    return response;
  },
  error => {
    hideLoading();
    if (error.response && error.response.status == 401) {
      window._openPage('/passport/login', '_self');
    }
    // console.log("请求出错：", error);
  },
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url: string, params = {}, isLoading?: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        withCredentials: true,
        headers: {
          isLoading: isLoading,
          // encrypt: 0,
        },
      })
      .then(response => {
        resolve(response ? response.data : '');
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url: string, data: any, isLoading?: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    axios
      .post(url, formData, {
        headers: {
          isLoading: isLoading,
          // encrypt: 0,
        },
      })
      .then(
        response => {
          //关闭进度条
          resolve(response.data);
        },
        err => {
          reject(err);
        },
      );
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function jsonPost(url: string, data: any, isLoading?: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          isLoading: isLoading,
          // encrypt: 0,
        },
      })
      .then(
        response => {
          //关闭进度条
          resolve(response?.data);
        },
        err => {
          reject(err);
        },
      );
  });
}
/**
 * 封装jsondownload请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function jsonDownload(url: string, data: any, isLoading?: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          isLoading: isLoading,
        },
        responseType: 'blob',
      })
      .then(
        response => {
          let filename = response.headers['content-disposition'].split('filename=')[1] || '';
          filename = decodeURI(filename);
          //关闭进度条
          resolve({ blob: new Blob([response.data]), filename: filename });
        },
        err => {
          reject(err);
        },
      );
  });
}
/**
 * 封装download请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function download(url: string, data: any, isLoading?: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    axios
      .post(url, formData, {
        headers: {
          isLoading: isLoading,
        },
        responseType: 'blob',
      })
      .then(
        response => {
          let filename = response.headers['content-disposition']
            ? response.headers['content-disposition'].split('filename=')[1] || ''
            : '';
          filename = decodeURI(filename);
          //关闭进度条
          resolve({ blob: new Blob([response.data]), filename: filename });
        },
        err => {
          reject(err);
        },
      );
  });
}

//统一接口处理，返回数据
export default function http(fecth: any, url: any, param: {} | undefined, isLoading?: boolean, ase?: any) {
  const ASE = ase;
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case 'get':
        console.log('param', url ,param)
        if (ASE && param && Object.keys(param)?.length !== 0) {
          let query = '';
          Object.keys(param).forEach((item: any, index: any) => {
            query = query + item + '=' + param?.[item] + (index == Object.keys(param)?.length - 1 ? '' : '&');
          });
          param = { param: encodeURIComponent(Encrypt(query)) };
        }
        get(url, param, isLoading)
          .then(function(response) {
            if (response.success || response?.message == 'success') {
              const newRes = response?.value
                ? { ...response, value: ASE && response.value ? JSON.parse(Decrypt(response.value)) : response.value }
                : response;
                console.error(url, newRes)
              resolve(newRes);
              return;
            }
            resolve(response);
          })
          .catch(function(error) {
            console.log('get request GET failed.', error);
            reject(error);
          });
        break;
      case 'post':
        console.log('param',url , param)
        if (ASE && param && Object.keys(param)?.length !== 0) {
          param = { param: Encrypt(param) };
        }
        post(url, param, isLoading)
          .then(function(response) {
            if (response.success || response?.message == 'success') {
              const newRes = {
                ...response,
                value: ASE && response.value ? JSON.parse(Decrypt(response.value)) : response.value,
              };
              console.error(url, newRes)
              resolve(newRes);
              return;
            }

            resolve(response);
          })
          .catch(function(error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      case 'jsonpost':
        console.log('param', url ,param)
        if (ASE && param && Object.keys(param)?.length !== 0) {
          param = { param: Encrypt(param) };
        }
        jsonPost(url, param, isLoading)
          .then(function(response) {
            if (response.success || response?.message == 'success') {
              const newRes = {
                ...response,
                value: ASE && response.value ? JSON.parse(Decrypt(response.value)) : response.value,
              };
              console.log(url, newRes)
              resolve(newRes);
              return;
            }
            resolve(response);
          })
          .catch(function(error) {
            console.error('get request POST failed.', error);
            reject(error);
          });
        break;
      case 'download':
        download(url, param, isLoading)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      case 'jsonDownload':
        jsonDownload(url, param, isLoading)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      default:
        break;
    }
  });
}

export function messageHttp(method: string, url: string, params: object, isLoading?: boolean) {
  return new Promise((resolve, reject) => {
    http(method, url, params, isLoading).then(
      (res: any) => {
        if (res.success || res?.message == 'success') {
          resolve(res);
        } else {
          message.error(res.msg || res.message);
          resolve(res);
        }
      },
      error => {
        console.log('网络异常~', error);
        message.error('网络异常~');
        reject({ msg: '网络异常~' });
      },
    );
  });
}
