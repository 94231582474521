import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { getFlow } from '@/api/newApi/approvalList';
import { useTranslation } from 'react-i18next';
interface IAppProps {
  isModel: boolean;
  source: string;
  jdOrderId: any;
  orderPin: string;
}
const ApproveList: React.FC<IAppProps> = (props: any) => {
  const { t, i18n } = useTranslation();
  const { isModel, jdOrderId, orderPin } = props;
  const [nodeList, setNodeList] = useState([]);
  const [needRemind, setNeedRemind] = useState(false);
  const store: any = useSelector(state => {
    return state;
  });
  const pin = store?.User?.data[0]?.pin ?? '';
  const nodeListFn = () => {
    const getData = async () => {
      const result: any = (await getFlow({ orderId: jdOrderId, orderPin })) as any;
      console.log('result',result)
      if (result.success) {
        const nList = result?.value?.node ?? [];
        for (const item of nList) {
          if (item.approvalStatus === 1 || item.approvalStatus === null) {
            item.isShowBtn = true;
            break;
          }
        }
        setNodeList(nList.reverse());
        setNeedRemind(result?.value?.showRemind ?? false);
      } else {
        message.error(result.msg);
      }
    };
    getData();
  };
  useEffect(() => {
    nodeListFn();
  }, []);
  const stateFunc = (status: number) => {
    switch (status) {
      case null:
      case undefined:
      case 1:
        return  TLT(`审批中`);
      case -1:
      case 2:
        return TLT(`审批完成`);
      case 3:
        return TLT(`审批驳回`);
      case 4:
        return TLT(`中断`);
    }
  };
  const stateCol = (approvalStatus: number) => {
    switch (approvalStatus) {
      case null:
      case 1:
        return 'nor';
      case -1:
      case 2:
        return 'suc';
      case 3:
      case 4:
        return 'rej';
      default:
        return 'nor';
    }
  };

  const remindFunc = async () => {
    // const result: any = await remindApi({
    //   orderId: jdOrderId,
    // });
    // if (result.success == true) {
    //   message.success('已提醒审批账号审单！');
    // } else {
    //   message.error(result.msg);
    // }
  };
  return (
    <div className={styles.approvalModal}>
      <h3 className={styles.title}>{TLT(`审批进度`)}</h3>
      <ul className={styles.approvalList}>
        {nodeList?.map((item: any, index: number) => {
          return (
            <li
              key={index}
              className={[
                index > 1 && !isModel ? styles.hide : '',
                stateCol(item) && styles[stateCol(item.approvalStatus)],
              ].join(' ')}
            >
              <span className={styles.state}>{stateFunc(item.approvalStatus)}</span>
              <div className={styles.detail}>
                <i className={styles.nodeIcon}>
                  <i className={styles.icon}></i>
                  {index + 1 != nodeList.length && <i className={styles.b}></i>}
                  {index == 1 && !isModel && <i className={styles.bg_opacity}></i>}
                </i>
                <div className={styles.boxs}>
                  <div className={styles.top}>
                    <p>
                      <em className={styles.level}>{item.nodeName}</em>
                      <em className={styles.name}>{item.currentApprovePin}</em>
                      {item.dealTime && (
                        <em className={styles.date}>{moment(item.dealTime).format('YYYY-MM-DD HH:mm:ss')}</em>
                      )}
                    </p>
                  </div>
                  {/*{pin == orderPin && isModel && needRemind && item.isShowBtn && (*/}
                  {/*  <span className={`${styles.remind} ${styles.s}`} onClick={remindFunc}>*/}
                  {/*    提醒审单*/}
                  {/*  </span>*/}
                  {/*)}*/}
                  {/*{item.reason && (item.approvalStatus == 2 || item.approvalStatus == 3) && (*/}
                  {/*  <p className={styles.suggest}>意见：{item.reason}</p>*/}
                  {/*)}*/}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ApproveList;
