import ja from "./ja";

const getEnvironment = () => {
  let isDev = true;
  if (window.location.hostname === "buy.jdindustry.com" && !window.location.port) {
    isDev = false;
  }
  return isDev;
};

/**
 * 子午线埋点依赖，pin入参名称可自定义
 */
const onloadJAScript = (pin = ""): void => {
  const isDev = getEnvironment();
  const account: string = isDev ? "" : "JA2022_1114210";
  const script: HTMLScriptElement = document.createElement("script");
  script.type = "text/javascript";
  script.src = "//wl.jd.com/joya.js";
  script.async = true;
  if (!(window as any).jaq) {
    (window as any).jaq = [
      ["account", account],
      ["domain", "jdindustry.com"],
      ["account_type", "erp_pin"], // 登陆账号类型，pin: 取自cookie里pin，erp: 用户传入的erp账号，默认erp_pin：有传erp取erp,没传则取cookie pin。
      ["erp_account", pin], // 设置登录帐号
    ];
  }
  document.body.appendChild(script);
};

/**
 * 将参数对象转换为参数字符串
 * @param paramObj
 * @returns {string}
 * @private
 */
const _getParamStr = (paramObj: Record<string, any>): string => {
  const params: string[] = [];
  for (const key in paramObj) {
    params.push(`${key}=${paramObj[key] || "NULL"}`);
  }
  return params.join(",");
};

/**
 * 获取曝光参数
 * @param eventParam
 * @param ext
 * @returns {string}
 * @private
 */
const _getExpParam = (
  eventParam: Record<string, any>,
  ext: Record<string, any>
): Record<string, any> => {
  const result: Record<string, any> = {};
  result["event_param"] = eventParam;
  if (ext) {
    for (const k in ext) {
      result[k] = decodeURIComponent(ext[k]);
    }
  }
  return result;
};

/**
 * 获取超时promise
 * @param timeout
 * @returns {Promise<unknown>}
 * @private
 */
const _getTimeoutPromise = (timeout = 200): Promise<unknown> => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

/**
 * 曝光上报（通用）
 * @param pageId
 * @param eventId
 * @param eventParam
 * @param ext
 * @returns {Promise<unknown>}
 */
const expReport = (
  pageId: string,
  eventId: string,
  eventParam: Record<string, any>,
  ext: Record<string, any>
): Promise<unknown> => {
  const actionPromise: Promise<unknown> = new Promise(
    (resolve: any, reject) => {
      ja.getExpLogJSON().then((expLogJSON: any) => {
        try {
          if (eventParam) {
            let paramStr: any;
            if (eventParam.needJSON) {
              delete eventParam.needJSON;
              paramStr = JSON.stringify(eventParam);
            } else {
              paramStr = _getParamStr(eventParam);
            }
            if (ext) {
              const expParam = _getExpParam(paramStr, ext);
              expLogJSON(pageId, eventId, expParam);
            } else {
              expLogJSON(pageId, eventId, paramStr);
            }
          } else {
            expLogJSON(pageId, eventId);
          }
          resolve();
        } catch (e) {
          console.error(
            `[pageId:${pageId}, [eventId:${eventId}, eventParam:${eventParam}]执行曝光上报失败`
          );
          reject(e);
        }
      });
    }
  );

  const timeoutPromise: Promise<unknown> = _getTimeoutPromise();
  return Promise.race([actionPromise, timeoutPromise]);
};

/**
 * 日志上报
 * @param pageId
 * @param eventId
 * @param eventParam
 * @returns {Promise<unknown>}
 */
const logReport = (
  pageId: string,
  eventId: string,
  eventParam: Record<string, any>
): Promise<unknown> => {
  const actionPromise: Promise<unknown> = new Promise(
    (resolve: any, reject) => {
      ja.getLogJSON().then((logJSON: any) => {
        try {
          logJSON(pageId, eventId, JSON.stringify(eventParam));
          resolve();
        } catch (e) {
          console.error(
            `[pageId:${pageId}, [eventId:${eventId}, eventParam:${eventParam}]执行日志上报失败`
          );
          reject(e);
        }
      });
    }
  );

  const timeoutPromise: Promise<unknown> = _getTimeoutPromise();
  return Promise.race([actionPromise, timeoutPromise]);
};

/**
 * 点击上报（自定义）
 * @param pageId
 * @param eventId
 * @param eventParam
 * @param ext
 * @returns {Promise<unknown>}
 */
const customClickReport = (
  pageId: string,
  eventId: string,
  eventParam?: Record<string, any>,
  ext?: Record<string, any>
): Promise<unknown> => {
  const actionPromise: Promise<unknown> = new Promise(
    (resolve: any, reject) => {
      ja.getLog().then((log: any) => {
        try {
          if (eventParam) {
            let paramStr: any;
            if (eventParam.needJSON) {
              delete eventParam.needJSON;
              paramStr = JSON.stringify(eventParam);
            } else {
              paramStr = _getParamStr(eventParam);
            }
            if (ext) {
              const expParam = _getExpParam(paramStr, ext);
              log(pageId, eventId, expParam);
            } else {
              log(pageId, eventId, paramStr);
            }
          } else {
            log(pageId, eventId);
          }
          resolve();
        } catch (e) {
          console.error(
            `[pageId:${pageId}, [eventId:${eventId}, eventParam:${eventParam}]执行自定义点击上报失败`
          );
          reject(e);
        }
      });
    }
  );

  const timeoutPromise: Promise<unknown> = _getTimeoutPromise();
  return Promise.race([actionPromise, timeoutPromise]);
};

export default {
  onloadJAScript,
  expReport,
  logReport,
  customClickReport,
};
