/*
 * @Author: duchongchong1
 * @Date: 2022-05-30 17:56:52
 * @LastEditors: duchongchong1
 * @LastEditTime: 2023-08-21 17:03:49
 * @FilePath: /isp_International/src/components/NewSearch/history/index.tsx
 * @Description:
 */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import style from './index.module.scss';
import icon_remove from '@/assets/images/search/icon_remove.png';
import icon_trash_can from '@/assets/images/search/icon_trash_can.png';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
export interface IAppProps {
  historyList: any;
  searchHistoryFnEvent: Function;
  handleSceneSearch: Function;
  downIndex: number;
  width: number;
  isScene?: any;

  openWinPage: (v: string) => void;
}
const History: React.FC<IAppProps> = props => {
  const [showClose, setShowClose] = useState<number | null>(null);
  const { t } = useTranslation();
  // const history = useHistory();
  const { historyList, searchHistoryFnEvent, downIndex, width, openWinPage } = props;
  //  删除历史记录
  const delSearchHistoryFn = (num: number, data: object) => {
    searchHistoryFnEvent(num, data);
  };
  // const openWinPage = (inputVal: any) => {
  //   history.push({
  //     pathname: '/page/list',
  //     query: inputVal,
  //   } as any);
  // };
  return (
    <div className={`${style.conent}`}>
      <div className={`${style.hotHistoryList}`} style={{ width: width + 'px' }}>
        <div className={`${style.hotHistoryList_box}`}>
          <div className={`${style.search_item} ${style.search_bt}`}>{TLT(`历史搜索`)}</div>
          <div
            className={`${style.search_count} ${style.search_clos_all}`}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              delSearchHistoryFn(-1, { delAll: 1, word: '' });
            }}
          >
            {historyList && historyList.length > 0 && historyList[0] != '' ? (
              <span>
                <img src={icon_trash_can} />
                {TLT(`清除记录`)}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
        <ul className={`${style.historyList}`}>
          {historyList.length ? (
            historyList.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className={`${downIndex == index ? style.active : ''}`}
                  onClick={() => {
                    openWinPage(item);
                  }}
                  onMouseEnter={() => {
                    setShowClose(index);
                  }}
                  onMouseLeave={() => {
                    setShowClose(null);
                  }}
                >
                  <div>{item}</div>
                  {showClose === index && (
                    <div
                      className={`${style.search_count}`}
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        delSearchHistoryFn(index, { delAll: 0, word: item });
                      }}
                    >
                      <img src={icon_remove} alt="" />
                      {/* <CloseOutlined style={{ width: '12px', height: '12px' }} /> */}
                    </div>
                  )}
                </li>
              );
            })
          ) : (
            <div className={`${style.noList}`}>{TLT(`暂无相关记录`)}</div>
          )}
        </ul>
      </div>
    </div>
  );
};
export default History;
