/**
 * 检查是否支持 webp 格式
 * @description 借助 canvas 并配合本地存储检查 webp 支持情况。
 *
 * @returns {boolean} 返回是否支持webp
 * @example
 *
 * const isSupport = getWebpSupport() // 返回 true 或者 false
 */

function getWebpSupport() {
  const WEBP_NAME = 'o2_webpSupport'; // 检查缓存

  const storage = localStorage;
  const isSupport = storage.getItem(WEBP_NAME);

  if (isSupport === 'true') {
    return true;
  } // 通过 canvas 来检查

  const support =
    document
      .createElement('canvas')
      .toDataURL('image/webp')
      .indexOf('data:image/webp') === 0; // 记录在本地

  storage.setItem(WEBP_NAME, support ? 'true' : 'false'); // 返回结果

  return support;
}

const webpSupport = getWebpSupport();
/**
 * 图片统一处理函数
 * @description 统一图片路径域名，以及裁剪图片宽高
 * @param {string} url - [必填]图片地址
 * @param {number} width - [必填]裁剪宽度
 * @param {number} [height] - 裁剪高度，默认为裁剪宽度大小
 *
 * @returns {string} 处理后的图片地址
 * @example
 *
 * const cover = 'http://img10.360buyimg.com/ling/jfs/t1/11801/14/12271/78918/5c8f620dE35b2ee1a/6ae92ca018000696.png'
 * const img = getImg(cover, 100, 50) // http://img10.360buyimg.com/ling/jfs/t1/11801/14/12271/78918/5c8f620dE35b2ee1a/6ae92ca018000696.png!cc_100x50.webp
 */

export default function getImg(url: string, width: number, height?: number) {
  if (!height) {
    height = width;
  }
  if (!url) return '';

  if (url.indexOf('data:image') === 0 && url.indexOf('base64') !== -1) {
    return url;
  } // 去除链接中空白字符

  url = url.replace(/\s+/g, ''); // 原始图片地址

  url = url.replace(/!.+$/, ''); // 统一协议头

  url = url.replace(/^(https?:)?\/\//i, 'http://'); // 拼接域名和业务名

  if (!/^http/i.test(url)) {
    url = 'http://img10.360buyimg.com/img/'.concat(url);
  }

  url = url.replace(/^(https?:)?\/\//i, '//');
  const IS_GIF = /\.gif/i.test(url);
  const IS_JFS = /jfs\//.test(url);
  const IS_CDN = /(m|img\d{1,2})\.360buyimg\.com/.test(url);
  const IS_IMG = /\.(jpg|jpeg|png|webp)/.test(url);
  const IS_WEBP = /\.webp/.test(url); // Gif 图不做后续处理

  if (IS_GIF) return url; // 非JFS 或 CDN 图片不处理

  if (!IS_JFS || !IS_CDN || !IS_IMG) {
    return url;
  } // m.360buyimg.com 替换

  url = url.replace(/m\.360buyimg\.com/i, 'img10.360buyimg.com'); // 去除图片后缀的多余字符

  const rUrl = url?.match(/(\S*(jpg|jpeg|png|webp|gif))\s*/g);
  if (!rUrl) return url;
  url = rUrl[0]; // 设定宽高

  if (width) {
    url = url.replace(/(\/)(?:s\d+x\d+_)?(jfs\/)/, '$1s'.concat(`${width}`, 'x').concat(`${height}`, '_$2'));
    url += '!cc_'.concat(`${width}`, 'x').concat(`${height}`);
  } // 转webp

  if (webpSupport && !IS_WEBP) {
    url += '.webp';
  }

  const IS_JPG = /\.(jpg|jpeg)/.test(url); // 质量压缩

  if (IS_JPG) {
    url = url.replace(/(!q\d{1,2})?/, '');
  } // 分散域名

  const pool = [10, 11, 12, 13, 14, 20, 30]; // 域名池

  const idx = (parseInt(url.substr(url.lastIndexOf('/') + 1, 8), 36) || 0) % pool.length;
  url = url.replace(/(\/\/img)\d{1,2}(\.360buyimg\.com)/, '$1'.concat(`${pool[idx]}`, '$2'));
  return url;
}

export function updatePathWithParams(url: string, params: { [key: string]: string }): string {
  // 检查输入参数的有效性
  if (typeof url !== 'string' || !url.trim()) {
      console.error('Invalid URL');
  }

  if (typeof params !== 'object' || params === null) {
     console.error('Invalid params object');
  }

  try {
      // 解析 URL
      const parsedUrl = new URL(url);
      const searchParams = new URLSearchParams(parsedUrl.search);

      // 更新 URL 中已有的参数
      for (const [key, value] of Object.entries(params)) {
          if (searchParams.has(key)) {
              searchParams.set(key, value);
          } else {
              searchParams.append(key, value);
          }
      }

      // 构建新的 URL
      parsedUrl.search = searchParams.toString();
      return parsedUrl.toString();
  } catch (error) {
      console.error('Error updating URL with params:', error);
      return url; // 返回原始 URL 以防止程序崩溃
  }
}
