import React, { useEffect, useState } from 'react';
import cookies from 'react-cookies';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import styles from './index.module.scss';
import { getPrice } from '@/util';
import { formatMoney } from '@/util/index';
import iconCopy from '@/assets/images/skuDetail/copy.png';
import iconCopied from '@/assets/images/skuDetail/copied@2x.png';
import Tips from '@/pages/SkuDetailV2/components/Tips';
import { toPercent } from '@/util/tools'
import { useTranslation } from 'react-i18next';

const Index = () => {
  const basicInfo = useRecoilValue('basicInfo');
  const { t, i18n } = useTranslation();
  const [baseMessage, setBaseMessage] = useState<any>(null);
  const [isprice, setIsPrice] = useState<boolean>(true);
  const [isCopy, setCopy] = useState(false);
  const [isLocalPro, setIsLocalPro] = useState(cookies.load('stationType') == '0'); // 1 跨境 0本土
  const skuSaleState = basicInfo?.skuSaleState;

  const getGifts = () => {
    if (basicInfo?.gifts) {
      return Object.values(basicInfo?.gifts)[0];
    } else {
      return 0;
    }
  };



  useEffect(() => {
    setBaseMessage({ key: '商品编码', value: basicInfo?.skuId } || null);
    setIsPrice(basicInfo?.popCreate);
  }, [basicInfo]);

 
  return (
    <div className={styles.m}>
      {skuSaleState && skuSaleState.saleState == 0 && (
        <Tips />
      )}
      {/* 免责声明 */}
      {/* {
        i18n.language === 'en' && (
          <p className={styles.disclaimer}>Disclaimer: The English information of the following products is the result of machine translation, there may be errors in the translation, and only for reference. The specific product information is subject to the Chinese information.</p>
        )
      } */}
      <div className={styles.m_amount} style={{ background: 'rgba(0, 3, 20, 0.03)' }}> 
        <div className={styles.m_amount_message}>
          <div className={styles.amount_box} style={{ alignItems: 'baseline' }}>
            {isprice ? (
              <div className={styles.message_amount} style={{ fontSize: '18px' }}>
                {/*暂无价格*/}
                {TLT(`暂无价格`)}
              </div>
            ) : (
              <>
              <div className={styles.message_amount}>
                {isLocalPro ? 
                  <span>
                      <span className={styles.price_label}>{TLT(`未税价`)}</span>
                      <span className={styles.price_value}>{basicInfo?.showCurrency?.symbol} </span>
                      {basicInfo?.showCurrency?.price && (
                        <>
                          {/*{i18n.language == 'en'*/}

                          {skuSaleState && skuSaleState.saleState == 0 ? (
                            <span style={{ fontSize: '18px' }}>{TLT(`入池后可查`)}</span>
                          ) : (
                              basicInfo?.showCurrency?.price && (
                                <span className={styles.price_value}>
                                  {formatMoney(basicInfo?.showCurrency?.price, false)}
                                </span>
                              )
                          )}
                        </>
                      )}

                      <span className={styles.price_label}>{TLT(`税率`)}</span>
                      <span className={styles.price_value}>{toPercent(basicInfo?.showCurrency?.valueAddedTaxRate || 0)}</span>

                      <span className={styles.price_label}>{TLT(`含税价`)}</span>
                      <span className={styles.price_value}>{basicInfo?.showCurrency?.symbol} </span>
                      {basicInfo?.showCurrency?.salePrice && (
                      <>
                        {/*{i18n.language == 'en'*/}

                        {skuSaleState && skuSaleState.saleState == 0 ? (
                          <span style={{ fontSize: '18px' }}>{TLT(`入池后可查`)}</span>
                        ) : (
                          basicInfo?.showCurrency?.salePrice && (
                            <span className={styles.price_value}>
                             {formatMoney(basicInfo?.showCurrency?.salePrice, false)}
                            </span>
                          )
                        )}
                      </>
                    )}
                  </span> 
                  :
                  <span>
                    <span style={{ fontSize: '18px', fontFamily: '"JDZHENGHEI"' }}>{basicInfo?.showCurrency?.symbol} </span>
                    {basicInfo?.showCurrency?.salePrice && (
                      <>
                        {/*{i18n.language == 'en'*/}

                        {skuSaleState && skuSaleState.saleState == 0 ? (
                          <span style={{ fontSize: '18px' }}>{TLT(`入池后可查`)}</span>
                        ) : (
                          getPrice(basicInfo?.showCurrency?.salePrice).float && (
                            <span>
                              {getPrice(basicInfo?.showCurrency?.salePrice).int}.
                              {getPrice(basicInfo?.showCurrency?.salePrice).float}
                            </span>
                          )
                        )}
                      </>
                    )}
                    {basicInfo?.showCurrency?.valueAddedTaxRate && 
                    <span>
                      <span style={{ fontSize: '12px', fontFamily: '"JDZHENGHEI"',marginLeft: '20px', marginRight: '10px'}}>{TLT(`未税价`)}</span>
                      <span style={{ fontSize: '18px', fontFamily: '"JDZHENGHEI"' }}>{basicInfo?.showCurrency?.symbol} </span>
                      {basicInfo?.showCurrency?.price && (
                        <>
                          {/*{i18n.language == 'en'*/}

                          {skuSaleState && skuSaleState.saleState == 0 ? (
                            <span style={{ fontSize: '18px' }}>{TLT(`入池后可查`)}</span>
                          ) : (
                            getPrice(basicInfo?.showCurrency?.price).float && (
                              <span>
                                {getPrice(basicInfo?.showCurrency?.price).int}.
                                {getPrice(basicInfo?.showCurrency?.price).float}
                              </span>
                            )
                          )}
                        </>
                      )}
                      <span style={{ fontSize: '12px', fontFamily: '"JDZHENGHEI"',marginLeft: '20px', marginRight: '10px'}}>{TLT(`税率`)}</span>
                      <span >{toPercent(basicInfo?.showCurrency?.valueAddedTaxRate)}</span>
                    </span>}
                  </span>}
                  {/**/}
                </div> 
              </>
            )}
          </div>
        </div>
        {baseMessage && (
          <div className={styles.m_amount_message_more}>
            <div className={styles.amount_box}>
              <div className={styles.message_title}>
                {/*商品编码*/}
                {TLT(`商品编码`)}
              </div>
              <div className={styles.message_title}>
                {baseMessage.value}
                {isCopy ? (
                  <img src={iconCopied} style={{ height: '14px', marginLeft: '4px', marginTop: '-2px' }} alt="copied" />
                ) : (
                  <CopyToClipboard
                    style={{ cursor: 'pointer', marginTop: '4px', marginLeft: '4px' }}
                    text={baseMessage.value}
                  >
                    <div
                      onClick={() => {
                        setCopy(true);
                        setTimeout(() => {
                          setCopy(false);
                        }, 1500);
                      }}
                    >
                      <img src={iconCopy} alt="icon" width="14px" height="14px" />
                    </div>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {getGifts() == 0 ? null : (
        <div className={styles.m_gifts} style={{ background: 'rgba(0,3,20,0.06)' }}>
          <div style={{ display: 'flex', marginRight: '30px' }}>
            <div className={styles.m_gifts_box}>
              <p>{TLT(`附件`)}</p>
            </div>
            <img
              src={`https://img30.360buyimg.com/popshop/${getGifts()?.img_dfs_url}`}
              style={{ backgroundColor: 'transparent', border: '1px solid rgba(0,0,0,0.1)', marginRight: '5px' }}
              height="22px"
              alt={TLT(`附件`)}
            />
            <span className={styles.m_gifts_tip}>{getGifts() && getGifts()?.product_name}</span>
            <p style={{ marginRight: '5px', color: '#E00224', padding: '2px' }}>x{getGifts()?.num}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
