/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-02-05 10:06:08
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-11 10:41:23
 * @FilePath: /isp_International/src/pages/OrderInfo/components/TradeType/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import { Card, Radio } from 'antd';
import { useRecoilState, useRecoilValue } from '../../hooks';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const [tradeTypeOption, setTradeType] = useRecoilState('tradeType'); // 设置贸易条款
  const { t } = useTranslation();
  
  return (
    <div className={styles.m}>
    <Card bordered={false} title={TLT(`贸易条款`)}>
    <div className={styles.m_box}>
    <Radio.Group options={tradeTypeOption.option} onChange={(e)=>{
      setTradeType({
        ...tradeTypeOption,
        selected:e.target.value + ''
      });
    }} value={tradeTypeOption.selected}>
      </Radio.Group>
    </div>
    </Card>
    </div>
  );
};

export default Index;
