/*
 * @Author: your name
 * @Date: 2021-11-18 16:06:41
 * @LastEditTime: 2024-08-13 15:22:33
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_International/src/components/NewSearch/index.tsx
 */
import React, { useEffect, useState } from 'react';
import styled from './index.module.scss';
import data from './setting.json';
import Logo from './logo';
import Search from './search';
import { useSelector } from 'react-redux';
import { getHotCate } from '@/api/newApi/home';
import { message } from 'antd';
import cookies from 'react-cookies';
import { useTranslation, withTranslation, WithTranslationProps } from 'react-i18next';
import getImg from '@/util/getImg';
import { useHistory } from 'react-router-dom';
import { addSearchHistory, isSkuId } from '@/api/newApi/search';
interface IAppProps {
  width?: any;
  height?: any;
}
const NewSearch: React.FC<IAppProps> = (props: IAppProps & WithTranslationProps) => {
  const { width, height } = props;
  const [isLocation, setIsLocation] = useState(cookies.load('stationType')); //是否本土 0 本土
  const { func, style, bannerImg } = data;
  const { is_search } = func;
  const history = useHistory();
  const { t } = useTranslation();

  // 精选列表
  const [hotCate, setHotCate] = useState<
    {
      categoryName: string;
      httpUrl: string;
      imgUrl: string;
    }[]
  >([]);
  // 背景图片尺寸
  const [imageSize, setImageSize] = useState({
    w: 0,
    h: 0,
  });
  // 当前登录信息
  const userInfo: any = useSelector((state: any) => {
    return state?.User?.data[0];
  });
  //是否是IE白名单
  const clientOpenWindow: any = useSelector((state: any) => {
    return state?.User?.data[0]?.clientOpenWindow;
  });
  //以上方法中 mode就是加密模式，padding是填充。
  useEffect(() => {
    const bom: any = [];
    window.localStorage.setItem('__userLocalStorageBom', JSON.stringify(bom));
    return () => {
      return;
    };
  }, [userInfo]);
  // 初始化背景大小
  useEffect(() => {
    const img = new Image();
    img.src = bannerImg.large;
    img.onload = () => {
      setImageSize({
        w: img.width,
        h: img.height,
      });
    };
  }, []);
  /**
   * 获取精选
   */
  const fetchData = async () => {
    const hotCate: any = (await getHotCate()) as any;
    if (hotCate.success) {
      setHotCate(hotCate.value.splice(0, 14));
    } else {
      message.error(hotCate.msg);
    }
  };
  // 跳转
  const handleClick = async (item: any) => {
    await addSearchHistory(item.categoryName);
    const result = (await isSkuId(item.categoryName)) as any;
    if (result.success && result.value) {
      window.location.href = `${window.location.origin}/sku/${result.value}`;
      return;
    }
    const path = {
      pathname: '/page/list',
      cat: item.catId,
      cataIdList: item.catId + '',
      query: item.categoryName || '',
    };
    history.push(path);
  };
  /**
   * 初始化获取精选
   */
  useEffect(() => {
    fetchData();
    return () => {
      return;
    };
  }, [props.i18n?.language]);
  /**
   * 计算首页背景大小
   */
  const calculatedBgImgShow = () => {
    const wProportion = width / imageSize.w; // 100%
    if (imageSize.h * wProportion > height - 184) {
      return {
        width: '100%',
      };
    }
    return {
      height: '100%',
    };
  };
  return (
    <>
      <div className={styled.banner_img} style={{ height: `${height - 184 < 750 ? 750 : height - 184}px` }}>
        <img src={getImg(`${bannerImg.large}`, imageSize.w, imageSize.h)} style={calculatedBgImgShow()} />
      </div>
      <div className={`${styled.banner} `} style={{ minHeight: `${height - 184 < 750 ? 750 : height - 184}px` }}>
        <div
          className={styled.scroll}
          style={{
            padding: `${height < 874 ? '10px' : `${(height - 184 - 706) / 2}px`} 0`,
          }}
        >
          <div
            className={`${styled.main_1}`}
            style={{
              marginLeft: `${(width - 1200) / 2}px`,
              // top: height < 874 ? '10px' : `${(height - 184 - 706) / 2}px`,
            }}
          >
            <div className={`${styled.con_logo}`}>
              <Logo clientOpenWindow={clientOpenWindow} {...props} />
            </div>
            {is_search ? (
              <div className={`${styled.con_search}`}>
                <Search clientOpenWindow={clientOpenWindow} {...func} {...style} {...props}></Search>
              </div>
            ) : (
              ''
            )}
           
            <div className={ isLocation != '0' ? styled.main_list : [styled.main_list, styled.local_width].join(' ')}>
           { isLocation != '0' && <div className={styled.hot_tit}>{TLT(`热门类目`)}</div>}
              <ul>
                {hotCate.map((item, index) => {
                  return (
                    <li key={index}>
                      <span
                        onClick={() => {
                          handleClick(item);
                          log('jdisp', 'HomePage_Rec' + index + 1);
                        }}
                      >
                        <img src={item?.imgUrl} alt="" />
                        <p>{item?.categoryName}</p>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <span className={`${styled.clr}`}></span>
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(NewSearch);
