/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-04-15 18:04:25
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-22 20:07:53
 * @FilePath: /isp_International/src/pages/Approval/Create/floor/info/detail.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import moment from 'moment';

import { PROCESS_TYPE } from '../../constants/index';
import styles from '../../index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  stateApproval?: any;
}

const FloorInfoDetail: React.FC<IProps> = (props: any) => {
  const { stateApproval } = props;
  const { t } = useTranslation();


  return (
    <div className={styles.info}>
      <div className={styles.infoTitle}>{TLT(`审批流基本信息`)}</div>
      <div className={styles.infoItems}>
        <div className={styles.infoItem}>
          <span className={styles.name}>{TLT(`审批流名称:`)} </span>
          <span className={styles.content}>{stateApproval?.processName}</span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.small}>{TLT(`创建人:`)}</span>
          <span className={styles.content}>{stateApproval.applyPin}</span>
        </div>
      </div>
      <div className={styles.infoItems}>
        <div className={styles.infoItem}>
          <span className={styles.name}>{TLT(`审批流类型:`)}</span>
          <span className={styles.content}>
            {stateApproval.processType === PROCESS_TYPE.noCondition ? TLT(`无条件限制`) :  TLT(`有条件限制`)}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.small}>{TLT(`修改时间:`)}</span>
          <span className={styles.content}>{moment(stateApproval?.modified).format('YYYY-MM-DD HH:mm:ss')}</span>
        </div>
      </div>
    </div>
  );
};

export default FloorInfoDetail;
