import React from 'react';
import { Button, Checkbox, message } from 'antd';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import styles from './index.module.scss';
import iconCopy from '@/assets/images/orderList/icon_copy.svg';
import { ReactComponent as IconPrint } from '@/assets/images/orderList/icon_print1.svg';
import { ReactComponent as IconDelete2 } from '@/assets/images/orderList/icon_delete2.svg';
import iconInfo from '@/assets/images/orderList/icon_info.svg';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { OrderStatus, orderStatus, print, orderTime } from '@/components/Order';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UserType } from '../../components/SearchForm';
import { useTranslation } from 'react-i18next';

const OrderTitle: React.FC<{
  order: any;
  type?: 'parent' | 'child';
  isAudit: boolean;
  isDelete: boolean;
  isVsp: boolean;
  auditQueryType: string;
  checked: boolean;
  onCheckOrder: (e: CheckboxChangeEvent, order: any) => void;
  onRemoveOrder: React.MouseEventHandler<any>;
  isConfirm: boolean;
  orderConfirm: () => void;
}> = ({
  order,
  type,
  isAudit,
  isDelete,
  isVsp,
  checked,
  auditQueryType,
  onCheckOrder,
  onRemoveOrder,
  isConfirm,
  orderConfirm,
}) => {
  const { t } = useTranslation();
  const status = orderStatus(order);
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;

  const userPin: string = currentUser.pin;

  return (
    <div
      className={styles.borderTop}
      style={{
        backgroundColor: type === 'child' ? '#fff' : 'rgba(0,3,20,0.03)',
        padding: '9px 18px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', color: 'rgba(0,3,20,0.45)' }}>
        <div style={{ width: '14px' }}>
          <Checkbox clstag="h|keycount|myorder|15" checked={checked} onChange={e => onCheckOrder(e, order)} />
        </div>
        <div style={{ marginLeft: '15px' }}>{moment(orderTime(order)).format('YYYY-MM-DD HH:mm:ss')}</div>
        <div style={{ marginLeft: '20px' }}>
          {TLT(`订单编号`)}：{type === 'parent' ? order.parentJdOrderId : order.jdOrderId}
        </div>
        <CopyToClipboard
          text={type === 'parent' ? order.parentJdOrderId : order.jdOrderId}
          onCopy={() => {
            message.success(TLT(`已复制`));
          }}
        >
          <span style={{ marginLeft: '10px', cursor: 'pointer', color: '#1938ED' }}>{TLT(`复制`)}</span>
        </CopyToClipboard>
        {userPin !== order.pin && (
          <div style={{ marginLeft: '20px' }}>
            {' '}
            {TLT(`采购账号`)}：{order.pin || ''}
          </div>
        )}
      </div>
      {type !== 'child' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconPrint
            className={styles.link}
            style={{
              marginLeft: '10px',
              cursor: 'pointer',
              width: '16px',
              height: '16px',
              color: '#999',
              fill: 'currentcolor',
            }}
            onClick={() => {
              print(order.jdOrderId, '1', t);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default OrderTitle;
