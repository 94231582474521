const prefix = '_wisp_';

export enum LocalNameEnum {
  langRemark = 'lang-remark',
  mulLangLocalTag = 'mul-lang-local-tag',
  mulLangLocalTagRemark = 'mul-lang-local-tag-remark',
}

export function setLocal(name: string, value: any) {
    const valueToStore = typeof value !== 'object' ? value : JSON.stringify(value);
    localStorage.setItem(prefix + name, valueToStore);
}

export function getLocal(name: string): any {
    const storedValue = localStorage.getItem(prefix + name);
    try {
        return JSON.parse(storedValue as string);
    } catch (e) {
        return storedValue;
    }
}

export function removeLocal(name: string) {
    localStorage.removeItem(prefix + name);
}
