import React from 'react';
import { Card } from 'antd';

import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import Goods from './Goods';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from '../../hooks';

const Index = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [shipment, setShipment] = useRecoilState('shipment');


  const title = (
    <>
      <div className={styles.title}>
        <span>{TLT(`商品清单`)}</span>
        <span
          onClick={() => {
            history.push({
              pathname: '/cart/carts',
            });
            log('jdisp', 'Settlement_BacktoCart');
          }}
        >
          {TLT(`返回修改采购清单`)}
        </span>
      </div>
    </>
  );

  return (
    <div className={styles.m}>
      <Card bordered={false} title={title}>
        <div className={styles.m_box}>
          <Goods />
          { 
            shipment?.productList && shipment?.productList.some((ele)=>ele?.sourceCountryCode == 'CN') && <div style={{
              color: "rgba(0, 0, 0, 0.85)",
              fontSize: "12px",
              marginTop:"10px"
            }}>{TLT(`跨境商品，货期和报关方式与境内贸易商品不同，敬请留意。如有疑问，请随时联系我们的运营经理`)}</div>
          }
        </div>
      </Card>
    </div>
  );
};

export default Index;
