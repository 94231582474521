import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Modal, message } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import styles from '../../index.module.scss';
import iconArrowRight from '@/assets/images/orderList/icon_arrow_right.svg';
import iconInfo from '@/assets/images/orderList/icon_info.svg';
import iconAudit from '@/assets/images/orderList/icon_audit.svg';
import { formatMoney, cnyMoney } from '@/util';
import Goods from '../Goods';
import OrderActions from '../OrderActions';
import { RightOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import cookies from 'react-cookies';
import DeliveryGreen from '@/assets/images/orderList/delivery_green.png';
import DeliveryOrange from '@/assets/images/orderList/delivery_orange.png';
import DeliveryBlack from '@/assets/images/orderList/delivery_black.png';

import {
  OrderStatus,
  orderStatus,
  AuditStatus,
  paymentType,
  restore,
  CancelModal,
  ApproveModal,
  RejectModal,
} from '@/components/Order';
import { pick } from 'lodash';
import { getAfterSaleGoods, getServiceUrl } from '@/api/orderList/api';
import ApproveProcess from '@/components/ApproveProcess';
import { UserType } from '../../components/SearchForm';
import center from '@/components/Header/center';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { queryDeliveryApi } from '@/api/newApi/order';
import OrderLogisticsTrajectory from '@/pages/OrderDetailNew/components/OrderLogisticsTrajectory';

const OrderItem: React.FC<{
  order: any;
  isAudit: boolean;
  isDelete: boolean;
  isVsp: boolean;
  trackInfoList:any,
  auditQueryType: string;
  onCancel: () => void;
  onApprove: () => void;
  onReject: () => void;
  onRestore: () => void;
  isConfirm: boolean;
  orderConfirm: (orderId: any) => void;
  hoverCallback: (orderId: any) => void; //触摸到货时间回调
}> = ({
  order,
  isAudit,
  isDelete,
  isVsp,
  trackInfoList,
  auditQueryType,
  onCancel,
  onApprove,
  onReject,
  onRestore,
  isConfirm,
  orderConfirm,
  hoverCallback,
}) => {
  const { t } = useTranslation();
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [auditModalVisible, setAuditModalVisible] = useState<boolean>(false);
  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [afterSaleModalVisible, setAfterSaleModalVisible] = useState<boolean>(false);
  const [afterSaleGoodsList, setAfterSaleGoodsList] = useState<Array<object>>([]);
  const [selectedOrders, setSelectedOrders] = useState<Array<{ jdOrderId: string; processId: string; pin: string }>>(
    [],
  );
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土

  let orderExtInfo: { timeRuleVersion?: string } = {}
  try {
    orderExtInfo = JSON.parse(order?.orderExtInfo)
  } catch (error) {
    console.log('parseError', error)
  }
  const history = useHistory();

  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;
  const userPin: string = currentUser.pin;

  const orderConsignee = order.consignee;

  const OrderStatusEnum: Record<number, string> = {
    1: "已提交",
    2: "待确认",
    3: "审批中",
    4: "下单成功",
    5: "已完成",
    80: "已发货",
    90: "已收货",
    95: "已确认收货",
    99: "已取消"
  }

  const receiver = (
    <div>
      <div className={styles.receiver_title}>{order.consignee + ' ' + order.deliveryPhone}</div>
      <div className={styles.receiver_title} style={{ marginTop: '10px' }}>
        {order.deliveryCountry || ''}
      </div>
      <div className={styles.receiver_detail} style={{ marginTop: '5px' }}>
        {TLT(`详细地址`)}-{order.deliveryAddress}
      </div>
    </div>
  );

  const cancelModal = (
    <CancelModal
      order={order}
      visible={cancelModalVisible}
      onCancel={() => setCancelModalVisible(false)}
      onOK={() => {
        setCancelModalVisible(false);
        onCancel();
      }}
    />
  );

  const afterSaleModal = (
    <Modal
      width={620}
      title="申请售后"
      visible={afterSaleModalVisible}
      footer={null}
      onCancel={() => setAfterSaleModalVisible(false)}
      className={styles.buyAccountModal}
    >
      <div style={{ color: '#666' }}>选择需要售后的商品</div>
      <div
        style={{
          overflowY: 'auto',
          maxHeight: '520px',
          marginTop: '20px',
        }}
      >
        {afterSaleGoodsList.map((g: any) => (
          <Row
            key={g.skuId}
            className={styles.borderBottom}
            style={{
              padding: '15px 0',
              fontSize: '12px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Col className={[styles.center].join(' ')} style={{ alignItems: 'start', width: '230px' }}>
              <img src={`https://m.360buyimg.com/da/s150x150_${g.imgUrl}`} style={{ width: '40px', height: '40px' }} />
              <span className={styles.goodsName} style={{ marginLeft: '10px' }}>
                {g.name}
              </span>
            </Col>
            <Col className={[styles.center].join(' ')}>
              <span style={{ fontWeight: 'bold' }}>
                <span className={styles.blue}>{cnyMoney(g.price)}</span> x {g.num}
                {g?.extAttr?.x ?? '件'}
              </span>
            </Col>
            <Col>
              <Button
                type="primary"
                ghost
                disabled={!g.canApply}
                onClick={() => history.push(`/application?orderId=${order.jdOrderId}&skuId=${g.skuId}`)}
              >
                申请售后
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    </Modal>
  );
  const approveModal = (
    <ApproveModal
      orders={selectedOrders}
      visible={approveModalVisible}
      onCancel={() => setApproveModalVisible(false)}
      onOK={() => {
        setApproveModalVisible(false);
        onApprove();
      }}
    />
  );

  const rejectModal = (
    <RejectModal
      orders={selectedOrders}
      visible={rejectModalVisible}
      onCancel={() => setRejectModalVisible(false)}
      onOK={() => {
        setRejectModalVisible(false);
        onReject();
      }}
    />
  );
  const handleWarning = () => {
    switch (order.orderStatus) {
      case 1:
        message.warning(TLT(`订单将产生履约服务费，您的专属客户经理会第一时间进行订单审核并反馈报价。`));
        break;
      case 2:
        message.warning(TLT(`订单的履约服务费已完成报价，请查看订单详情进行确认。`));
        break;
    }
  };

  /**
   * 判断在当预计配送时间前还是后 0预计XX到达 1今天到达 2已过配送时间
  */
  const getJuddgeTime = () => {
    const momentToday = moment();
    const startTime = moment(order?.expectedDeliveryDate).startOf('day');//配送当天开始
    const endTime = moment(order?.expectedDeliveryDate).endOf('day'); //配送当天结束
    return momentToday.isBefore(startTime) ? 0 : momentToday.isAfter(endTime) ? 2 : 1;
  }

  const deliveryTimeStatus = getJuddgeTime();

  return (
    <Row  className={styles.borderBottom}>
      <Col className={styles.borderRight}>
        {order.waresReqs && (!order?.expanded ? order.waresReqs.slice(0, 2): order.waresReqs).map((g: any, index: number) => (
          <Goods key={index} goods={g} />
        ))}
        {/*{order.waresReqs.length > 5 && moreGoods}*/}
      </Col>
      {/* {isLocalPro && <Col className={[styles.title8, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}>{order?.customsClearance == 1 ? TLT(`是`) : order?.customsClearance == 0 ? TLT(`否`) : '-'}</Col>} */}
      <Col className={[styles.title4, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}>
        <Row style={{ alignItems: 'center' }}>
          <Tooltip title={receiver} placement="bottom">
            <span style={{ marginRight: '5px', wordBreak: 'break-all' ,textOverflow:'ellipsis',display:'-webkit-box',WebkitLineClamp:'3',WebkitBoxOrient:'vertical',overflowY:'hidden'}}>{orderConsignee}</span>
          </Tooltip>
        </Row>
      </Col>
      <Col className={[styles.title5, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}>
        <div className={[styles.black, styles.normal].join(' ')}>
          {order.symbol + cnyMoney(order.totalPrice || order.waresTotalPrice, false)}
        </div>
        {/*<div style={{ marginTop: '10px' }}>{paymentType(order)}</div>*/}
      </Col>
      <Col
        className={[styles.title6, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}
        style={{ flexDirection: 'column' }}
      >
        <Row
          style={{ wordBreak: 'break-all' }}
          className={
            order.orderStatus == 3 || order.orderStatus == 2
              ? styles.orange
              : order.orderStatus == 4 || order.orderStatus == 80
              ? styles.green
              : styles.black
          }
        >
          <span>
            <span
              style={{ cursor: order.orderStatus == '3' ? 'pointer' : 'default' }}
              // onClick={e => {
              //   if (order.orderStatus == '3') {
              //     e.stopPropagation();
              //     setAuditModalVisible(true);
              //   }
              // }}
            >
              {TLT(OrderStatusEnum[order.orderStatus])}
            </span>
            {order.orderStatus == 1 && (
              <InfoCircleOutlined
                title={TLT(`订单将产生履约服务费，您的专属客户经理会第一时间进行订单审核并反馈报价。`)}
                style={{ marginLeft: '4px' }}
                // onMouseOver={handleWarning}
              />
            )}
            {order.orderStatus == 2 && (
              <InfoCircleOutlined
                title={TLT(`订单的履约服务费已完成报价，请查看订单详情进行确认。`)}
                style={{ marginLeft: '4px' }}
                // onMouseOver={handleWarning}
              />
            )}
          </span>
        </Row>
        {(order.orderStatus != 2 && order.orderStatus != 1 && order?.approveStatus !== undefined) && (
          <Row style={{ marginTop: '5px', alignItems: 'center', fontWeight: 'normal' }}>
            <span
              onClick={e => {
                e.stopPropagation();
                setAuditModalVisible(true);
              }}
              className={`${styles.titleArrow}`}
              style={{ cursor: 'pointer', color: 'rgba(51,51,51,0.65)' }}
            >
              {TLT(`审批详情`)}
              <i className={`${styles.crumbs_arrow}`}></i>
            </span>

          </Row>
        )}
        {/* 维护了配送日期 */}
        {order?.orderStatus >= 4 &&
          <Tooltip 
            title={<OrderLogisticsTrajectory params={{ orderNo: order?.orderNo, timeRuleVersion: orderExtInfo?.timeRuleVersion}} />} 
            placement='left'
            color='#fff'
            key='tooltip'
            trigger='click'
          >
            <div className={styles.trackingPath}>{ TLT('轨迹跟踪') }</div>
          </Tooltip> 
        }
        
      </Col>
      <Col
        className={[styles.title7, styles.goodsCell, styles.borderTop].join(' ')}
        style={{ flexDirection: 'column' }}
      >
        <OrderActions
          order={order}
          isAudit={isAudit}
          isDelete={isDelete}
          isVsp={isVsp}
          isConfirm={isConfirm}
          orderConfirm={orderConfirm}
          auditQueryType={auditQueryType}
          onCancel={() => {
            setCancelModalVisible(true);
          }}
          refreshList={() => {
            onCancel();
          }}
          onApprove={() => {
            setSelectedOrders([pick(order, ['jdOrderId', 'processId', 'pin'])]);
            setApproveModalVisible(true);
          }}
          onReject={() => {
            setSelectedOrders([pick(order, ['jdOrderId', 'processId', 'pin'])]);
            setRejectModalVisible(true);
          }}
          onRestore={() => {
            restore(order.jdOrderId, () => {
              onRestore();
            });
          }}
          onCountDown={() => onCancel()}
          onReceiptCountDown={() => onCancel()}
        />
      </Col>
      {cancelModal}
      {/*{afterSaleModalVisible && afterSaleModal}*/}
      {/*{approveModal}*/}
      {/*{rejectModal}*/}
      {/*查看审批进度*/}
      <ApproveProcess
        visible={auditModalVisible}
        order={order}
        // type={order.auditStatus == AuditStatus.auditing && userPin === order.pin ? 'approve' : 'seeFile'}
        type={'approve'}
        setAuditModalVisible={setAuditModalVisible}
        isShowApprove={true}
      />
    
    </Row>
  );
};

export default OrderItem;
