/*
 * @Author: weiyanna
 * @Date: 2021-12-08 10:34:08
 * @LastEditTime: 2024-10-25 10:03:33
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/AddressList/index.tsx
 */
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Pagination, message, Modal, Checkbox, Table, Upload, UploadFile, UploadProps, Space, Form, Select } from 'antd';

import { Input } from 'antd';
import { useSelector } from 'react-redux';
import {
  createAddress,
  getAddressDetail,
  modifyAddress,
} from '@/api/newApi/personalCenter/address';
import Goods from '../components/Goods'
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { GetRequest } from '@/util';
import { orderDetail } from '@/api/newApi/orderdetail';
import { afterSaleSubmit, uploadFile } from '@/api/newApi/afterSale';
import { useHistory } from 'react-router-dom';


const AddressList: React.FC<{}> = () => {
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  // 查询条件
  const [searchParams, setSearchParams] = useState<any>({});
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [mkuList, setMkuList] = useState<any>([]);
  const [reason, setReason] = useState<string>('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();
  const urlParams: any = GetRequest();
  const id = urlParams.orderId;
  const mkuId = urlParams.mkuId;
  // 中英文切换
  const { t, i18n } = useTranslation();
  const { TextArea } = Input;
  const history = useHistory();
  const store: any = useSelector(state => {
    return state;
  });
  const orderPin = urlParams.orderPin ? urlParams.orderPin : store?.User?.data[0]?.pin ?? '';
  const isVsp = urlParams.isVsp === '1' || false;
 
  // 初始化调用
  // 监听用户信息变化，解决用户接口响应慢时获取地址列表优先于用户接口调用，导致报错问题
  useEffect(() => {
    if (currentUser.pin) {
      setSearchParams({});
    }
  }, [currentUser.pin]);

  const getOrderDetail = async()=>{
    const result: any = (await orderDetail({ 
        orderId: id, 
        orderPin: orderPin, 
        orderSource: isVsp ? 0 : 1,
        mask:false //收货人信息是否脱敏 
      })) as any;
    if (result.success) {
      // getAddressInfo();
      const order = result.value;
      console.log(order, 'orderDetails')
      order.bizOrder = order.bizOrder || {};
      setOrderDetails(order?.orderRes);
      const mkuData = order?.orderRes?.waresReqs.filter((ele)=>ele.sku == mkuId)
      setMkuList(mkuData || [])
      form.setFieldsValue({
        customerPostcode:"",
        customerEmail:"",
        customerMobilePhone: order?.orderRes?.deliveryPhone && order?.orderRes?.deliveryPhone.split(' ')[1],
        customerTel:"",
        customerContactName: order?.orderRes?.consignee
      })
    } else {
      message.error(result.msg);
    }
  }

  // 当refresh状态为true并且用户信息已有
  // 重置所有条件
  useEffect(() => {
    getOrderDetail();
  }, []);


  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{TLT(`上传`)}</div>
    </div>
  );

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', '23');
    try {
      const res = await uploadFile(formData);

      if (res?.success) {
        const fileLink = res?.value;
        // 更新对应行的数据，将文件链接添加到record对象中
        setFileList([...fileList, ...[{
            uid: '',
            name: file.fileName,
            status: 'done',
            url: fileLink,
          }]
        ]);
        // 文件上传成功
      } else {
        // 文件上传失败
      }
    } catch (error) {
      console.error('文件上传出错:', error);
    }
  };
  
  /**
   * 保存/修改地址
   * @param address 地址详情
   */
  const onSaveAddress = async () => {
    const formValidate = await form?.validateFields();
    const asCustomerDto = form.getFieldsValue(); //联系人信息
    if(!formValidate) return
    const {packageDesc, customerExpect, customerPostcode, customerEmail, customerMobilePhone, customerTel, customerContactName} = asCustomerDto;
    const params = {
      asDetailDto:{
        skuNum: mkuList[0]?.skuNum,
        skuId: urlParams?.mkuId
      },
      thirdAfsServiceId:"",
      questionPic:fileList.map((ele)=>ele.url),
      questionDesc:reason,
      packageDesc,
      customerExpect,
      jdOrderId:id,
      asCustomerDto:{
        customerPostcode, 
        customerEmail, 
        customerMobilePhone, 
        customerTel, 
        customerContactName
      }
    }
    let res: any = '';
    
    res = await afterSaleSubmit({
        ...params,
      });
    if (res.success) {
      message.success(TLT(`申请成功`));
      toBack();
    } else {
      message.error(res.msg);
    }
  };

  const toBack = ()=>{
    history.push('/orderList')
  }
  
  const spanNum = i18n.language == 'zh' ? 6 : 8;
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="ispContainer">
        <div className={styles.index} style={{ width: '1100px' }}>
        <Form layout="horizontal" labelWrap={true} form={form} colon={false} name="control-hooks" className={styles.searchForm} >
          <Card className={styles.con} style={{ padding: '15px 20px' }} title={TLT(`商品清单`)} bordered={false}>
            <div className={styles.shopArea}>
                {/* {mkuList.map((o: any) => (
                    <Item
                      key={o.id}
                      order={o}
                    />
                  ))} */}

                  <Goods mkuList={mkuList} orderDetails={orderDetails}/>
            </div>
          </Card>
          <Card className={styles.con} style={{ padding: '15px 20px' }} title={TLT(`问题描述`)} bordered={false}>
            <div className={styles.shopArea}>
              <Row gutter={24}>
                <Col span={spanNum}>
                    <Form.Item
                        initialValue={10} 
                        key="formItem12" 
                        name="customerExpect" 
                        rules={[{
                          required: true,
                          message:TLT(`请选择问题类型`)
                        }]}
                        label={TLT(`问题类型`)}
                        labelCol={{
                          span:8
                        }}
                      >
                        <Select options={[
                      {
                        value:10,
                        label:TLT(`退货`)
                      },
                      {
                        value:20,
                        label:TLT(`换货`)
                      },
                      {
                        value:30,
                        label:TLT(`维修`)
                      }
                    ]}>
                        </Select>
                  </Form.Item>
                    
                </Col>
                <Col span={spanNum}>
                  <Form.Item 
                        initialValue={0} 
                        rules={[{
                          required: true,
                          message:TLT(`请选择是否有包装`)
                        }]}
                        key="formItem12" 
                        name="packageDesc" 
                        label={TLT(`是否有包装`)}
                        labelCol={{
                          span:8
                        }}
                      >
                    <Select options={[
                      {
                        value:0,
                        label:TLT(`无包装`)
                      },
                      {
                        value:10,
                        label:TLT(`包装完整`)
                      },
                      {
                        value:20,
                        label:TLT(`包装破损`)
                      }
                    ]}>
                    </Select>
                    </Form.Item>
                </Col>
              </Row>
              <Row>
                    <Col span={2}>
                      {TLT(`问题描述`)}
                    </Col>
                    <Col span={22}>
                      <TextArea  className={styles.area} onChange={(e)=>setReason(e.target.value)} placeholder={TLT(`为了更快的完成售后审核,请认真填写售后问题解决。`)}></TextArea>
                    </Col>
              </Row>
            </div>
          </Card>
          <Card className={styles.con} style={{ padding: '15px 20px' }} title={ React.createElement(
          'div',
          {
            className: styles.card_title,
          },
          React.createElement(
            'span',
            {
              className: styles.card_m_title,
            },
            TLT(`图片信息`),
          ),
          React.createElement(
            'span',
            {
              className: styles.card_sub_title,
            },
            TLT('最多可上传{{tag_0}}张图片，每张大小不超过{{tag_1}}M,支持bmp、gif、jpg、png', {
              tag_0: '10',
              tag_1: '5'
            })
          ),
        )  } bordered={false}>
            <div className={styles.shopArea}>
            <>
            <Upload
                accept="image/png,image/gif,image/jpeg,image/jpg"
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                multiple
                listType="picture-card"
                fileList={fileList}
                beforeUpload={(file) => {
                    const isLt50M = file.size / 1024 / 1024 <= 3;
                    if (!isLt50M) {
                      message.error({
                        content: '文件过大 文件必须小于 3MB!',
                      });
                      return false;
                    }
                    handleUpload(file);
                    return false;
                  }}
                // onPreview={handlePreview}
                // onChange={handleChange}
                onRemove={(param) => {
                  const index = fileList.findIndex((ele) => ele.url == param.url);
                  fileList.splice(index, 1);
                  setFileList(JSON.parse(JSON.stringify(fileList)));
                }}
                // showUploadList={false}
            >
                {fileList.length >= 10 ? null : uploadButton}
            </Upload>
     
    </>
            </div>
          </Card>
          <Card className={styles.con} style={{ padding: '15px 20px' }} title={TLT(`售后联系人信息`)} bordered={false}>
            <div className={styles.shopArea}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item 
                            
                              rules={[{
                                required: true,
                                message:TLT(`请输入联系人姓名`)
                              }]}
                              key="formItem12" 
                              name="customerContactName" 
                              label={TLT(`联系人姓名`)} 
                              labelCol={{
                                span:8
                              }}
                            >
                                <Input placeholder={TLT(`请输入`) + TLT(`联系人姓名`)} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item 
                                rules={[{
                                  required: true,
                                  message:TLT(`请输入联系电话`)
                                },{
                                  pattern: /^\+?[1-9]\d{1,14}$/,
                                  message: TLT(`请输正确联系电话`)
                                }]}
                                key="formItem12" 
                                name="customerTel" 
                                label={TLT(`联系电话`)}
                                labelCol={{
                                  span:8
                                }}
                              >
                                <Input placeholder={TLT(`请输入`) + TLT(`联系电话`)} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item 
                              rules={[{
                                required: true,
                                message:TLT(`请输入联系手机`)
                              },
                              {
                                pattern: /\d+/,
                                message:TLT(`请输正确联系手机`),
                              }]
                            }
                              key="formItem12" 
                              name="customerMobilePhone" 
                              label={TLT(`联系手机`)}
                              labelCol={{
                                span:8
                              }}
                            >
                                <Input placeholder={TLT(`请输入`) + TLT(`联系手机`)} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item 
                              rules={[{
                                required: true,
                                message:TLT(`请输入联系人邮箱`)
                              },
                              {
                                pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message:  TLT(`请输正确的邮箱`),
                              }
                            ]}
                              key="formItem12" 
                              name="customerEmail" 
                              label={TLT(`联系人邮箱`)}
                              labelCol={{
                                span:8
                              }}
                            >
                                <Input placeholder={TLT(`请输入`) + TLT(`联系人邮箱`)} />
                            </Form.Item>
                        </Col>
                       
                    </Row>
                    <Row gutter={24}>
                      <Col span={8}>
                          <Form.Item 
                            key="formItem12" 
                            name="customerPostcode" 
                            label={TLT(`联系人邮编码`)}
                            labelCol={{
                              span:8
                            }}
                            >
                              <Input placeholder={TLT(`请输入`) + TLT(`联系人邮编码`)} />
                          </Form.Item>
                        </Col>
                    </Row>
              
            </div>
          </Card>
          <Card className={styles.con} style={{ padding: '15px 20px' }} title="" bordered={false}>
            <div className={styles.shopArea}>
                <Row>
                    <Col span={3} offset={21}>
                        <Space>
                            <Button type="default" onClick={()=> toBack()}>{TLT(`返回`)}</Button>
                            <Button type="primary" onClick={onSaveAddress}>{TLT(`提交`)}</Button>
                        </Space>
                    </Col>
                </Row>
              
            </div>
          </Card>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default AddressList;
