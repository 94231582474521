/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2023-12-04 15:09:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-06-20 12:03:48
 * @FilePath: /isp_International/src/pages/SkuDetailV2/components/Main/Summary/Basic/Panel/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import Amount from './Amount';
import Buy from './Buy';
import Payment from './Payment';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import { getDeliveryDate } from '@api/newApi/skudetail';
import { ExclamationCircleFilled } from '@ant-design/icons';

const Index = () => {
  const basicInfo = useRecoilValue('basicInfo');
  const { t, i18n } = useTranslation();
  const [deliveryDate, setDeliveryDate] = useState(''); // 错误提示

  useEffect(() => {
    setDeliveryDate(basicInfo?.deliveryDate);
  }, [basicInfo?.deliveryDate]);

  const handleChangeDeliveryDate = (params: any) => {
    getDeliveryDate(params).then((res: any) => {
      console.log(params, res);
      if (res?.success) {
        setDeliveryDate(res?.value?.deliveryDate);
      }
    });
  };
  return (
    <div className={styles.m}>
      <div className={styles.stock}>
        {basicInfo?.remainNum === 0 && <div>{TLT(`无货`)}</div>}
        {basicInfo?.remainNum > 0 && basicInfo?.remainNum <= 200 && (
          <div>{TLT(`仅剩XX件`)?.replace('XX', basicInfo?.remainNum)}</div>
        )}
        {basicInfo?.remainNum !== 0 && deliveryDate && (
          <div className={styles.deliveryDate}>
            {deliveryDate}{' '}
            <Tooltip title={TLT('重大不可抗力因素或节假日可能会导致配送延迟')}>
              <ExclamationCircleFilled style={{ color: '#faad14', cursor: 'pointer' }} />
            </Tooltip>
          </div>
        )}
      </div>
      <div className={styles.mt}>
        <div className={styles.mc}>
          <Amount
            changeDeliveryDate={(params: any) => {
              handleChangeDeliveryDate(params);
            }}
          />
          <Buy />
        </div>
        <Payment />
      </div>
    </div>
  );
};

export default Index;
