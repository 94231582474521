/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-05-16 10:58:05
 * @LastEditors: duchongchong1
 * @LastEditTime: 2023-07-27 16:10:21
 * @FilePath: /isp_International/src/pages/SelectList/components/GoodsList/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useState } from 'react';
import { replaceString } from '@/util';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import getImg from '@/util/getImg';
import DefaultBig from '@/assets/images/searchList/default-big.png';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
export interface IAppProps {
  changeGoodData?: any;
  addCarBe?: Function;
  isWideScreen?: boolean;
}
const Index: React.FC<IAppProps> = (props: any) => {
  const { changeGoodData, addCarBe } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.content} style={{ width: '1200px' }}>
      <div className={`${styles.larger_img}`}>
        <div className={`${styles.gl_warp}`} id="gl_warp">
          {changeGoodData.length > 0 &&
            changeGoodData.map((item: any, index: any) => {
              return (
                <div className={`${styles.gl_item}`} key={item.sku || index}>
                  <div className={styles.box} style={{padding:'8px'}}>
                    <img
                        style={{width:'100%',height:'100%'}}
                      src={
                        item?.img != null
                          ? getImg(`https://m.360buyimg.com/da/s452x452_${item?.img}`, 233, 228)
                          : DefaultBig
                      }
                      onClick={() => {
                        setTimeout(() => {
                          // window._openPage('/sku/' + item?.sku, '_self');
                          history.push({
                            pathname: '/sku/' + item?.sku,
                          });
                        }, 100);
                      }}
                    />
                    <div className={styles.box_bg}></div>
                  </div>

                  <div className={`${styles.ent_price}`}>
                    <>
                      <em>$</em>
                      {item?.price}
                    </>
                  </div>

                  <div className={`${styles.title_1}`}>
                    <a title={item?.wareName} onClick={() => window._openPage(`/sku/${item?.sku}`, '_blank')} rel="noreferrer">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceString(item?.wareName, ''),
                        }}
                      ></div>
                    </a>
                  </div>
                  <div className={`${styles.order_Num} ${styles.text_space}`} title={item.sku || ''}>
                    {TLT(`商品编号`)}: {item?.sku || ''}
                  </div>
                  <div className={`${styles.operation}`}>
                    <div
                      className={`${styles.add_shop}`}
                      onClick={() => {
                        addCarBe(item);
                      }}
                    >
                      <i></i>
                      {TLT(`加入采购清单`)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Index;
