/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import styled from './index.module.scss';
import { getNextCategory, getCategoryName } from '@/api/newApi/search';
import cookies from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchAnnounceData } from '@/redux/action';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
export interface IAppProps {
  updataData: Function;
}

const Crumbs: React.FC<IAppProps> = props => {
  const { updataData } = props;
  const [searchVal, setSearchVal] = useState('');
  const [searchValArray, setSearchValArray] = useState([]);
  const [level, setLevel] = useState('');
  const { t, i18n } = useTranslation();
  const [twoCategory, setTwoCategory] = useState([]);
  const [threeCategory, setThreeCategory] = useState([]);
  const [fourCategory, setFourCategory] = useState([]);
  const [categoryNameList, setCategoryNameList] = useState([]);
  const [stationtype, setStationType] = useState(cookies.load('stationType')) // 0 本土站点 1跨境站点
  const history = useHistory();
  const dispatch = useDispatch();
  const red_goods_data: any = useSelector((state: any) => {
    return state.SearchList;
  });
  const { brandRole, params, brandList, selectParams } = red_goods_data;

  const clearAllParams = () => {
    const param = {
      ...params,
      cat: '',
      priceMinFilter: null,
      priceMaxFilter: null,
      extAttrList: [],
      brandRole: [],
    };
    dispatch(dispatchAnnounceData(param, true, []));
  };

  const clearOneParams = (item: any, index: any) => {
    let param = {};
    let selectParam ={};
    let arr: any[] = [];
    // item[0] 筛选条件标题
    if (item[0] == '价格') {
      param = {
        ...params,
        priceMinFilter: null,
        priceMaxFilter: null,
      };
      arr =  selectParams?.selectId
    } else if (item[0] == '品牌') {
      param = {
        ...params,
        brandRole: [],
      };
      arr =  selectParams?.selectId
    } else if (item[0] == '类目') {
      param = {
        ...params,
        cat: '',
      };
      arr =  selectParams?.selectId
    } else {
      selectParams.extAttrList.forEach((el: any, index: any) => {
        if (Object.values(el)[0].name == item[1]) {
          selectParams.extAttrList.splice(index, 1);
          arr =  selectParams.selectId.filter((item: any)=> item != Object.keys(el)[0] )
          params.extAttrList.splice(index, 1);
        }
      });
      param = {
        ...params,
      };
    }
 
    selectParam={
      ...selectParams,
      selectId: arr || []
    }
    dispatch(dispatchAnnounceData(param, false, selectParam));
  };
  const initData = () => {
    const menuArr: any = [];
    // 设置搜索关键词
    setSearchVal(params?.key || '');
    if (params?.brandRole?.length > 0) {
      menuArr.push(['品牌', brandList[0].name]);
    }
    // 价格
    if (params?.priceMinFilter || params?.priceMaxFilter) {
      menuArr.push(['价格', params?.priceMinFilter + ' - ' + params?.priceMaxFilter]);
    }
    // 二级类目
    if (params?.cat) {
      // menuArr.push(['猜你想搜', decodeURIComponent(params?.cat)]);
    }
    // 扩展属性信息
    if (params?.extAttrList && params?.extAttrList.length > 0) {
      params?.extAttrList.forEach(function(item: any) {
        selectParams?.extAttrList.forEach((data: any, index: any) => {
         const val = Object?.keys(item);
          if (Object.keys(data)[0] == item?.[val[0]]) {
            const { key, name } = data[Object.keys(data)[0]];
            menuArr.push([key, name]);
          }
        });
      });
    }
    searchValArray &&
      searchValArray.forEach(item => {
        if (item[0] === '类目') {
          menuArr.push(['类目', item[1]]);
        }
      });

    setSearchValArray(menuArr);
  };

  const getCategory = async (categoryId: any, level: any) => {
    const result: any = await getNextCategory({
      category: categoryId,
    });
    if (result.success) {
      if (level === 2) {
        setTwoCategory(result.value);
      }
      if (level === 3) {
        setThreeCategory(result.value);
      }
      if (level === 4) {
        setFourCategory(result.value)
      }
    }
  };
  const getCategoryNameList = async (cat: any) => {
    // debugger;
    const result: any = await getCategoryName({
      category: cat,
    });
    if (result.success) {
      const values = result.value || [];
      const categoryList: any = [];
      values &&
        values.forEach((value: any) => {
          categoryList.push({
            catId: Object.keys(value)[0],
            catName: Object.values(value)[0],
          });
        });
      setCategoryNameList(categoryList);
    }
  };
  useEffect(() => {
    if (params?.cat) {
      selectParams && getCategoryNameList(selectParams.cataIdList);
      const cates = selectParams?.cataIdList?.split(',');
      if(cates && cates.length > 0 && cates.length === 4){
        getCategory(`${cates[0]}`, 2);
        getCategory(`${cates[0]},${cates[1]}`, 3);
        getCategory(`${cates[0]},${cates[1]},${cates[2]}`, 4);
      }else if (cates && cates.length > 0 && cates.length === 3) {
        getCategory(`${cates[0]}`, 2);
        getCategory(`${cates[0]},${cates[1]}`, 3);
      } else if (cates && cates.length > 0 && cates.length === 2) {
        getCategory(`${cates[0]}`, 2);
      }
    } else {
      setSearchValArray([]);
      setCategoryNameList([]);
    }
  }, [params]);

  useEffect(() => {
    initData();
  }, [red_goods_data.data]);

  const changeCurrentTab = (level: any) => {
    setLevel(level);
  };

  const changeCurrentPage = (item: any, level: any) => {
    const cats = selectParams?.cataIdList.split(',');
    if (Number(level) === 2) {
      cats[1] = item.catId;
      if (cats.length > 2) {
        cats.pop();
      }
    } else {
      if (cats.length > 2) {
        cats.pop();
      }
      cats.push(item.catId);
    }

    const path = {
      pathname: '/page/list',
      cat: item.catId,
      cataIdList: cats.join(','),
      query: item.catNameCn,
    };
    history.replace(path);
    // const param = {
    //   ...params,
    //   pageNo: 1,
    //   cat: item.catId,
    // };
    // const Params = {
    //   ...selectParams,
    //   cataIdList: cats.join(','),
    //   cat: item.catId,
    // };
    // dispatch(dispatchAnnounceData(param, false, Params));
  };

  
  //@ts-ignore
  const oneCategoryName = categoryNameList.length > 0 && categoryNameList[0] ? categoryNameList[0].catName : '';
  //@ts-ignore
  const twoCategoryName = categoryNameList.length > 0 && categoryNameList[1] ? categoryNameList[1].catName : '';
  //@ts-ignore
  const threeCategoryName = categoryNameList.length > 0 && categoryNameList[2] ? categoryNameList[2].catName : '';
  //@ts-ignore
  const fourCategoryName = categoryNameList.length > 0 && categoryNameList[3] ? categoryNameList[3].catName : '';
  console.log(fourCategoryName)
  return (
    <div className={`${styled.crumbs}`}>
      <div className={`${styled.crumbs_nav_main} `}>
        <div className={`${styled.crumbs_nav_item}`}>
          <a
            onClick={() => {
              clearAllParams();
              log('jdisp', 'Search_breadcrumb');
            }}
          >
            {TLT(`全部结果`)}
          </a>
        </div>
        <i className={`${styled.crumbs_arrow}`}></i>

        <div className={`${styled.top_cate}`}>
          <span className={styled.first}>{oneCategoryName}</span>
        </div>
        {twoCategoryName && <i className={`${styled.crumbs_arrow}`}></i>}
        {twoCategoryName && (
          <div className={styled.categories}>
            <div
              className={`${styled.menu_drop} ${Number(level) === 2 ? styled.z_menu_drop_open : ''}`}
              onMouseOver={() => {
                changeCurrentTab(2);
              }}
              onMouseOut={() => {
                changeCurrentTab('');
              }}
            >
              <div className={styled.trigger}>
                <span className={styled.curr}>{twoCategoryName}</span>
                <i className={styled.menu_drop_arrow}></i>
              </div>
              <div className={styled.menu_drop_main}>
                <ul className={styled.menu_drop_list}>
                  {twoCategory &&
                    twoCategory.map((item: any) => {
                      return (
                        <li key={item.catId}>
                          <a
                            title={item.catNameCn}
                            onClick={() => {
                              changeCurrentPage(item, 2);
                            }}
                          >
                            {item.catNameCn}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        )}
        {threeCategoryName && <i className={styled.crumbs_arrow}></i>}
        {threeCategoryName && (
          <div className={styled.categories}>
            <div
              className={`${styled.menu_drop} ${Number(level) === 3 ? styled.z_menu_drop_open : ''}`}
              onMouseOver={() => {
                changeCurrentTab(3);
              }}
              onMouseOut={() => {
                changeCurrentTab('');
              }}
            >
              <div className={styled.trigger}>
                <span className={styled.curr}>{threeCategoryName}</span>
                <i className={styled.menu_drop_arrow}></i>
              </div>
              <div className={styled.menu_drop_main}>
                <ul className={styled.menu_drop_list}>
                  {threeCategory &&
                    threeCategory.map((item: any) => {
                      return (
                        <li key={item.catId}>
                          <a title={item.catNameCn} onClick={() => changeCurrentPage(item, 3)}>
                            {item.catNameCn}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        )}
        {fourCategoryName && <i className={styled.crumbs_arrow}></i>}
        {fourCategoryName && (
          <div className={styled.categories}>
            <div
              className={`${styled.menu_drop} ${Number(level) === 4 ? styled.z_menu_drop_open : ''}`}
              onMouseOver={() => {
                changeCurrentTab(4);
              }}
              onMouseOut={() => {
                changeCurrentTab('');
              }}
            >
              <div className={styled.trigger}>
                <span className={styled.curr}>{fourCategoryName}</span>
                <i className={styled.menu_drop_arrow}></i>
              </div>
              <div className={styled.menu_drop_main}>
                <ul className={styled.menu_drop_list}>
                  {fourCategory &&
                    fourCategory.map((item: any) => {
                      return (
                        <li key={item.catId}>
                          <a title={item.catNameCn} onClick={() => changeCurrentPage(item, 4)}>
                            {item.catNameCn}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className={`${styled.select_con}`} style={{ marginLeft: params?.cat ? '5px' : '' }}>
          {searchValArray &&
            searchValArray.map((item: any, index: number) => {
              return (
                <a
                  style={{ marginBottom: '2px' }}
                  className={`${styled.crumb_select_item}`}
                  key={index}
                  onClick={() => {
                    clearOneParams(item, searchValArray[index]);
                  }}
                >
                  <b>{item[0]}：</b>
                  <em className={`${styled.text_space}`} title={item[1]}>
                    {item[1]}
                  </em>
                  <i></i>
                </a>
              );
            })}
        </div>

        {!threeCategoryName && !twoCategoryName && (
          <div className={`${styled.crumbs_nav_item}`}>
            <strong>{searchVal || ''}</strong>
          </div>
        )}
         {/**本土有四级类目*/}
         {/* {(stationtype == '0' && searchVal) && !fourCategoryName && !threeCategoryName && !twoCategoryName && (
          <div className={`${styled.crumbs_nav_item}`}>
            <strong>{'"' + searchVal + '"'}</strong>
          </div>
          )} */}
        {searchValArray.length > 0 && (
          <div className={`${styled.crumbs_nav_item} ${styled.clear_selected}`}>
            <a
              style={{ marginLeft: '10px', float: 'left' }}
              onClick={() => {
                const param = {
                  ...params,
                  cat: '',
                  priceMinFilter: null,
                  priceMaxFilter: null,
                  extAttrList: [],
                  brandRole: [],
                };
                setSearchValArray([]);
                dispatch(dispatchAnnounceData(param, true, []));
              }}
            >
              {TLT(`重新筛选`)}
            </a>
          </div>
        )}
        <div className={`${styled.clearfix}`}></div>
      </div>
    </div>
  );
};
export default Crumbs;
