/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2023-12-04 15:09:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-08 15:28:04
 * @FilePath: /isp_International/src/pages/OrderDetailNew/components/Progress/right/approval.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './../index.module.scss';
import { message } from 'antd';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

interface IAppProps {
  orderDetails: any;
}
const OrderApproval: React.FC<IAppProps> = (props: any) => {
  const { t, i18n } = useTranslation();
  const { isModel, orderPin, orderDetails } = props;
  const [nodeList, setNodeList] = useState([]);
  const [needRemind, setNeedRemind] = useState(false);
  const store: any = useSelector(state => {
    return state;
  });
  const pin = store?.User?.data[0]?.pin ?? '';
  useEffect(() => {
    setNodeList(orderDetails?.node);
    setNeedRemind(orderDetails?.showRemind);
  }, []);

  const stateFunc = (status: number) => {
    switch (status) {
      case null:
      case 1:
        return i18n.language == 'zh' ? '审批中' : 'Pending Approval'; // TLT(`审批中`);
      case -1:
      case 2:
        return i18n.language == 'zh' ? '审批完成' : 'Approved'; //  TLT(`审批完成`);
      case 3:
        return i18n.language == 'zh' ? '审批驳回' : 'Rejected'; //  TLT(`审批驳回`);
      case 4:
        return i18n.language == 'zh' ? '中断' : 'Interrupt';
    }
  };
  const stateCol = (approvalStatus: number) => {
    switch (approvalStatus) {
      case null:
      case 1:
        return 'nor';
      case -1:
      case 2:
        return 'suc';
      case 3:
      case 4:
        return 'rej';
      default:
        return 'nor';
    }
  };
  const remindFunc = async () => {
    console.log('提醒发货');
  };
  return (
    <ul className={styles.approvalList}>
      {nodeList?.reverse()?.map((item: any, index: number) => {
        return (
          <li
            key={index}
            className={[stateCol(item) && styles[stateCol(item.approvalStatus ? item.approvalStatus : null)]].join(' ')}
          >
            <span className={styles.state}>{stateFunc(item.approvalStatus ? item.approvalStatus : null)}</span>
            <div className={styles.detail}>
              <i className={styles.nodeIcon}>
                <i className={styles.icon}></i>
                {index + 1 != nodeList.length && <i className={styles.b}></i>}
                {index == 1 && !isModel && <i className={styles.bg_opacity}></i>}
              </i>
              <div className={styles.boxs}>
                <div className={styles.top}>
                  <span className={styles.level}>{item.nodeName}</span>
                  <span className={styles.name}>{item.currentApprovePin}</span>
                  {item.dealTime && (
                    <span className={styles.date}>{moment(item.dealTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                  )}
                </div>
                {pin == orderPin && isModel && needRemind && item.isShowBtn && (
                  <span className={`${styles.remind} ${styles.s}`} onClick={remindFunc}>
                    提醒审单
                  </span>
                )}
                {item.reason && (item.approvalStatus == 2 || item.approvalStatus == 3) && (
                  <p className={styles.suggest} title={item.reason}>
                    {TLT(`意见`)}：{item.reason}
                  </p>
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default OrderApproval;
