/*
 * @Author: duchongchong1
 * @Date: 2022-10-26 09:17:45
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-11-01 16:14:24
 * @FilePath: /isp_International/src/pages/AccountSettings/components/ChangeEmailModal/index.tsx
 * @Description:
 */
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Modal, Row, Form, Input, message } from 'antd';
import { sendVerifyMail } from '@/api/newApi/personalCenter/accountSettings';
import { useTranslation } from 'react-i18next';

const ChangeEmailModal: React.FC<{
  visible: boolean;
  onCancel?: () => void;
  onOK?: (email: string) => void;
}> = ({ visible, onCancel, onOK }) => {
  // 表单
  const [form] = Form.useForm();
  // 加载按钮
  const [loading, setLoading] = useState(false);
  // 中英文翻译
  const { t } = useTranslation();
  /**
   * form保存的回调
   * @param values 保存的数据
   */
  const onFinish = (values: any) => {
    setLoading(true);
    sendVerifyMail(values.email)
      .then((res: any) => {
        setLoading(false);
        if (res?.success) {
          onOK && onOK(values.email);
        } else {
          message.error(res?.msg);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  // 监听visible，实现初始化弹窗清空保留信息
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      title={TLT(`绑定邮箱`)}
      className="detailModel"
      open={visible}
      onCancel={onCancel}
      centered={true}
      footer={null}
    >
      <Form form={form} colon={false} name="control-hooks" onFinish={onFinish} className={styles.searchForm}>
        {/* 邮箱 */}
        <Form.Item
          key="formItem2"
          name="email"
          label={TLT(`邮箱`)}
          rules={[
            // { required: true, message: TLT(`请输入所绑定的邮箱`) },
            {
              required: true,
              validator: (_, value) => {
                if (!value) return Promise.reject(TLT(`请输入所绑定的邮箱`));
                if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(TLT(`请输入正确的邮箱`));
              },
            },
          ]}
        >
          <Input placeholder={TLT(`请输入所绑定的邮箱`)} />
        </Form.Item>
        <Row justify="end">
          {/* 确定 */}
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ fontSize: '14px', width: '100px', height: '32px', marginBottom: '10px', marginRight: '16px' }}
          >
            {TLT(`确定`)}
          </Button>
          {/* 取消 */}
          <Button onClick={onCancel} style={{ fontSize: '14px', width: '100px', height: '32px', marginBottom: '10px' }}>
            {TLT(`取消`)}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangeEmailModal;
