/*
 * @Author: your name
 * @Date: 2021-11-01 13:44:29
 * @LastEditTime: 2024-01-25 10:32:39
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: In User Settings Edit
 * @FilePath: /isp_International/src/components/NavBar/Drawer/index.tsx
 */
/* eslint-disable react/jsx-no-target-blank */
import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from './index.module.scss';
import SliterMenu from '../sliter/index';
import { getTree } from '@/api/newApi/header';
import close from '@/assets/images/common/icon_close.png';
import { useTranslation } from 'react-i18next';
export interface IAppProps {
  visible: any;
  onClose: () => void;
}

const Drawers: React.FC<IAppProps> = (props: IAppProps) => {
  const { onClose, visible } = props;
  const [menuIdx, setMenuIdx] = useState(0);
  const [menuData, setMenuData] = useState([]);
  const { t } = useTranslation();
  const setShowList = async () => {
    const res = (await getTree({})) as any;
    if (res && res.success) {
      setMenuData(res.value || []);
    }
  };
  useEffect(() => {
    // 获取分类数据
    setShowList();
  }, []);
  useEffect(() => {
    if (visible) {
      setMenuIdx(0);
    }
  }, [visible]);

  return (
    <Drawer
      title={TLT(`全部商品分类`)}
      placement="left"
      width={1080}
      closable={true}
      closeIcon={<img src={close} width={12} />}
      onClose={onClose}
      open={visible}
      className={styled.drawer}
    >
      <div className={`${styled.drawer_conent}`}>
        <div className={`${styled.menu_conent}`} id="classification">
          <div className={`${styled.industry}`}>
            <div className={`${styled.menu_list}`}>
              {menuData?.length > 0 &&
                menuData.map((item: any, index: any) => {
                  return (
                    <div
                      title={item.catName}
                      key={item.catId}
                      className={`${styled.menu_item} ${styled.text_space} ${
                        index == menuIdx ? styled.menu_hover : ''
                      }`}
                      onMouseEnter={() => {
                        setMenuIdx(index);
                      }}
                    >
                      <a>{item.catName}</a>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className={`${styled.sub_menu}`}>
          {menuData[menuIdx] && (
            <SliterMenu
              childData={(menuData[menuIdx] as any).categoryDTOList}
              oneId={(menuData[menuIdx] as any).catId}
              {...props}
              onClose={onClose}
            ></SliterMenu>
          )}
        </div>
      </div>
    </Drawer>
  );
};
export default Drawers;
