import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BaseLayout from '@/layout/BaseLayout';
import WorkBenchRoutes from './WorkBench';

import SelectList from '../pages/SelectList';
import SearchList from '../pages/SearchList';
import Home from '../pages/NewHome/index';
import Carts from '../pages/CartsNew/index';
import OtherCarts from '../pages/OtherCarts/index';
import Login from '../pages/Login';
import UpdatePassword from '../pages/UpdatePassword';
import SkuDetailV2 from '../pages/SkuDetailV2/index';
import OrderInfo from '../pages/OrderInfo/index';
import BatchCarts from '@/pages/BatchCarts';
import { useTranslation } from 'react-i18next';

const routes = [
  //搜索列表
  {
    path: `/page/list`,
    name: 'SearchList',
    exact: true,
    meta: {
      title: 'searchList', //搜索列表
    },
    component: SearchList,
  },
  {
    path: `/`,
    name: 'Home',
    exact: true,
    meta: {
      title: 'home', //首页
    },
    component: Home,
  },
  {
    path: `/select`,
    name: 'Select',
    exact: true,
    meta: {
      title: 'select', //精选商品-落地页
    },
    component: SelectList,
  },
  //购物车
  {
    path: `/cart/otherCart`,
    name: 'OtherCarts',
    exact: true,
    meta: {
      title: 'otherCart', //其他采购清单
    },
    component: OtherCarts,
  },
  {
    path: `/sku/:skuId`,
    name: 'SkuDetail',
    exact: true,
    meta: {
      title: 'skuDetail', //商品详情页
    },
    component: SkuDetailV2,
  },
  {
    path: `/cart/carts`,
    name: 'Carts',
    exact: true,
    meta: {
      title: 'cartList', //采购清单
    },
    component: Carts,
  },
  {
    path: `/cart/batchAdd`,
    name: 'batchCarts',
    exact: true,
    meta: {
      title: 'batchCarts', //采购清单
    },
    component: BatchCarts,
  },
  {
    path: `/order/goOrderInfo`,
    name: 'OrderInfo',
    exact: true,
    meta: {
      title: 'settlement', //结算页
    },
    component: OrderInfo,
  },
  ...WorkBenchRoutes,
  {
    path: '*',
    name: 'Home',
    exact: true,
    meta: {
      title: 'home', //首页
    },
    component: Home,
  },
];

const titleName: Record<string, string> = {
  "settlement": "结算页",
  "home": "首页",
  "skuDetail": "商品详情",
  "cartList": "采购清单",
  "otherCart": "其他采购清单",
  "searchList": "搜索列表",
  "select": "商品列表",
  "approvalList": "审批列表",
  "checkout": "结算",
  "purchasing": "采购清单",
  "orderList": "订单列表",
  "addressList": "地址管理",
  "orderDetail": "订单详情",
  "workbench": "工作台",
  "account": "账户管理",
  "approvalSettings": "审批管理",
  "createApprovalFlow": "创建审批流",
  "editApprovalFlow": "编辑审批流",
  "downloads": "下载中心",
  
}
const RouteWithSubRoutes = (route: any) => {
  const { t, i18n } = useTranslation();
  return (
    <Route
      exact
      path={route.path}
      render={props => {
        document.title = `${TLT(`工采国际`)}-` + `${TLT(titleName[route?.meta?.title])}`;
        return <route.component {...props} {...route.params} routes={route.routes} />;
      }}
    />
  );
};

const BasicRoute = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/passport/login" component={Login} />
      <Route exact path="/forget" component={UpdatePassword} />
      <BaseLayout backgroundColor="#f0f0f0">
        <Switch>{routes && routes.map((route: any, i: any) => <RouteWithSubRoutes key={i} {...route} />)}</Switch>
      </BaseLayout>
    </Switch>
  </BrowserRouter>
);

export default BasicRoute;
