/*
 * @Author: duchongchong1
 * @Date: 2023-04-21 17:11:37
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-22 20:11:04
 * @FilePath: /isp_pro/src/pages/Approval/Create/floor/submit/index.tsx
 * @Description:  
 */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import { PAGE_TYPE } from '@/pages/Approval/Create/constants';
import { getUrlsQuery } from '@/util';
import styles from '../../index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  operateType?: number;
  confirmCallBack?: () => void;
  isCanSubmit?: boolean;
}

const FloorSubmit: React.FC<IProps> = (props: any) => {
  const { confirmCallBack, operateType, isCanSubmit } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [submitTitle, setSubmitTitle] = useState(TLT(`提交`));
  const [backTitle, setBackTitle] = useState(TLT(`取消`));
  const [backUrl, setBackUrl] = useState('');

  

  useEffect(() => {
    switch (operateType) {
      case PAGE_TYPE.detailPage:
        setSubmitTitle(TLT(`编辑`));
        setBackTitle(TLT(`返回`));
        break;
      case PAGE_TYPE.modifyPage:
        setSubmitTitle(TLT(`提交`));
        setBackTitle(TLT(`取消`));
        break;
      default:
        setSubmitTitle(TLT(`提交`));
        setBackTitle(TLT(`取消`));
        setBackUrl('/processList');
        break;
    }
  }, [operateType]);

  const handleBack = () => {
    switch (operateType) {
      case PAGE_TYPE.detailPage:
        setSubmitTitle(TLT(`编辑`));
        break;
      case PAGE_TYPE.modifyPage:
        setSubmitTitle(TLT(`提交`));
        break;
      default:
        setSubmitTitle(TLT(`提交`));
        break;
    }
  };
  const handleSubmit = () => {
    const processKey = getUrlsQuery('processKey');
    switch (operateType) {
      case PAGE_TYPE.detailPage:
        history.push(`/approval?processKey=${processKey}&type=2`);
        break;
      case PAGE_TYPE.addPage:
        confirmCallBack && confirmCallBack();
        break;
      default:
        confirmCallBack && confirmCallBack();
        break;
    }
  };
  return (
    <div className={styles.submit}>
      <div className={styles.right}>
        <Button style={{marginRight: '8px'}}>
          <Link to={backUrl}>{backTitle}</Link>
        </Button>
        <Button disabled={!isCanSubmit} type={'primary'} onClick={handleSubmit}>
          {submitTitle}
        </Button>
        
      </div>
    </div>
  );
};

export default FloorSubmit;
