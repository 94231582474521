/*
 * @Author: your name
 * @Date: 2022-03-07 11:31:54
 * @LastEditTime: 2022-11-29 15:42:34
 * @LastEditors: duchongchong1
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_International/src/api/header.ts
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
// import { RootObject } from "config";
import http from './axios';
import { dispatchPublicPromise, dispatchPromiseWhite } from '@/redux/action/index';
import store from '@/redux/stroe';
import { getPersonInfo } from '@/api/newApi/carts';

/**
 * 头层顶部信息 - top
 */
// export function getOrderNum() {
//   return new Promise((resolve, reject) => {
//     http('get', '/orderList/getOrderStateCount', '', false).then(
//       res => {
//         resolve(res);
//       },
//       error => {
//         console.log('网络异常~', error);
//         reject(error);
//       },
//     );
//   });
// }
// 获取用户路由权限
export function getPublicPromise() {
  return new Promise((resolve, reject) => {
    http('get', '/public/promise', '', false).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

// 获取个人权限
const publicPromise = async () => {
  try {
    const publicPromise = await getPublicPromise();
    store.dispatch(dispatchPublicPromise(publicPromise));
  } catch (e) {
    console.log('网络异常~', e);
  }
};

// 用户信息
export function getUser() {
  // 获取用户信息的同时重新获取用户权限
  // publicPromise();
  return new Promise((resolve, reject) => {
    getPersonInfo().then(
      (res: any) => {
        console.log(res, 'personInfo');
        resolve({
          ...res,
        });
      },
      err => {
        console.log('网络异常~', err);
        reject(err);
      },
    );
  });
}

// 退出登录
export function logout() {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/passport/logout', '', false).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 获取在线客服地址
export function customer() {
  return new Promise((resolve, reject) => {
    http('get', '/DD/contactCustomer/dd/new', '', false).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
