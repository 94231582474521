import { fullUrlGen } from '@/util/index';

export const passPortLoginUrl = fullUrlGen('https://passport.jd.com/common/loginPage', {
  from: 'jdi_itnational_web',
  regTag: 2,
  pwdTag: 2,
  btnTag: '992bbfbb9100d5e1',
  ReturnUrl: WISP_LOGIN_URL,
});

export const logoutHandler = (cb: Function) => {
  fetch('https://sso.jdindustry.com/exit?callback=?', {
    method: 'GET',
    credentials: 'include',
  }).then((res: any) => {
    fetch('https://sso.jd.com/exit?callback=?', {
      method: 'GET',
      credentials: 'include',
    }).then(async () => {
      await cb();
      window.location.href = WISP_LOGIN_URL;
    });
  });
};
